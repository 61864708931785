import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import "../../assets/css/blog.css";
import ReactHtmlParser from "react-html-parser";

function Blog() {
  const [allBlog, setAllBlog] = useState([]);
  const [noBlog, setNoBlog] = useState(false);

  function timeCounter(data) {
    const words =
      data.title.trim().split(/\s+/).length +
      data.author.trim().split(/\s+/).length +
      data.description.trim().split(/\s+/).length +
      data.metaDescription.trim().split(/\s+/).length +
      data.customTitle.trim().split(/\s+/).length;

    return Math.ceil(words / 200);
  }
  const getAllBlog = async () => {
    try {
      const fetchedData = await import(
        "../../utils/services/blog.service"
      ).then(async (service) => await service.getBlogList({}));
      if (fetchedData) {
        if (fetchedData.data.length === 0) {
          setAllBlog(fetchedData.data);
          setNoBlog(true);
        } else if (fetchedData.data.length > 0) {
          setAllBlog(fetchedData.data);
          setNoBlog(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllBlog();
  }, []);

  const handleBlogBtn = (data) => {
    localStorage.setItem("blogIdweT", JSON.stringify(data._id));
  };

  return (
    <>
      <div>
        <Helmet htmlAttributes>
          <title> Blog | WeTech</title>
          <meta name="description" content="Blog | WeTech"></meta>
          <meta name="keywords" content="Blog"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div
            className="row services-page-one"
            style={{
              marginBottom: "3rem",
              marginTop: "2.8rem",
              width: "100%",
            }}>
            {allBlog.length > 0 && noBlog === false ? (
              allBlog.map((data) => (
                <div className="col-lg-4 col-md-6 mb-4 blog_card">
                  <div className="text-center blog-image-p">
                    <img src={data?.file} className="w-100 h-100" alt="" />
                  </div>
                  <div className="blog-post_body">
                    <h4 className="blog-post__title">{data?.title}</h4>
                    <small className="blog-post__meta push-half-bottom">
                      By {data?.author}
                    </small>
                    <div className="blog-post__excerpt push-bottom">
                      <p className="text-muted">
                        {ReactHtmlParser(data?.description)}
                      </p>
                    </div>
                  </div>
                  <div className="blog-post__footer">
                    <Link to={`/blog/${data?.customUrl}`}>
                      {/* to={`/blog/${data?.customUrl ? data?.customUrl : data?.title}`} */}
                      <button
                        className="btn btn-primary btn-small blog-post__link"
                        onClick={() => handleBlogBtn(data)}>
                        View Full Blog <AiOutlineArrowRight />
                      </button>
                    </Link>
                    <p>
                      <span
                        style={{
                          fontSize: "1.1rem",
                          fontWeight: "700",
                          color: "#030C16",
                        }}>
                        {" "}
                        {timeCounter(data)}
                      </span>{" "}
                      minutes to read
                    </p>
                  </div>
                </div>
              ))
            ) : allBlog.length === 0 && noBlog === true ? (
              <div className="text-center primary-bg text-light py-5 mb-5">
                <h1>There are no blogs available right now! </h1>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
