import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/websiteDevelopment/1.PinClipart 2 (4).png";
import image2 from "../../../assets/images/websiteDevelopment/2.website-development-links-seo-webinar-cyberspace-concept 1.png";
import image3 from "../../../assets/images/websiteDevelopment/3.businesspeople-shaking-hands (1) 1.png";
import image4 from "../../../assets/images/websiteDevelopment/3.businesspeople-shaking-hands (1) 1.png";
// import image4 from "../../../assets/images/websiteDevelopment/4.0hand-hold-sales-data-economic-growth-graph-chart-business-planning-strategy-analysing-trading-exchange-financial-banking-technology-digital-marketing-profit-growing-plan 1.png";
import image5 from "../../../assets/images/websiteDevelopment/5.portrait-pensive-asian-woman-standing 1.png";
import image6 from "../../../assets/images/websiteDevelopment/6.attractive-smiling-girl-looking-happy-pointing-upper-right-corner 1.png";
import { Link } from "react-router-dom";

function WebsiteDevelopment() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Website Development: affordable web development packages | WeTech
            Digital
          </title>
          <meta
            name="description"
            content="Get responsive website development services from WeTech Digital. We provide web development services at affordable prices."></meta>
          <meta
            name="keywords"
            content="
          
          website development
,website development company
,website development cost
,website development services
,website development agencies
,website development price


          
          "></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">Web Development</h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Our website Development agency is transforming the way
                    brands connect with audiences in a digital world. Learn why
                    market leaders choose our Unique set of development and
                    solutions.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Web Development Company/Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  We assist you in improving the customer experience, responsive
                  and custom website development service at affordable prices by
                  offering top-rated solutions built with modern frameworks like
                  Angular JS, Node JS, React JS, ASP.NET, PHP, WordPress, and
                  others. Our process-driven and enthusiastic team of designers
                  and developers have extensive expertise and experience in
                  developing stable, cross-browser compatible, and
                  device-independent web applications.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to Landing Page Design</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  You've come to the correct location if you're searching for
                  personalized website development solutions. Our highly
                  experienced web development experts are well-versed in today's
                  cutting-edge web technologies. To be successful, web
                  development projects must be highly decorated. Every day, our
                  technical project managers and developers collaborate to
                  ensure that our clients' needs are always prioritized. Our
                  development team also collaborates closely with the UX and UI
                  teams to ensure that best practices in customer experience are
                  always at the forefront of our minds.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Analysis</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Plan</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Design</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Development</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Testing</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you want to get a first-class Web Development Service?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-six-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We believe quality service and our team develops accurately
                    and accurately. We make sure our customers' needs are met by
                    the end product.
                  </li>
                  <li className="page-six-li">
                    Even after completion of the project, our development team
                    will be available.
                  </li>
                  <li className="page-six-li">
                    We will adjust our working hours to ensure that we overlap
                    with your time zone, depending where you are.
                  </li>
                  <li className="page-six-li" >
                    We provide customized Website Development solutions to
                    satisfy customers worldwide, ranging from start-ups to
                    established businesses searching for exceptional website
                    design services with minimal development cost.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We convert development into profitable solutions.
                  </li>
                  <li className="page-six-li">Result-oriented outcomes</li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements
                  </li>
                  <li className="page-six-li">
                    We offer social media network establishment, optimization
                    and identity auditing services.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Percel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Web Development Service At The Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default WebsiteDevelopment;
