import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BiArrowBack } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import "../../assets/css/blog.css";
import ReactHtmlParser from 'react-html-parser';

function BlogDetails() {
  // const id = JSON.parse(localStorage.getItem("blogIdweT"));
  const [blogDetails, setBlogDetails] = useState({});
  // console.log(blogDetails)
  const { title } = useParams();
  
  const getAllBlog = async () => {
    try {
      const fetchedData = await import("../../utils/services/blog.service").then(
        async (service) => await service.getBlogList({})
      )
      if (fetchedData) {
        if (fetchedData.data.length > 0) {
          const single = fetchedData.data.find(data => data.customUrl === title);
          setBlogDetails(single);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllBlog();
  }, [])

  return (
    <>
      <div>
        <Helmet htmlAttributes>
          <title>
            {" "}
            {`${blogDetails?.customTitle ? blogDetails?.customTitle : ""}`}
          </title>
          <meta
            name="description"
            content="Blog details | WeTech"
          ></meta>
          <meta
            name="keywords"
            content="Blog"
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="row services-page-one justify-content-center" style={{ marginBottom: "3rem", marginTop: "2.8rem", width: "100%" }}>
            <div className="col-md-10">
              <Link to="/blog">
                <button className="btn btn-primary mb-5"><BiArrowBack /></button>
              </Link>
              <div className="mb-3">
                <h1 className="blog-post__title">{blogDetails?.title}</h1>
                <small className="blog-post__meta push-half-bottom">By {blogDetails?.author}</small>
              </div>
              <div className="text-center blog-details-page-img">
                <img src={blogDetails?.file} className="w-100 h-100" alt="" />
              </div>
              <div className="blog-post_body">

                <div className="push-bottom">
                  <p className="text-muted">
                    {
                      ReactHtmlParser(blogDetails?.description)
                    }
                  </p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </>
  );
}

export default BlogDetails;
