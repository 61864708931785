import React from "react";
import "../assets/css/footer.css";
import logo from "../assets/logos/wetechdigital.png";
import { Link } from "react-router-dom";
import { AiFillBehanceSquare, AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillTwitterSquare, AiFillYoutube } from "react-icons/ai"

function Footer() {

  return (
    <div className="new-footer-main">
      <div className="footer-sub">
        <div className="footer-left">
          <div className="footer-logo-section">
            <Link to="/">
              <img className="footer-logo" src={logo} alt="" />
            </Link>
          </div>
          <div className="content-left-section">
            <div className="content-partition">
              <ul className="footer-ul-left">
                <li className="footer-li-top">Services</li>
                <li className="footer-li">
                  <Link to="seo-service">SEO Service</Link>
                </li>
                <li className="footer-li">
                  <Link to="eCommerce-seo">E-Commerce SEO</Link>
                </li>
                <li className="footer-li">
                  <Link to="ppc-management">PPC Management</Link>
                </li>
                <li className="footer-li">
                  <Link to="eCommerce-ppc-service">
                    E-Commerce PPC Services
                  </Link>
                </li>
                <li className="footer-li">
                  <Link to="social-media-management-service">
                    Social Media Management
                  </Link>
                </li>
              </ul>
            </div>
            <div className="content-partition">
              <ul className="footer-ul-left">
                <li className="footer-li-top">Company</li>
                <li className="footer-li">
                  <Link to='/about'> About Us </Link>
                </li>
                <li className="footer-li">
                  <Link to='/contact'> Contact Us </Link>
                </li>
                <li className="footer-li">
                  <Link to="/career">Career </Link>
                </li>
              </ul>
            </div>
            <div className="content-partition">
              <ul className="footer-ul-left">
                <li className="footer-li-top">Resource</li>
                {/* <li className="footer-li">Case studies</li> */}
                <li className="footer-li">
                  <Link to='/blog'>Blog</Link>
                </li>
                {/* <li className="footer-li">Our clients</li> */}
                <li className="footer-li">
                  <Link to='/portfolio'>Our Portfolio</Link> </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-contact-section">
            <ul className="footer-ul-right">
              <li className="footer-li-top">Contact Us</li>
              <p></p>
              <li className="footer-li">
                <b>E. </b>
                <a href="mailto:contact@wetechdigital.com">
                  contact@wetechdigital.com
                </a>
              </li>

              <li className="footer-li">
                <a
                  href="https://www.google.com/maps/place/WeTech+Digital/@23.7529091,90.3816866,17z/data=!3m1!4b1!4m5!3m4!1s0x3755b9bece9995b1:0xb019b8000bb2cfbd!8m2!3d23.7529042!4d90.3838753"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-2 mx-md-0"
                >
                  <b>A. </b>44 F/7(Rongon Tower),
                  <br />
                  West Panthapath,Dhaka
                </a>
              </li>
              <li className="footer-li">
                <b>P. </b>
                <a href="tel:+8801817698776">+8801817698776</a>
              </li>
            </ul>
          </div>
          <div className="social-media-section !w-full flex flex-row mt-4 gap-3">
            <div onClick={() => window.open("https://www.facebook.com/WeTechDigitalGlobal/")} className="cursor-pointer">
              <AiFillFacebook className="text-white text-3xl hover:!text-[#ffd872]" />
            </div>

            <div onClick={() => window.open("https://www.youtube.com/channel/UC2bEtF_v_-zOiJqULPCAj5w")} className="cursor-pointer">
              <AiFillYoutube className="text-white text-3xl hover:!text-[#ffd872]" />
            </div>

            <div onClick={() => window.open("https://www.instagram.com/wetechdigitalglobal/")} className="cursor-pointer">
              <AiFillInstagram className="text-white text-3xl hover:!text-[#ffd872]" />
            </div>

            <div onClick={() => window.open("https://www.behance.net/wetechdigitalglobal")} className="cursor-pointer">
              <AiFillBehanceSquare className="text-white text-3xl hover:!text-[#ffd872]" />
            </div>

            <div onClick={() => window.open("https://twitter.com/WeTech_Digital")} className="cursor-pointer">
              <AiFillTwitterSquare className="text-white text-3xl hover:!text-[#ffd872]" />
            </div>

            <div onClick={() => window.open("https://www.linkedin.com/company/wetechdigitalglobal")} className="cursor-pointer">
              <AiFillLinkedin className="text-white text-3xl hover:!text-[#ffd872]" />
            </div>

          </div>
        </div>
      </div>


    </div>
  );
}

export default Footer;
