import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import ContactWetech from "../../blocks/ContactWetech";

// photos
import smile from "../../../assets/images/smileMan.png";
import project from "../../../assets/images/project.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/customSeoReporting/1.PinClipart 2 (2).png";
import image2 from "../../../assets/images/customSeoReporting/2.legal-businessman-advisor.png";
import image3 from "../../../assets/images/customSeoReporting/3.business-executives-shaking-hands-during-meeting 1.png";
import image4 from "../../../assets/images/customSeoReporting/4.close-up-seo-word-web-business-concept-idea 1.png";
import image5 from "../../../assets/images/customSeoReporting/5.questioned-asian-guy.png";
import image6 from "../../../assets/images/customSeoReporting/6.thoughtful-blonde.png";
import { Link } from "react-router-dom";

function CustomSEOReporting() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            {" "}
            Custom SEO report services: SEO analysis agency | WeTech
          </title>
          <meta
            name="description"
            content="Get custom SEO report services, check your website SEO ranking. Find out SEO issues to perform better than your competitors. Check out our packages now!
"></meta>
          <meta
            name="keywords"
            content="
          
custom seo report services
,seo ranking check service
,google website ranking check services
,seo analysis agency
,seo analysis services"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box-custom">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    Custom SEO Reporting
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you need custom SEO report services? Our SEO Experts know
                    how your company will get more focus locally!
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="img-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Custom SEO Reporting Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital learns about your marketing strategy, market,
                  and rivals while paying attention to your strategic
                  objectives. Custom SEO services can guarantee that your
                  customers discover you and keep an eye on your customer base
                  by using the information to develop a tailored plan that takes
                  you where you need to go. Custom SEO report services by us
                  make you go ahead in the market. WeTech Digital offers
                  numerous bespoke reporting solutions for clients that want
                  advanced monthly data on the effectiveness of their company's
                  website. Every month, these sophisticated reports offer your
                  company very specific data. WeTech Digital clients that want
                  more detailed information than the monthly reports included
                  with our SEO programs may now request advanced reports for a
                  charge.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Custom SEO Reporting Services
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  In the world of digital marketing, accurate reporting is
                  crucial. As an SEO analysis agency, WeTech Digital offers
                  sophisticated, customized reports that include everything from
                  call monitoring to lead attribution and all in between. Our
                  custom reporting pricing is completely transparent, so you
                  always know where your money is going. Organic search engine
                  traffic, PPC, and social media traffic, and income reports are
                  split up by particular marketing channels. In Google Analytics
                  or other website analytics platforms, custom objectives,
                  events, and dashboards may be created for advanced monthly
                  tracking. Call monitoring, cost-per-lead, eCommerce, lead
                  attribution, firms visiting your website, and more advanced
                  reporting features are available.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Keyword & aspects of research</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Ecommerce advice</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Extended working hours</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Time to time Report</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Would like to develop Custom SEO Reporting Services?
                    <span
                      style={{
                        color: "#FFD872",
                        fontFamily: "lora, serif",
                      }}></span>
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We invigilate at SEO ranking check service
                  </li>
                  <li className="page-six-li">
                    We provide custom SEO analysis services at a low cost.
                  </li>
                  <li className="page-six-li">
                    We have SEO specialists (Included international SEO
                    capabilities) who can guide you.
                  </li>
                  <li className="page-six-li">
                    We appear to be more than just the top local SEO services
                    supplier; we can also manage a wide range of customer
                    requirements in local markets.
                  </li>
                  <li className="page-six-li">
                    We have well-researched keywords.
                  </li>
                  <li className="page-six-li">
                    We use robust techniques to grow any business with an online
                    presence
                  </li>
                  <li className="page-six-li">
                    We monitor ratings and reviews and also create links.
                  </li>
                  <li className="page-six-li">
                    We consider user reviews most to give them a friendly
                    experience.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We offer Google website ranking check services.
                  </li>
                  <li className="page-six-li">
                    As it is easy to track, our SEO team makes you visible on
                    top.
                  </li>
                  <li className="page-six-li">
                    We have lead creation and sales development for organic and
                    paid keywords.
                  </li>
                  <li className="page-six-li">
                    We provide ROI advisory services.
                  </li>
                  <li className="page-six-li">
                    Meet industry-specific criteria in a specific area.
                  </li>
                  <li className="page-six-li">
                    Our specialists help you to plan, execute and revise
                    strategies.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Custom SEO Reporting Service At The Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default CustomSEOReporting;
