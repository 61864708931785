const data = [
  {
    name: "Content assets developed",
    silver: "3",
    gold: "6",
    platinum: "10",
  },
  {
    name: "Dedicated Content Marketing expert",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Online project management schedule",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Keyword research",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monthly Content Marketing Strategy Report",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Choose from the following Content Marketing Asset Options - Customized to your business goals",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Long-form Content",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Micrographics",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Infographics",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Online Guides",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Website & Social Engagement Videos",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Animated Explainer Videos",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Live Action Explainer Videos",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Slideshares",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "SlideMotion Graphicsshares",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Paid Content Asset Promotion",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Content Asset Outreach",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Competitor Gap Content Outlines",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Voice Search Optimized Content",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "User Testing Videos + Suggested Actions Summary Reports",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "1 Personalization Graphic (Equal to 1 Asset)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Rich Media Citations",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Competitor Content Gap Intelligence Report",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monitor competitors' website changes on a monthly basis?",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "150+ SMEs behind campaign driving results",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monthly Content Marketing Investment:",
    silver: "",
    gold: "",
    platinum: "",
  },
   
   
   
];

export default data;
