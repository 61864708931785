import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import image1 from "../../../assets/images/portfolio/LABAID Group - Revival Tea Website Developed by WeTech Digital-1.png";
import image0 from "../../../assets/images/portfolio/1.programmer-coding-software-development-working-project-it 1.png";
import image2 from "../../../assets/images/portfolio/explaining-strategy 1.png";
import image3 from "../../../assets/images/portfolio/Mask.png";
import image4 from "../../../assets/images/portfolio/Captumre 1.png";
import image5 from "../../../assets/images/portfolio/image 1.png";
import image6 from "../../../assets/images/portfolio/image 2.png";
// import image7 from "../../../assets/images/portfolio/7.empty-modern-creative-agency-office-with-dual-monitors-setup-with-processing-video-film-montage-vide 1.png";
// import image8 from "../../../assets/images/portfolio/8.fun-3d-cartoon-teenage-boy (1) 1.png";
// import image9 from "../../../assets/images/portfolio/9.pay-per-click-login-website-payment-graphic-concept 1.png";
import image10 from "../../../assets/images/portfolio/wholesalexpress3.png";
import StarRatings from "react-star-ratings";


function WebDesignPortfolio() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            {" "}
            Web Design And Development Portfolio | WeTech
          </title>
          <meta
            name="description"
            content="Web Design And Development Portfolio | WeTech"
          ></meta>
          <meta
            name="keywords"
            content="web design portfolio"
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box pb-5 pb-md-0">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">Web Design & <br /> Development <br /> Portfolio </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                  Do you want well to build up Competitor Analysis services? Then we are here to please you providing that professionally.
                  </p>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image0} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Web Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                LABAID Group
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src={image1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-4">
               <div className="secondary-clr">
                   <h3 className="fw-bold">People Reviews <br /> Of Wetech Digital</h3>
                   <p className="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis tortor posuere a velit pharetra sit id felis. sit amet, consectetur adipiscing elit</p>
               </div>
            </div>
            <div className="services-page-three-right p-5 primary-text-clr">
                
                <div>
                    <StarRatings
                        rating={5}
                        starRatedColor="#FED831"
                        starDimension="30px"
                        starSpacing="0px"
                    />
                    <div className="mt-3">
                      <img className="img-fluid" src={image2} alt="" />
                    </div>
                   <div className="d-flex mt-4">
                      <div>
                        <img src={image3} style={{width: "60px", marginTop: "10px"}} alt="" />
                      </div>
                      <div className="mx-3">
                         <p>Meg Rigden <br /> <span >Type something</span></p>
                          
                      </div>
                   </div> 
                </div>
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-5 portfolio-bg">
              <img className="image-1" src={image4} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Web development</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Trabn.com</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Web development</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                Wholesalexpress
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src={image10} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-5 portfolio-bg">
              <img className="image-1" src={image5} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Web development</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Life Tracker BD</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Web development</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                CHOSHMA MELA
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src={image6} alt="" />
            </div>
          </div>


          <div className="services-page-three">
            <div className="services-page-three-left p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/VNYNcb1/image-3.png" alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Web development</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Business Insight</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Web development</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                My Vatican Trip
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/zFDxyRd/image-4.png" alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/YkR3tnD/image-5.png" alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Web development</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Maksuda Mou</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Web development</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                Care Trip
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/M6qN8Rt/image-35.png" alt="" />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default WebDesignPortfolio;
