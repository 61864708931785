import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import '../../assets/css/portfolio.css';
// photos
import cLogo1 from "../../assets/images/portfolio/1.image 6.png";
import cLogo2 from "../../assets/images/portfolio/ATN_Partnership Logo.png";
import cLogo3 from "../../assets/images/portfolio/Wheels2.png";
import cLogo4 from "../../assets/images/portfolio/agencyio.png";
import cLogo5 from "../../assets/images/portfolio/5.image 9.png";
import cLogo6 from "../../assets/images/portfolio/mlt-1.png";
import cLogo7 from "../../assets/images/portfolio/trabn.png";
import cLogo8 from "../../assets/images/portfolio/7.Maksuda-Akter-Logo.png";
import cLogo9 from "../../assets/images/portfolio/9.image 12.png";
import cLogo10 from "../../assets/images/portfolio/10.image 13.png";
import cLogo11 from "../../assets/images/portfolio/11.image 14.png";
import cLogo12 from "../../assets/images/portfolio/12.image 15.png";
import YouTube from 'react-youtube';
import Testimonial from "../blocks/PortfolioReview";

function Portfolio() {
  const myRef = useRef(null);
  const [sectionList, setSectionList] = useState([]);
  const len = sectionList.length;
  const half = sectionList.length / 2;
  const [call, setCall] = useState(false);

  // console.log(sectionList);

  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  const getAllSectionList = async () => {
    try {
      const dataFetched = await import("../../utils/services/portfolio.service").then(
        async (service) => await service.getSection({})
      );
      if (dataFetched) {
        // console.log(dataFetched)
        if (dataFetched.data.length > 0) {
          setSectionList(dataFetched.data);
          setCall(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllSectionList();
  }, [])

  const handleSectionId = (id) => {
    localStorage.setItem("weTSectionId", JSON.stringify(id));
  }

  const opts = {
    height: '280',
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      // mute: 1,
      loop: 1
    },
  };

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            {" "}
            Portfolio | WeTech
          </title>
          <meta
            name="description"
            content="Portfolio | WeTech"
          ></meta>
          <meta
            name="keywords"
            content="crm roi
,roi service
,return on investment company
,return on investment optimized service provider"
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="row p-4 primary-bg">
              <div className="col-md-7 mt-3">
                <div className="">
                  <h1 className="item-title">Our Portfolio </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    WeTech Digital is one of the fast growing digital marketing companies that's
                    handling various projects of many reputed organizations. Established in 2020,
                    we have continuously strived towards imparting digital skills to clients.
                    Go through the whole portfolio to
                    learn more about us!!!
                  </p>
                </div>
              </div>
              <div className="col-md-5 mt-3">
                <YouTube videoId="IxJj5OzH8bM" opts={opts} />
                {/* <iframe
                 className="ytPlayer"
                  src="https://www.youtube.com/embed/IxJj5OzH8bM?rel=0" 
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

                {/* <div className="right-image-section bgImageOfport"
                 style={{borderRadius: "10px", border: "2px solid #fff", backgroundImage: `url(https://i.ibb.co/dDBVNBT/sddefault.jpg)`}}>
                  <p className="play-icon-filled cursor" onClick={()=> setOpen(true)}></p>
                  <Player open={open} toggleModal={onOpenModal} />
                </div> */}
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}
          <div className="mb-5 pb-5">
            <h2 className="text-center font-weight-bold secondary-clr">We’ve Served 327+ Clients</h2>
            {/* client Logo */}
            <div className="row mt-4">
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className="mt-md-4 mt-0 img-fluid" src={cLogo1} alt="" />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className=" img-fluid" src={cLogo2} alt="" />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className="mt-4  img-fluid" src={cLogo3} alt="" />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className=" img-fluid" src={cLogo4} alt="" />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className="img-fluid" src={cLogo5} alt="" />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className="mt-4 img-fluid" src={cLogo6} alt="" />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className="img-fluid" src={cLogo7} alt="" />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className="img-fluid" src={cLogo8} alt="" />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className="img-fluid" src={cLogo9} alt="" />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className="img-fluid" src={cLogo10} alt="" />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className="img-fluid" src={cLogo11} alt="" />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="client-logo">
                  <img className="img-fluid c-logo-img" src={cLogo12} alt="" />
                </div>
              </div>
            </div>

          </div>

          {
            sectionList.length > 0 ? (
              sectionList.slice(0, half).map((data, i) =>
                (i % 2 === 0) ? (
                  <div className="services-page-two" ref={myRef} key={data._id}>
                    <div className="services-page-two-left" >
                      <div className="page-two-header">
                        <span className="header">
                          <p className="mb-0">{data?.name}</p>
                          <span
                            style={{
                              borderTop: "1px solid white",
                              width: "60px",
                              marginLeft: "5px",
                              marginTop: "12px",
                            }}
                          ></span>
                        </span>
                      </div>
                      <div className="page-two-title" >
                        <p className="title">
                          {data?.name}
                        </p>
                      </div>
                      <div className="page-two-paragraph">
                        <p className="paragraph">
                          {data?.description}
                        </p>
                        <div className="box-one-button">
                          <Link to={`/portfolio/${data?.customUrl}`} className="text-uline-rm">
                            <button className="services-button" onClick={() => handleSectionId(data._id)}>
                              {/* onClick={() => handleSectionId(data._id)} */}
                              <span>See Portfolio</span> <span>{">"}</span>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="services-page-two-right">
                      <img className="image-1" src={data?.file} alt="" />
                    </div>
                  </div>
                ) : (
                  <div className="services-page-three" key={data._id} >
                    <div className="services-page-three-left">
                      <img className="image-1" src={data?.file} alt="" />
                    </div>
                    <div className="services-page-three-right" >
                      <div className="page-three-header">
                        <span className="header">
                          <p className="mb-0">{data?.name}</p>
                          <span
                            style={{
                              borderTop: "1px solid white",
                              width: "60px",
                              marginLeft: "5px",
                              marginTop: "12px",
                            }}
                          ></span>
                        </span>
                      </div>
                      <div className="page-three-title">
                        <p className="title">{data?.name}</p>
                      </div>
                      <div className="page-three-paragraph">
                        <p className="paragraph">
                          {data?.description}
                        </p>
                        <div className="box-one-button">
                          <Link to={`/portfolio/${data?.customUrl}`} className="text-uline-rm">
                            <button className="services-button" onClick={() => handleSectionId(data._id)} >
                              {/* onClick={() => handleSectionId(data._id)} */}
                              <span>See Portfolio</span> <span>{">"}</span>
                            </button>
                          </Link>

                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
            ) : null
          }


          {
            sectionList ? (
              sectionList.length > 1 ? (
                <div className="services-page-nine" >
                  <div className="page-nine-header">
                    <div className="nine-header-left">
                      {/* <p className="eleven-left-title1">
                        Reviews
                        <span
                          style={{
                            borderTop: "1px solid black",
                            width: "60px",
                            marginLeft: "5px",
                            marginTop: "12px",
                          }}
                        ></span>
                      </p>
                      <p className="page-nine-title">What our customers are saying</p> */}
                    </div>
                    <div className="nine-header-right"></div>
                  </div>
                  <div className="page-nine-content-section">
                    {/* <Testimonial call={call} /> */}
                  </div>
                </div>
              ) : null
            ) : null
          }

          {
            sectionList.length > 1 ? (
              sectionList.slice(half, len).map((data, i) =>
                (i % 2 === 0) ? (
                  <div className="services-page-two" ref={myRef} key={data._id} >
                    <div className="services-page-two-left" >
                      <div className="page-two-header">
                        <span className="header">
                          <p className="mb-0">{data?.name}</p>
                          <span
                            style={{
                              borderTop: "1px solid white",
                              width: "60px",
                              marginLeft: "5px",
                              marginTop: "12px",
                            }}
                          ></span>
                        </span>
                      </div>
                      <div className="page-two-title">
                        <p className="title">
                          {data?.name}
                        </p>
                      </div>
                      <div className="page-two-paragraph">
                        <p className="paragraph">
                          {data?.description}
                        </p>
                        <div className="box-one-button">
                          <Link to={`/portfolio/${data?.customUrl}`} className="text-uline-rm">
                            <button className="services-button" onClick={() => handleSectionId(data._id)}>
                              <span>See Portfolio</span> <span>{">"}</span>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="services-page-two-right">
                      <img className="image-1" src={data?.file} alt="" />
                    </div>
                  </div>
                ) : (
                  <div className="services-page-three" key={data._id}>
                    <div className="services-page-three-left">
                      <img className="image-1" src={data?.file} alt="" />
                    </div>
                    <div className="services-page-three-right">
                      <div className="page-three-header">
                        <span className="header">
                          <p className="mb-0">{data?.name}</p>
                          <span
                            style={{
                              borderTop: "1px solid white",
                              width: "60px",
                              marginLeft: "5px",
                              marginTop: "12px",
                            }}
                          ></span>
                        </span>
                      </div>
                      <div className="page-three-title">
                        <p className="title">{data?.name}</p>
                      </div>
                      <div className="page-three-paragraph">
                        <p className="paragraph">
                          {data?.description}
                        </p>
                        <div className="box-one-button">
                          <Link to={`/portfolio/${data?.customUrl}`} className="text-uline-rm">
                            <button className="services-button" onClick={() => handleSectionId(data._id)}>
                              <span>See Portfolio</span> <span>{">"}</span>
                            </button>
                          </Link>

                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
            ) : null
          }

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Portfolio;
