import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import data from "./pricing/AmazonPPCManagement";
import ContactWetech from "../../blocks/ContactWetech";
// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
//import eCom from "../../../assets/images/eComPPC.png";
import jam from "../../../assets/images/jamCheck.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/ppcAdvertising/1.PinClipart 2 (1).png";
import image2 from "../../../assets/images/ppcAdvertising/2.ppc-pay-per-click-concept-businessman-working-concept 1.png";
import image3 from "../../../assets/images/ppcAdvertising/3.businesspeople-shaking-hands 1.png";
import image4 from "../../../assets/images/ppcAdvertising/4.business-connecting-piece-multi-colored-pie-wood-chart-hand (1) 1.png";
import image5 from "../../../assets/images/ppcAdvertising/5.skeptical-frustrated-asian.png";
import image6 from "../../../assets/images/ppcAdvertising/6.glad-forty-years-old-woman.png";
import { Link } from "react-router-dom";

function PPCManagement() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            PPC Advertising service: Google Ads PPC agency | WeTech Digital{" "}
          </title>
          <meta
            name="description"
            content="To get a powerful PPC Advertising service, check out our Google Ads PPC Campaign packages or book a free consultation. We provide worldwide services as one of the best PPC Advertising Company."></meta>
          <meta
            name="keywords"
            content="
          
          ppc advertising

          ,ppc advertising company
          ,ppc advertising companies
          ,ppc advertising services
          ,google ppc campaigns
          ,google ppc
          ,google ppc ads
          ,google ppc agency
          ,google ads ppc


          
          "></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">PPC Advertising</h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Did you search for a result-driven PPC advertising agency
                    for your business? We have the best solutions for you
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top PPC Campaign Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top PPC advertising company. We are not
                  efficient unless you achieve your desired success, and that's
                  why your company is our highest concern. Our experts are
                  dedicated to helping your company grow and thrive. There are
                  many PPC advertising companies out there in the market. They
                  follow some methods, like Google PPC campaigns. Google Ads is
                  useful only if your advertising gets real visitors. Even then,
                  Google ads PPC are most used. While some Google PPC
                  advertising campaign services use the same standardized
                  reports for every account, WeTech Digital creates personalized
                  reports based on the KPIs that are most relevant to your
                  company. As a PPC advertising company, our PPC experts are
                  among the finest in the global industry, with a demonstrated
                  history of working to grow tons of successful companies.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to Google PPC Ads Service</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  We specify each of the research carefully and verify which
                  campaign is going to cover your companies' demand. We also
                  analyze search queries to identify specific information people
                  have about the products and services you provide. We will
                  drive more traffic to websites by optimizing these search
                  queries and ensuring your content contains the information
                  your audience is looking for. Sometimes, we also arrange
                  Google PPC campaigns if required or our customers demand it.
                  In Google PPC ads campaign we offer display, video, product
                  listing, automated ads etc. Our approaches make WeTech Digital
                  one of the best PPC advertising companies. Our PPC advertising
                  services are very famous in the market. As a Google PPC
                  agency, our Google ads PPC are most effective.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process </p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Research</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Competitor analysis</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Optimization</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Detailed analytics reporting</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Increase your ROI through a flexible PPC Campaign!
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us.</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We have global PPC specialists (Included international &
                    proven capabilities)
                  </li>
                  <li className="page-six-li">
                    We are more than the best PPC advertising service provider;
                    we can also handle all types of clients’ needs.
                  </li>
                  <li className="page-six-li">
                    Our PPC advertising services are very different from others
                    and the outcome is very satisfactory.
                  </li>
                  <li className="page-six-li">
                    Clear and Customized reporting
                  </li>
                  <li className="page-six-li">
                    We provide professional Google PPC ads, demanding and
                    effective PPC advertising solutions for small to large
                    businesses.{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Result-oriented outcomes as a local & global PPC agency
                  </li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements
                  </li>
                  <li className="page-six-li">
                    We plan, execute, and refine strategy through our experts.
                  </li>
                  <li className="page-six-li">
                    With our service, you can rely on us as a Google PPC agency.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end PPC Advertising Service At The Right Price
              </p>
            </div>
            <div className="pricing-main">
              <div className="pricing-table-section">
                <div className="table-header">
                  <div className="feature-section">
                    <h2 className="table-title">Features</h2>
                  </div>
                  <div className="silver-section">
                    <h2 className="table-title">Silver</h2>
                  </div>
                  <div className="gold-section">
                    <h2 className="table-title">Gold</h2>
                  </div>
                  <div className="platinum-section">
                    <h2 className="table-title">Platinum</h2>
                  </div>
                </div>

                <div className="table-content1">
                  <div className="feature">
                    <p></p>
                  </div>

                  <div className="silver">
                    <p>300/month</p>
                  </div>
                  <div className="gold">
                    <p>14% of ad spend</p>
                  </div>
                  <div className="platinum">
                    <p>12% of ad spend</p>
                  </div>
                </div>
                {data.map((data) => (
                  <div className="table-content1">
                    <div className="feature">
                      <p>{data.name}</p>
                    </div>

                    <div className="silver">
                      {data.silver === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.silver}</p>
                      )}
                    </div>
                    <div className="gold">
                      {data.gold === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.gold}</p>
                      )}
                    </div>
                    <div className="platinum">
                      {data.platinum === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.platinum}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default PPCManagement;
