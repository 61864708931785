const data = [
  {
    name: "Pages reviewed",
    silver: "Up to 20 pages",
    gold: "Up to 50 pages",
  },
  {
    name: "Audit implementation training/support",
    silver: "Up to 1 hour",
    gold: "Up to 3 hours",
  },
  {
    name: "Complete audit report upon completion",
    silver: "",
    gold: "",
  },
  {
    name: "Future site strategy (One sheet)",
    silver: "",
    gold: "",
  },
  {
    name: "Competitive scorecard (Analysis of up to 3 competitors)",
    silver: "",
    gold: "",
  },
  {
    name: "Backlink report",
    silver: "",
    gold: "",
  },
  {
    name: "Keyword research (Up to 50 terms)",
    silver: "",
    gold: "",
  },
  {
    name: "SEO Checker reporting on all pages",
    silver: "",
    gold: "",
  },
  {
    name: "External link acquisition strategy",
    silver: "Additional $250",
    gold: "Additional $250",
  },
  {
    name: "In-Depth Link Audit [Optional]",
    silver: "Additional $600",
    gold: "Additional $600",
  },
  {
    name: "Analysis Checklist:",
    silver: " ",
    gold: " ",
  },
  {
    name: "301 redirects",
    silver: "",
    gold: "",
  },
  {
    name: "Broken links",
    silver: "",
    gold: "",
  },
  {
    name: "Canonicalization",
    silver: "",
    gold: "",
  },
  {
    name: "Code to text ratio",
    silver: "",
    gold: "",
  },
  {
    name: "Crawl errors - complete list",
    silver: "",
    gold: "",
  },
  {
    name: "Geo-locationGeo-location",
    silver: "",
    gold: "",
  },
  {
    name: "Google Webmaster tools parameter exclusions",
    silver: "",
    gold: "",
  },
  {
    name: "Google Webmaster tools preferred domain",
    silver: "",
    gold: "",
  },
  {
    name: "HTML/CSS code review",
    silver: "",
    gold: "",
  },
  {
    name: "HTTP status codes - home page",
    silver: "",
    gold: "",
  },
  {
    name: "HTTP status codes - sitewide",
    silver: "",
    gold: "",
  },
  {
    name: "Image optimization",
    silver: "",
    gold: "",
  },
  {
    name: "Internal link structure",
    silver: "",
    gold: "",
  },
  {
    name: "Page content",
    silver: "",
    gold: "",
  },
  {
    name: "Remote use of .js & CSS",
    silver: "",
    gold: "",
  },
  {
    name: "Robots.txt configuration",
    silver: "",
    gold: "",
  },
  {
    name: "Shared IP addresses",
    silver: "",
    gold: "",
  },
  {
    name: "Site compression configuration",
    silver: "",
    gold: "",
  },
  {
    name: "Site load time - average",
    silver: "",
    gold: "",
  },
  {
    name: "Site load time - home page",
    silver: "",
    gold: "",
  },
  {
    name: "Sitemap.xml configuration",
    silver: "",
    gold: "",
  },
  {
    name: "Sitemap.xml index rate",
    silver: "",
    gold: "",
  },
  {
    name: "Static/dynamic URL configuration",
    silver: "",
    gold: "",
  },
  {
    name: "Title tag optimization",
    silver: "",
    gold: "",
  },
  {
    name: "Analytics program configuration",
    silver: "",
    gold: "",
  },
  {
    name: "Duplicate content",
    silver: "",
    gold: "",
  },
  {
    name: "Home page content",
    silver: "",
    gold: "",
  },
  {
    name: "Information architecture",
    silver: "",
    gold: "",
  },
  {
    name: "Link profile",
    silver: "",
    gold: "",
  },
  {
    name: "Site theme and content",
    silver: "",
    gold: "",
  },
  {
    name: "Spam/malware",
    silver: "",
    gold: "",
  },
  {
    name: "Top keyword rankings",
    silver: "",
    gold: "",
  },
  {
    name: "Top keyword traffic",
    silver: "",
    gold: "",
  },
  {
    name: "Website conversion",
    silver: "",
    gold: "",
  },
  {
    name: "Website wrapper",
    silver: "",
    gold: "",
  },
  {
    name: "Post audit client implementation review (1 hour)",
    silver: "",
    gold: "",
  },
  {
    name: "150+ SMEs behind campaign driving results",
    silver: "",
    gold: "",
  },
  {
    name: "150+ SMEs behind campaign driving results",
    silver: "",
    gold: "",
  },
  {
    name: "Duration",
    silver: "30 days ",
    gold: "45 days",
  },
];
export default data;
