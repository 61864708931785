import React, { useRef } from "react";
import { Helmet } from "react-helmet";
// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/communityManagement/1.419-4199497_redes-sociales-management-community (1) 1.png";
import image2 from "../../../assets/images/communityManagement/2.manager-leading-brainstorming-meeting 1 (1).png";
import image3 from "../../../assets/images/communityManagement/3.men-women-business-agreement-hands-shake 1.png";
import image4 from "../../../assets/images/communityManagement/4.workers-pointing-increase-sales 1 (2).png";
import image5 from "../../../assets/images/communityManagement/5.question-mark-of-coiled-blue-paper-on-yellow-2021-08-29-02-12-27-utc.png";
import image6 from "../../../assets/images/communityManagement/6.businesspeople-celebrating-success 1 (1).png";
import { Link } from "react-router-dom";

function CommunityManagement() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Online community management services at affordable prices | WeTech
          </title>
          <meta
            name="description"
            content="Grow your businesses massively on the social media community with online community management services. Check our community management packages."
          ></meta>
          <meta
            name="keywords"
            content="online community management services ,community management agency
            ,social media community management agency"
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box-custom">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    Community Management
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want to get Online Community Management Services?
                    Then we're here to help you out competently.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Community Management Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top Community Agency in the Market. The
                  administration of a shared resource or issue by a community
                  via the collective activity of volunteers and stakeholders is
                  known as community management or widely accepted resource
                  management. A material or informational resource can be
                  managed. Are you looking for innovative ways to strengthen
                  your consumer relationships? Do you want to improve client
                  loyalty and online involvement with your company? Then, you
                  are in right place. We provide the best online community
                  management services. It lays the groundwork for you to engage
                  with existing and future consumers, as well as for them to
                  communicate with one another, in a controlled atmosphere. As a
                  social media community management agency, we keep our costs
                  comparatively low.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to Community Management</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital will create an online community that is
                  congruent with your brand. It encourages user interaction,
                  allowing you to stay in touch with your consumers at all
                  times. WeTech Digital is dedicated to providing clients with
                  very dedicated works. The greatest way to create long-term
                  connections with your consumers is to increase customer
                  loyalty. The most successful approach to increasing income for
                  a company is creating, facilitating, and nurturing client
                  connections. To fulfill all these, online community management
                  services are important. WeTech Digital can be your savior as
                  we are the top-notch community management agency. There are
                  many social media community management agencies out there, but
                  we are the most affordable one.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">
                    Analyze business background & structure
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Implementation strategy</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Community layout & blog creation
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Select online platforms</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">PPC setup & management</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require Community Management Services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <Link to="/contact">
                      <button className="services-five-button">
                        Contact Us
                      </button>
                    </Link>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We always balancing customer requirements.
                  </li>
                  <li className="page-six-li">
                    We provide more efficient work.
                  </li>
                  <li className="page-six-li">
                    Distinctive methods to the relationship with clients.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platforms.
                  </li>
                  <li className="page-six-li">
                    We have affordable service costs.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Our motto is to give you a managed community circle.
                  </li>
                  <li className="page-six-li">Increasing client retention</li>
                  <li className="page-six-li">
                    We make sure to keep all old & new clients connect with you.
                  </li>
                  <li className="page-six-li">
                    Spreads your fame everywhere by our links to make the
                    community.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Web Community Management Services At The Right
                Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default CommunityManagement;
