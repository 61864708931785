import React from "react";
import "../../../../assets/css/pricing.css";
import jam from "../../../../assets/images/jamCheck.png";

function pricingTable() {
  return (
    <div className="pricing-main">
      <div className="pricing-table-section">
        <div className="table-header">
          <div className="feature-section">
            <h2 className="table-title">Features</h2>
          </div>
          <div className="silver-section">
            <h2 className="table-title">Silver</h2>
          </div>
          <div className="gold-section">
            <h2 className="table-title">Gold</h2>
          </div>
          <div className="platinum-section">
            <h2 className="table-title">Platinum</h2>
          </div>
        </div>
        <div className="table-content1">
          <div className="feature">
            <p>{/* Number of keyphrases optimized */}</p>
          </div>
          <div className="silver">
            <p>$600</p>
          </div>
          <div className="gold">
            <p>$1200</p>
          </div>
          <div className="platinum">
            <p>$1600</p>
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Number of keyphrases optimized</p>
          </div>
          <div className="silver">
            <p>Up to 50</p>
          </div>
          <div className="gold">
            <p>Up to 100</p>
          </div>
          <div className="platinum">
            <p>Up to 150</p>
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Pages Optimized</p>
          </div>
          <div className="silver">
            <p>Up to 15</p>
          </div>
          <div className="gold">
            <p>Up to 25</p>
          </div>
          <div className="platinum">
            <p>Up to 35</p>
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Technical Website Analysis & Opportunity Report</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Keyword research & selection</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Keyword mapping to target pages</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Title Tags & Meta descriptions</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Robots.txt optimization</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>XML sitemap creation & submission</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Media Content Assets - Content, Linkbuilding, Consulting</p>
          </div>
          <div className="silver">
            <p>3-4 Content, Outtreach, UX</p>
          </div>
          <div className="gold">
            <p>7 Content, Outreach, UX</p>
          </div>
          <div className="platinum">
            <p>10 Content, Outreach, UX</p>
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Google My Business optimization (if needed)</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Information architecture audit</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Canonicalization analysis</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Initial external link analysis and disavow</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Link redirect audit</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Internal linking restructuring & optimization</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Duplicate content analysis</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Header Tags Optimized</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Setup of website sitemap</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Image optimization</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Custom 404 error page setup and optimization</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Schema Implementation (JSON-LD)</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Google Analytics setup w/ conversion tracking</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>
              Google Analytics traffic analysisGoogle Analytics setup w/
              conversion tracking
            </p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Google Data Studio Reporting Dashboard Setup</p>
          </div>
          <div className="silver">
            <p>1 dashboards</p>
          </div>
          <div className="gold">
            <p>3 dashboards</p>
          </div>
          <div className="platinum">
            <p>4 dashboards</p>
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Initial visitor profile report</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Ongoing quarterly keyword ranking reporting</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Ongoing monthly traffic, goal & CPL reporting</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>
              Ongoing user experience reporting (bounce rate, device category,
              etc.)
            </p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Ongoing quarterly competitor conquest reports</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>
              Keyword rank checker access with weekly updates (if requested)
            </p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>
              Ability to check location data accuracy across 20+ local
              directories
            </p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>
              A/B Testing of SERP title & description to improve clickthrough
            </p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Ability to review lead journey & visit history</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Monitor competitors' search rankings on a monthly basis</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Monitor competitors' website changes on a monthly basis</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Dedicated Internet Marketer</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>

        <div className="table-content">
          <div className="feature">
            <p>Online Project Management Platform</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>150+ SMEs behind campaign driving results</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Website conversion analysis implementation</p>
          </div>
          <div className="silver">
            <p>Custom</p>
          </div>
          <div className="gold">
            <p>Custom</p>
          </div>
          <div className="platinum">
            <p>Custom</p>
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Initial campaign investment:(Two month duration)</p>
          </div>
          <div className="silver">
            <p>$2500</p>
          </div>
          <div className="gold">
            <p>$4500</p>
          </div>
          <div className="platinum">
            <p>$2500</p>
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>
              Progressive monthly optimization:(6 month commitment - Subsequent
              4 months){" "}
            </p>
          </div>
          <div className="silver">
            <p>$600</p>
          </div>
          <div className="gold">
            <p>$1200</p>
          </div>
          <div className="platinum">
            <p>$1600</p>
          </div>
        </div>

        {/* <div className="pricing-btn-section">
          <div className="button-section">
            <div className="silver-button">
              <div className="button-header">
                <p className="button-content">
                  Tk 300 <span className="btn-content-span">/mon</span>
                </p>
              </div>
              <div className="buttons">
                <button className="pricing-btn-silver">Select</button>
              </div>
            </div>
            <div className="gold-button">
              <div className="button-header">
                <p className="button-content">
                  Tk 300 <span className="btn-content-span">/mon</span>
                </p>
              </div>
              <div className="buttons">
                <button className="pricing-btn-gold">Select</button>
              </div>
            </div>
            <div className="platinum-button">
              <div className="button-header">
                <p className="button-content">
                  Tk 300 <span className="btn-content-span">/mon</span>
                </p>
              </div>
              <div className="buttons">
                <button className="pricing-btn-platinum">Select</button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default pricingTable;
