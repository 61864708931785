import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import $ from "jquery";
// import data from "./portfolioData";
import "../../assets/css/portfolio.css";
import Player from "../portfolio/Player";

function PortfolioReview() {
  const [open, setOpen] = useState(false);
  const [vdLink, setVdLink] = useState("");
  const [allReview, setAllReview] = useState([]);

  const getAllReview = async () => {
    try {
      const fetchedData = await import("../../utils/services/review.service").then(
        async (service) => await service.getReviewList({})
      )
      if (fetchedData) {
        setAllReview(fetchedData.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllReview();
  }, [])

  const options = {
    margin: 10,
    responsiveClass: true,
    autoWidth: true,
    nav: true,
    rewindNav: true,
    dots: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const onOpenModal = () => {
    setOpen(prevState => !prevState);
  };
  const openVdPopup = (videoLink) => {
    setOpen(true);
    setVdLink(videoLink);
  }
  return (
    <>
      <OwlCarousel className="owl-theme" loop margin={10} {...options}>

        {
          allReview.map((data) => (
            <div className="item">
              <div className="portfolio-review-item" style={{ position: "relative" }}>

                <div className="p-3 p-md-5">
                  <div className="" style={{ height: "115px" }}>
                    {/* <h6 className="text-light fw-bold" style={{ textAlign: 'justify' }}>{data?.message}</h6> */}
                  </div>
                  <div className="mt-2">
                    <small className="fw-bold text-light">{data.designation}</small><br />
                    <small className="text-light">{data.name}</small>
                  </div>
                  <div style={{ height: "50px" }}>
                    <span className="play-icon-filled-review cursor"
                      onClick={() => openVdPopup(data.videoUrl)}
                    ></span>
                  </div>
                </div>
                <div className="reviewAuthImg"
                  style={{ backgroundImage: `url(${data?.file})` }}
                ></div>
              </div>
            </div>
          ))
        }
      </OwlCarousel>
      <div className="aaUpperIdx">
        <Player open={open} toggleModal={onOpenModal} videoLink={vdLink} />
      </div>
    </>
  );
}

export default PortfolioReview;

$("#featuredproducts .owl-nav").click(function (event) {
  $(this).removeClass("disabled");
});
