import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import link from "../../../assets/images/link-building-service.png";
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import Pricing from "../../pricingTable";
import Testimonial from "../../blocks/Testimonial";

import linkBuilding1 from "../../../assets/images/link-building-service/link-building-service-1.webp";
import linkBuilding2 from "../../../assets/images/link-building-service/link-building-service-2.webp";
import linkBuilding3 from "../../../assets/images/link-building-service/link-building-service-3.webp";
import linkBuilding4 from "../../../assets/images/link-building-service/link-building-service-4 .webp";
import linkBuilding5 from "../../../assets/images/link-building-service/link-building-service-5.webp";
import { Link } from "react-router-dom";

function LinkBuildingService() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Link building services: Best outreach to increase traffic | WeTech
          </title>
          <meta
            name="description"
            content="A powerful backlink will increase the possibility to rank your website higher on SERP. In addition, to ensure that WeTech Digital provides high-quality link-building services."
          ></meta>
          <meta
            name="keywords"
            content="
          link building services
          ,backlink service
          ,Seo backlink services
          ,best backlink services
          ,high-quality backlinks
          ,Backlink generator
          ,Backlink sites
          ,SEO link building services
          ,link building services company
          ,affordable link building services
          ,best link building services
          ,quality link building services
          ,white hat link building services
          
          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">Link Building Service</p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Would you like to link your website back to any other
                    websites? Because connections signal to Google that the page
                    is a reliable site worthy of reference, building links is
                    one of the numerous methods employed in search engine
                    optimization (SEO). So, recognize your site as a trustworthy
                    link building. Services are needed. We are here to take care
                    of link building services for you. You can approach to us
                    for getting best link building services
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={link} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Link Building Service Company?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  Even if you produce the finest material on a specific topic,
                  Google may not give it a high ranking. In reality, they rarely
                  do. To attract Google traffic these days, you need a lot more
                  than simply content. So, what does it take to win? You'll need
                  high-quality backlinks to your material sooner or later.
                  WeTech Digital works for you as a middler to get you
                  back-links. For good link building, you need to build good
                  relationships; Wetech Digital does that for you. There are
                  plenty of opportunities to make new contacts. Our experts
                  start with niche-related communities: forums, blogs, or social
                  groups. In addition, we offer backlink service. We have a
                  large supply of backlink sites.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={linkBuilding1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={linkBuilding2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to Link Building Services</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital provides personalized and innovative services
                  to improve the SEO performance of your website by constructing
                  high-quality backlinks. We have best seo backlink services.
                  SEO link building services, we provide can beat other company.
                  Our provided backlink services are one of the best backlink
                  services. To deliver high quality backlinks, we follow some
                  strategies like Linkable online advertising, columnist
                  interaction, visitor posting, strategic link insertion and
                  curated in-article backlinks, all examples of linkable content
                  marketing, etc., based on your SEO strategy company goals by
                  our affordable link building services. The most important
                  element of the world of SEO is a TOOL backlink generator for
                  Organic Traffic. A method of attracting visitors to a website
                  using (SEO) tactics such as appropriate linking to other
                  relevant material on the Internet is White hat link building.
                  Wetech Digital serves best White hat link building services
                  for outstanding results. However, our company is a high class
                  link building services company.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Development Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Study your business</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Connected you with backlinks</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={linkBuilding3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you want to get Link Building Services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us.</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We offers quality link building services
                  </li>

                  <li className="page-six-li">
                    We have the best & flexible Teams.
                  </li>
                  <li className="page-six-li">We give you authority.</li>
                  <li className="page-six-li">
                    Our work gives more qualified traffics.
                  </li>
                  <li className="page-six-li">
                    We make sure that your brand mentions across the network.
                  </li>
                  <li className="page-six-li">
                    We ensure good leads and sales.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={linkBuilding4} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={linkBuilding5} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We have good communication and connections that get
                    backlinks quickly.
                  </li>
                  <li className="page-six-li">
                    We'd like to ask permission to repost all or part of a
                    fantastic article on your site.
                  </li>
                  <li className="page-six-li">
                    Increasing your competitive advantage
                  </li>
                  <li className="page-six-li">
                    Meeting industry-specific needs
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="page-ten-header">
            <p className="page-ten-title">
              Find High-end SEO Service At The Right Price
            </p>
          </div>
          <Pricing />

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default LinkBuildingService;
