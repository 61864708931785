import React, { useRef } from "react";
import ContactWetech from "../../blocks/ContactWetech";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
import seoService1 from "../../../assets/images/rsz-home6.png";
import seoService2 from "../../../assets/images/rsz-seoservice2.png";
import seoService3 from "../../../assets/images/rsz-home2.png";
import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import Testimonial from "../../blocks/Testimonial";
import { Link } from "react-router-dom";

function SocialMediaManagementService() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Social media marketing & management services packages | WeTech{" "}
          </title>
          <meta
            name="description"
            content="Your social media marketing &  management will be easier & faster. WeTech Digital is the best practicing social media management services company."
          ></meta>
          <meta
            name="keywords"
            content="
          
          social media management services
,social media management company
,social media management agency
,cost of social media management services
,social media management services pricing
,top social media management company
,social media management services cost
,social media management company for small business
,social media management company pricing


          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box-custom">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    Social Media Management Service
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want well to build up social media marketing &
                    management services? Then we are here to please you
                    providing that professionally.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span> {">"} </span>
                  </button>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img
                    className="service-smile-image-landing"
                    src={smile}
                    alt="smile"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Social Media Marketing & Management
                  Service Company?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top social media management company in
                  the market, providing affordable social media management
                  services. Your company can start increasing brand recognition
                  and earning income from social media sites like Facebook,
                  LinkedIn, Instagram, and others with a tailored social media
                  marketing service package. When it comes to making a
                  purchasing choice, over 75% of consumers utilize social media.
                  Companies engage in professional social media services because
                  it is a crucial touchpoint for shoppers in the
                  business-to-consumer (B2C) and business-to-business (B2B)
                  industries. WeTech Digital is a reputable provider of social
                  media marketing services, with a customer recommendation score
                  that is 488 percent higher than the industry average. We'll
                  work with you to increase brand exposure, customer loyalty,
                  income, and more as your partner. You can choose us as a
                  trustable social media management agency.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Social Media Management Service
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  Browse our service bundles and rates below to see how (and
                  why) our social media management services are the best. You
                  may also use our free, user-friendly calculator to create a
                  company estimate. WeTech Digital is best in social media
                  management services pricing. Because no two businesses are
                  similar, we provide customized social media management
                  services. For this, the cost of social media management
                  services varies. Social Media Management Company pricing is
                  also different in this case of differences. We are also a
                  reliable social media management company for small businesses.
                  WeTech Digital is one of the top social media management
                  companies out there.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Development Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Develop strategy</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Create content</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    List of related thins & persons
                  </p>
                </div>

                <div className="growth-process">
                  <p className="process-steps">Sharing</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Create hashtags</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={seoService3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Want Social Media Management Service?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us.</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why WeTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We provide affordable social media management services cost.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platforms.
                  </li>
                  <li className="page-six-li">
                    Provide social media postings that are unique.
                  </li>
                  <li className="page-six-li">Website copy analysis</li>
                  <li className="page-six-li">
                    Solving your problems steadily
                  </li>
                  <li className="page-six-li">
                    Delivery to you after we are satisfied
                  </li>
                  <li className="page-six-li">
                    Will work on it again if you are still not satisfied.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService2} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService4} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why WeTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Designing custom pictures, covers, and profile photos.
                  </li>
                  <li className="page-six-li">
                    We offer social media network establishment, optimization,
                    and identity auditing services.
                  </li>
                  <li className="page-six-li">
                    We offer brand reputation analysis, improved post-ad
                    management, and more.{" "}
                  </li>
                  <li className="page-six-li">
                    Data-driven social strategy, in-depth competition analysis
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Social Media Management Service Service At The
                Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-main">
                <div className="pricing-table-section">
                  <div className="table-header">
                    <div className="feature-section">
                      <h2 className="table-title">Features</h2>
                    </div>
                    <div className="silver-section-ex">
                      <h2 className="table-title">1 SOCIAL NETWORK</h2>
                    </div>
                    <div className="gold-section-ex">
                      <h2 className="table-title">2 SOCIAL NETWORKS</h2>
                    </div>
                    <div className="platinum-section-ex">
                      <h2 className="table-title">3 SOCIAL NETWORKS</h2>
                    </div>
                    <div className="platinum-section-ex">
                      <h2 className="table-title">4 SOCIAL NETWORKS</h2>
                    </div>
                  </div>
                  <div className="table-content1">
                    <div className="feature">
                      <p></p>
                    </div>
                    <div className="silver-ex">
                      <p>{"$750"}</p>
                    </div>
                    <div className="gold-ex">
                      <p>{"$950"}</p>
                    </div>
                    <div className="platinum-ex">
                      <p>{"$1050"}</p>
                    </div>
                    <div className="platinum-ex">
                      <p>{"$1200"}</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p></p>
                    </div>
                    <div className="silver-ex">
                      <p></p>
                    </div>
                    <div className="gold-ex">
                      <p></p>
                    </div>
                    <div className="platinum-ex">
                      <p></p>
                    </div>
                    <div className="platinum-ex">
                      <p></p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Number of Posts per Month</p>
                    </div>
                    <div className="silver-ex">
                      <p>Up to 15</p>
                    </div>
                    <div className="gold-ex">
                      <p>Up to 30</p>
                    </div>
                    <div className="platinum-ex">
                      <p>Up to 45</p>
                    </div>
                    <div className="platinum-ex">
                      <p>Up to 60</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>
                        Number of Custom Images per Month Included with Posts
                      </p>
                    </div>
                    <div className="silver-ex">
                      <p>6</p>
                    </div>
                    <div className="gold-ex">
                      <p>8</p>
                    </div>
                    <div className="platinum-ex">
                      <p>10</p>
                    </div>
                    <div className="platinum-ex">
                      <p>12</p>
                    </div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>Number of Boosted Posts</p>
                    </div>
                    <div className="silver-ex">
                      <p>Up to 2</p>
                    </div>
                    <div className="gold-ex">
                      <p>Up to 4</p>
                    </div>
                    <div className="platinum-ex">
                      <p>Up to 6</p>
                    </div>
                    <div className="platinum-ex">
                      <p>Up to 8</p>
                    </div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>Number of Custom Contests Included per Year</p>
                    </div>
                    <div className="silver-ex">
                      <p>0</p>
                    </div>
                    <div className="gold-ex">
                      <p>0</p>
                    </div>
                    <div className="platinum-ex">
                      <p>0</p>
                    </div>
                    <div className="platinum-ex">
                      <p>1</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Social Media Marketing Strategy</p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Social Media Competitive Analysis</p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Social Media Brand Reputation Analysis</p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Social Media Audit + Recommendations</p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>Dedicated Social Media Account Manager</p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>Daily Monitoring of Included Social Assets</p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>Monitoring of Boosted Post Comments</p>
                    </div>
                    <div className="silver-ex">
                      <p>Weekly 3 days</p>
                    </div>
                    <div className="gold-ex">
                      <p>Weekly 3 days</p>
                    </div>
                    <div className="platinum-ex">
                      <p>Weekly 3 days</p>
                    </div>
                    <div className="platinum-ex">
                      <p>Daily</p>
                    </div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>48 Business Hour or Less Customer Response Time</p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>
                        Up to 1 personal social media consultations per month
                      </p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>
                        Access to editorial calendar & content publishing tools
                      </p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>Access to social media contest tools</p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>Standard Monthly Reporting and Analysis</p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>Cover Photo & Profile Photo Design/Optimization</p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>300+ SMEs behind campaign driving results</p>
                    </div>
                    <div className="silver-ex"></div>
                    <div className="gold-ex"></div>
                    <div className="platinum-ex"></div>
                    <div className="platinum-ex"></div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>
                        Ongoing Monthly Campaign Investment (Standard
                        Consultation and Reporting)
                      </p>
                    </div>
                    <div className="silver-ex">
                      <p>$750</p>
                    </div>
                    <div className="gold-ex">
                      <p>$950</p>
                    </div>
                    <div className="platinum-ex">
                      <p>$1050</p>
                    </div>
                    <div className="platinum-ex">
                      <p>$1200</p>
                    </div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>
                        Required Boosted Posts Ad Spend (Standard Consultation
                        and Reporting)
                      </p>
                    </div>
                    <div className="silver-ex">
                      <p>$100-$200</p>
                    </div>
                    <div className="gold-ex">
                      <p>$200-$400</p>
                    </div>
                    <div className="platinum-ex">
                      <p>$300-$600</p>
                    </div>
                    <div className="platinum-ex">
                      <p>$400-$800</p>
                    </div>
                  </div>

                  {/* <div className="pricing-btn-section">
                    <div className="button-section">
                      <div className="silver-button-ex">
                        <div className="button-header">
                          <p className="button-content">
                            Tk 300{" "}
                            <span className="btn-content-span">/mon</span>
                          </p>
                        </div>
                        <div className="buttons">
                          <button className="pricing-btn-silver">Select</button>
                        </div>
                      </div>
                      <div className="gold-button-ex">
                        <div className="button-header">
                          <p className="button-content">
                            Tk 300{" "}
                            <span className="btn-content-span">/mon</span>
                          </p>
                        </div>
                        <div className="buttons">
                          <button className="pricing-btn-gold">Select</button>
                        </div>
                      </div>
                      <div className="platinum-button-ex">
                        <div className="button-header">
                          <p className="button-content">
                            Tk 300{" "}
                            <span className="btn-content-span">/mon</span>
                          </p>
                        </div>
                        <div className="buttons">
                          <button className="pricing-btn-platinum">
                            Select
                          </button>
                        </div>
                      </div>
                      <div className="platinum-button-ex">
                        <div className="button-header">
                          <p className="button-content">
                            Tk 300{" "}
                            <span className="btn-content-span">/mon</span>
                          </p>
                        </div>
                        <div className="buttons">
                          <button className="pricing-btn-platinum">
                            Select
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default SocialMediaManagementService;
