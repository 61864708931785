const data = [
  {
    name: "Ad spend (across all Google ad networks)",
    silver: "Up to $1,000/mo.",
    gold: "Up to $1,001 - $5,000/mo.",
    platinum: "$5,000+/mo.",
  },
  {
    name: "Google Guarantee FAQ guide",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Minimum 3.5 star Google Review rating required",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Voice Search Optimization",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Weekly Review of Submitted Leads for Accuracy",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Eligibility submission and communicationy",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Budget Management",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Budget Management",
    silver: "Weekly",
    gold: "",
    platinum: "",
  },
  {
    name: "Reporting",
    silver: "Monthly reporting",
    gold: "Automatic monthly cloud updates",
    platinum: "Automatic monthly cloud updates",
  },
  {
    name: "Geographic Targeting",
    silver: "Up to 1 geographic location",
    gold: "Up to 3 geographic locations",
    platinum: "Up to 15 geographic location",
  },
  {
    name: "Service Category Targeting ",
    silver: "Up to 2 service categories",
    gold: "Up to 4 service categories",
    platinum: "Up to 8 service categories",
  },
  {
    name: "Templated review solicitations through LSA ",
    silver: "1 template",
    gold: "5 templates",
    platinum: "5 templates",
  },
  {
    name: "Setup Fee",
    silver: "$500",
    gold: "$850",
    platinum: "$1,200",
  },
  {
    name: "Monthly Management Fee",
    silver: "$175",
    gold: "12%",
    platinum: "$1,200",
  },
  {
    name: "Added to any other PPC plan:",
    silver: "$100/mo.",
    gold: "$100/mo.",
    platinum: "$100/mo.",
  },
  {
    name: "Added to any other local SEO plan:",
    silver: "$100/mo.",
    gold: "$100/mo.",
    platinum: "$100/mo.",
  },
];
export default data;
