import React from "react";
import ReactPlayer from "react-player";
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';

const customStyles = {
  content: {
    top: '55%',
    left: '50%',
    right: 'auto',
    height: "650px",
    width: "55%",
    padding: "0px",
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default function Player({ open, toggleModal, videoLink }) {

  return (
    <Modal
      isOpen={open}
      style={customStyles}
    >
      <div className="text-right" >
        <span className="cursor transparant px-2" onClick={toggleModal}>
          <AiOutlineClose />
        </span>
      </div>
      <ReactPlayer
        url={videoLink}
        // "https://www.youtube.com/embed/R49BcDd7OdY?rel=0"
        width="100%"
        height="650px"
      />
    </Modal>
  );
}   