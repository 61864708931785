import React, { useRef } from "react";
import ContactWetech from "../../blocks/ContactWetech";
import data from "./pricing/EcommercePPC";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
import seoService1 from "../../../assets/images/rsz-home6.png";
import seoService2 from "../../../assets/images/rsz-seoservice2.png";
import seoService3 from "../../../assets/images/rsz-home2.png";
import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import eCom from "../../../assets/images/eComPPC.png";
import jam from "../../../assets/images/jamCheck.png";
import Testimonial from "../../blocks/Testimonial";
import { Link } from "react-router-dom";

function EcommercePPCService() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            E-commerce PPC Management: PPC management packages | WeTech Digital
          </title>
          <meta
            name="description"
            content="Get E-commerce PPC management services to increase your eCommerce store sales. We provide eCommerce PPC service at the lowest cost packages."
          ></meta>
          <meta
            name="keywords"
            content="
          
          ecommerce ppc management
          ,Secondary Keywords
          ,ecommerce ppc services
          ,ecommerce ppc agency
          ,ppc marketing services
          ,ppc service provider
          ,local ppc management
          ,ppc management packages
          ,ppc service provider
          ,ecommerce ppc management company


          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    Ecommerce PPC management
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Taking your store online & helping to build practical
                    e-commerce PPC solutions that work for your business. Grab
                    our Ecommerce PPC services.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={eCom} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top E-commerce PPC Management Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top ecommerce PPC management service
                  provider. We offer dedicated developers and programmers to
                  support your team. Top companies and start-ups choose us to
                  provide the highest quality, stress-free e-commerce PPC
                  management services and solutions. Our dedicated team of
                  highly trained and experienced developers is devoted to
                  meeting your needs in the modern world across a broad range of
                  offerings, providing expert know-how and expertise with 100%
                  project delivery success. Our provided ecommerce PPC services
                  are very popular. As an ecommerce PPC agency, Wetech Digital
                  offers PPC marketing services. Wetech Digital, a PPC service
                  provider, also offers local PPC management with many types of
                  PPC management packages. If you are looking for an ecommerce
                  PPC Management Company, then we are perfect to serve you.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to E-commerce PPC Management
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  We are very popular ecommerce PPC agency. Our e-Commerce
                  development solutions are based on extensive analysis and
                  data-driven techniques that address various aspects of
                  ecommerce and its elements. We don't make powerful or magic
                  stuff, although, with our ecommerce PPC services’ help, you
                  can manage much more. Learn about our innovative designers,
                  skilled developers, and customer-focused project managers who
                  can provide you with the best PPC marketing services possible.
                  We believe in providing the best service in the local and
                  global markets, as we want to be known as the best PPC service
                  provider. Our local PPC management is very trustworthy to our
                  home customers. So always care about your service that can be
                  better with our special effort and ensure that you’ll get
                  exactly what you want.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Management Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Analysis</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Plan</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Development </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Testing</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Maintenance</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={seoService3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require Ecommerce PPC management Services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let our e-commerce experts help to explore your online store
                    through our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Ensure your website has Payment system integration
                  </li>
                  <li className="page-six-li">
                    We always keep the focus on Robust security
                  </li>
                  <li className="page-six-li">
                    We can manage PHP code and configure your online store's
                    frontend and backend.
                  </li>
                  <li className="page-six-li">
                    We seem more than the best PPC advertising service provider;
                    we can also handle all types of client’s needs.
                  </li>
                  <li className="page-six-li">
                    We use robust techniques to grow any business with an online
                    presence
                  </li>
                  <li className="page-six-li">
                    Clear and Customized reporting
                  </li>
                  <li className="page-six-li">
                    We provide professional Google PPC ads demanding and
                    effective PPC advertising solutions for small to large
                    businesses.{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService2} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService4} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Result-oriented outcomes as a local & global E-commerce PPC
                    agency
                  </li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements s
                  </li>
                  <li className="page-six-li">
                    We provide affordable PPC management packages
                  </li>
                  <li className="page-six-li">
                    We plan, execute, and refine strategy through our experts.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Ecommerce PPC Service At The Right Price
              </p>
            </div>
            <div className="pricing-main">
              <div className="pricing-table-section">
                <div className="table-header">
                  <div className="feature-section">
                    <h2 className="table-title">Features</h2>
                  </div>
                  <div className="silver-section">
                    <h2 className="table-title">Silver</h2>
                  </div>
                  <div className="gold-section">
                    <h2 className="table-title">Gold</h2>
                  </div>
                  <div className="platinum-section">
                    <h2 className="table-title">Platinum</h2>
                  </div>
                </div>
                <div className="table-content1">
                  <div className="feature">
                    <p></p>
                  </div>

                  <div className="silver">
                    <p>15% of ad spend / month</p>
                  </div>
                  <div className="gold">
                    <p>14% of ad spend / month</p>
                  </div>
                  <div className="platinum">
                    <p>12% of ad spend / month</p>
                  </div>
                </div>
                <div className="table-content1">
                  <div className="feature">
                    <p></p>
                  </div>

                  <div className="silver">
                    <p>INITIAL INVESTMENT: $475 + 1 Months Management Fee</p>
                    <p>$850 minimum</p>
                  </div>
                  <div className="gold">
                    <p>INITIAL INVESTMENT: $350 + 1 Months Management Fee</p>
                    <p>$1,450 minimum</p>
                  </div>
                  <div className="platinum">
                    <p>INITIAL INVESTMENT: 1 Months Management Fee</p>
                    <p>$3,500 minimum</p>
                  </div>
                </div>
                {data.map((data) => (
                  <div className="table-content1">
                    <div className="feature">
                      <p>{data.name}</p>
                    </div>

                    <div className="silver">
                      {data.silver === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.silver}</p>
                      )}
                    </div>
                    <div className="gold">
                      {data.gold === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.gold}</p>
                      )}
                    </div>
                    <div className="platinum">
                      {data.platinum === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.platinum}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default EcommercePPCService;
