import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/portfolioSite/1.573-5733262_portfolio-website 1.png";
import image2 from "../../../assets/images/portfolioSite/2.guy-shows-document-girl-group-young-freelancers-office-have-conversation-working 1.png";
import image3 from "../../../assets/images/portfolioSite/3.corporate-businessmen-shaking-hands 1.png";
import image4 from "../../../assets/images/portfolioSite/40.hands-writing-business-plan-sheet 1.png";
import image5 from "../../../assets/images/portfolioSite/5.confused-youngster.png";
import image6 from "../../../assets/images/portfolioSite/6.png";
import { Link } from "react-router-dom";

function PortfolioSite() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>WeTech - Portfolio Site</title>
          <meta name="description" content="Basic content"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box-custom">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">Portfolio Site </p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Would you like to get work as a freelancer, get more clients
                    for your firm, or get a job? A portfolio site is a digital
                    version of a freelancer's (or firm's) resume. It gives
                    potential clients an easy method to see your work while also
                    allowing you to broaden your talents and services. This
                    isn't, however, the primary goal of a portfolio website. Our
                    portfolio site experts can help you out.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span> {">"} </span>
                  </button>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="img-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Portfolio Site Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  The primary objective of a portfolio website is to help you
                  gain more clients, whether that means freelancing work, new
                  clients for your firm, or job opportunities. Before you start
                  adding material to your website, you need to figure out what
                  you want to achieve. For freelancers operating in the internet
                  era, portfolio sites are essential. While all freelancers can
                  send out business cards, and other freelancers, such as
                  photographers, can hand out actual portfolios to potential
                  clients, a portfolio website allows any freelancer in any
                  profession to reach a larger number of clients on a worldwide
                  scale. It also allows you to be more creative with the details
                  and information you reveal about yourself and your business.
                  Sometimes your portfolio is for photography websites, you have
                  to find the best photography websites where we can arrange
                  help. We also customize personal portfolio websites. Wetech
                  Digital is renowned for creating the best portfolio websites.
                  Our portfolio website examples are admirable.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to Portfolio Site</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  The types of content and elements you add to your site as well
                  as the way you implement them will affect your work to find
                  clients. Our world-class Portfolio Site experts keep their
                  eyes on some features to create a good portfolio site. They
                  synchronically work with design, tag-lined logo, CTA,
                  high-quality images, testimonials, services, contact
                  information, your introduction, contents, your photo, your
                  social media accounts links, your projects, free samples, FAQ
                  part, guest appearances to make a perfect portfolio site. We
                  also arrange a free portfolio website. For portfolio website
                  design we analyze many portfolio website examples.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Design Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">
                    Idea Outline & template choosing
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Gather needed data</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">CTA preparing</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Design & Optimization</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you want to get a first-class Portfolio Site?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us.</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">We have best & flexible Teams</li>
                  <li className="page-six-li">
                    We save & reuse; so maintain a library.
                  </li>
                  <li className="page-six-li">
                    We have the best personal websites.
                  </li>
                  <li className="page-six-li">
                    We make headlines that will catch people's attention.
                  </li>
                  <li className="page-six-li">
                    Clear and Customized reporting
                  </li>
                  <li className="page-six-li">
                    We examine the concept from a variety of perspectives.
                  </li>
                  <li className="page-six-li">
                    Make strong appeals to action (CTAs)
                  </li>
                  <li className="page-six-li">Integrate cues of trust</li>
                  <li className="page-six-li">
                    Increase your conversion rates.
                  </li>
                  <li className="page-six-li">Make it mobile-friendly.</li>
                  <li className="page-six-li">
                    We are eager to keep in contact with you while working and
                    also positive about your opinion.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    {" "}
                    Aiming to achieve a specific objective
                  </li>
                  <li className="page-six-li">
                    Increasing your competitive advantage
                  </li>
                  <li className="page-six-li">
                    Meeting industry-specific needs
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Local & Global Mobile Application Development
                Service At The Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default PortfolioSite;
