import React from "react";
import WeTechLogo from "../assets/logos/weTechBlack.png";
import "../assets/css/header.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";

function Header() {
  const location = useLocation();

  const isPortfolioSection = location.pathname.search("/portfolio");
  const isPortfolioSubSection = location.pathname.search("/portfolio/");

  return (
    <div>
      {isPortfolioSection === 0 && isPortfolioSubSection === -1 ? (
        <div className="header-main-container">
          <div className="">
            <div className="text-center">
              <Link to="/">
                <img
                  className="header-logo wetechLogo"
                  src={WeTechLogo}
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      ) : isPortfolioSubSection === 0 ? (
        <div className="header-main-container">
          <div className="">
            <div className="">
              <div className="backIcon">
                <Link to="/portfolio">
                  <IoArrowBackCircle
                    style={{
                      fontSize: "40px",
                      color: "#0099DD",
                      cursor: "pointer",
                      zIndex: "3",
                    }}
                  />
                </Link>
              </div>
              <div className="portfolioLogo text-center">
                <Link to="/">
                  <img
                    className="header-logo wetechLogo"
                    src={WeTechLogo}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="header-main-container">
          <div className="header-sub">
            <div className="logo-section">
              <Link to="/">
                <img className="header-logo" src={WeTechLogo} alt="" />
              </Link>
            </div>
            <div className="sign-log-section space-x-3">
              {/* <Link to="/internship-program">
                <button className="button-sign-in !w-[10rem]">
                  Internship Program
                </button>
              </Link> */}
              <div className="sign-in-section">
                <Link to="/get-a-quote">
                  <button className="button-sign-in">Get a Quote</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
