import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "../../assets/css/home.css";
import "../../assets/css/quotes.css";

function GetAQuote() {
    const [quoteInfo, setQuoteInfo] = useState({});
    
    const handleChange = async (e) => {
        const {name, value} = e.target;
        setQuoteInfo((pre) => ({
            ...pre, [name]: value
        }))
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const dataInserted = await import("../../utils/services/form.service").then(
                async (service) => await service.postQuote({data: quoteInfo})
            );
            if(dataInserted?.status === 201 || dataInserted?.status === 200) {
                setQuoteInfo({})
                toast.success('Successfully submit your proposal.', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }
        } catch(err){
            console.log(err);
        }
    }



  return (
    <div>
    <div>
      <Helmet htmlAttributes>
        <title>
          {" "}
          Get a quote | WeTech
        </title>
        <meta
          name="description"
          content="get a quote | WeTech"
        ></meta>
        <meta
          name="keywords"
          content="contact us"
        ></meta>
      </Helmet>
    </div>
    <div className="services-main-container">
      <div className="services-sub">
        <div className="row services-page-one" style={{marginBottom: "3rem", marginTop: "2.8rem", color: "black"}}>
            <div className="col-md-12 p-md-4 p-2">
                <div className="p-5 border primary-bg">
                <form onSubmit={(e)=>handleSubmit(e)} style={{width: "100%"}}>
                    <h3 className="text-center font-weight-bold">Request a FREE Proposal Now!</h3>
                    <div className="mt-5">
                    <div className="row">
                        <div className="col">
                            <div className="form-group mt-2">
                                <label>Full Name<span className="text-danger">*</span></label>
                                <input type="text" className="quotesForm"
                                required
                                value={quoteInfo.name || ""}
                                name="name"
                                onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group mt-2">
                                <label for="formGroupExampleInput">Work Email Address <span className="text-danger">*</span></label>
                                <input type="text" className="quotesForm" id="formGroupExampleInput"
                                 name="email"
                                 value={quoteInfo.email || ""}
                                 required
                                 onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group mt-3">
                                <label for="formGroupExampleInput">Phone Number <span className="text-danger">*</span></label>
                                <input type="number" className="quotesForm" id="formGroupExampleInput"
                                required
                                name="phone"
                                value={quoteInfo.phone || ""}
                                onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group mt-3">
                                <label for="formGroupExampleInput">Company <span className="text-danger">*</span></label>
                                <input type="text" className="quotesForm" id="formGroupExampleInput"
                                required
                                name="company"
                                value={quoteInfo.company || ""}
                                onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mt-3">
                                <label for="formGroupExampleInput">Website <span className="text-danger">*</span></label>
                                <input type="text" className="quotesForm" id="formGroupExampleInput" 
                                required
                                name="website"
                                value={quoteInfo.website || ""}
                                onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label for="formGroupExampleInput">Comments or Questions</label>
                        <textarea
                        className="quotesForm"
                        rows="2"
                        cols="40"
                        type="text"
                        id="message"
                        name="message"
                        value={quoteInfo.message || ""}
                        onChange={(e)=> handleChange(e)}
                    />
                    </div>
                   
                    <button
                        className="btn btn-block my-4"
                        type="submit"
                        style={{background: "#ffd872"}}
                    >
                        Submit
                    </button>
                    </div>
                </form>
                </div>
            </div>
        </div>

      </div>
    </div>

  </div>
  );
}


export default GetAQuote;
