import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import "../../../assets/css/career.css";
import ReactHtmlParser from 'react-html-parser';

function JobDetails() {
  //const myRef = useRef(null);
  const { title } = useParams();
  // const id = JSON.parse(localStorage.getItem("CareerIdweT"));
  // console.log(id)
  const [jobDetails, setJobDetails] = useState({});
  // console.log(id);

  // const executeScroll = () =>
  //   myRef.current.scrollIntoView({
  //     behavior: "smooth",
  //     top: 600,
  //   });

  useEffect(() => {
    window.scrollTo(0,
      700,
    );
  }, [jobDetails]);


  const getAllCareerList = async () => {
    try {
      const dataFetched = await import("../../../utils/services/career.service").then(
        async (service) => await service.getCareerList({})
      );
      if (dataFetched) {
        if (dataFetched.data.length > 0) {
          const findJob = dataFetched.data.find(data => data.customUrl === title);
          console.log(findJob);
          setJobDetails(findJob);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllCareerList();
  }, [])


  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title> {`${jobDetails?.customTitle ? jobDetails?.customTitle : ""} | WeTech`}</title>
          <meta name="description" content="Career | WeTech"></meta>
          <meta name="keywords" content="Career"></meta>
        </Helmet>
      </div>

      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one" style={{ marginBottom: "4rem" }}>
            <div className="page-one-box">
              <div className="box-left px-4">
                <div className="box-one-title">
                  <h1 className="services-header-title">Career</h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    WeTech Digital is an all-in-one digital marketing services agency that is dedicated to developing your business's outstanding success with online advertising and marketing facilities.
                  </p>
                </div>
              </div>
              <div className="first-box-right px-4">
                <div className="right-image-section">
                  <img
                    className="image-file"
                    src="https://i.ibb.co/Q8D0VwS/268628588-310552550943818-4547320090692996864-n.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="row" id="jobDetails">
            <div className="col-md-12">
              <h2 className="text-muted mb-5">{jobDetails?.title}</h2>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-12" style={{ background: "#0099DD" }}>
              <div className="jobDetailsCard" >
                <div className="mb-5">
                  {
                    ReactHtmlParser(jobDetails?.description)
                  }
                </div>

                {/* <div className="my-4">
                      <p>
                    If this offer is interesting for you, please, send us your CV hr@wetechdigital.com <br />
                    and a motivation letter explaining how you can fit this position in our company. <br />
                    </p>
                  </div> */}

                {/* <Link to="/jobApply" className="text-light"> */}
                <button onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSctw7Z9UN55D7jTxPHSpHNSGwIwSPoGfLAALbIYLQvTjjMVSA/viewform")} className="career-button" >
                  <span>Apply</span>
                </button>
                {/* </Link> */}
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default JobDetails;
