import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import "../../assets/css/home.css";

import ContactWetech from "../blocks/ContactWetech";
import Testimonial from "../blocks/PortfolioReview";



function AboutUs() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title> About us | WeTech</title>
          <meta name="description" content="About us | WeTech"></meta>
          <meta name="keywords" content="about us"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">

          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left px-4">
                <div className="box-one-title">
                  <h1 className="services-header-title">About Us</h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph" >
                    WeTech Digital is an all-in-one digital marketing services
                    agency that is dedicated to developing your business's
                    outstanding success with online advertising and marketing
                    facilities.
                  </p>
                </div>
              </div>
              <div className="first-box-right px-4">
                <div className="right-image-section">
                  <img
                    className="image-file"
                    src="https://i.ibb.co/Q8D0VwS/268628588-310552550943818-4547320090692996864-n.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}



          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">About us</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Who We Are?</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  We are dedicated to building long-term and successful bonds
                  with clients. We understand the companies goals and the
                  challenges they are looking to overcome. By considering our
                  client's products and services as our own, we create the best
                  campaigns that deliver the most of the information. Our
                  process is efficient and our services are customer-driven.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img
                className="image-1"
                // src="https://i.ibb.co/Trhtx8b/mobile-phone-app-streaming-music-1.png"
                src="https://i.ibb.co/xXX8cfN/We-Tech-Digital-Who-We-Are.jpg
                "
                alt=""
              />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-4">
              <img
                className="image-1"
                // src="https://i.ibb.co/SQBnTj4/programmer-coding-software-development-working-project-it-1.png"
                src="https://i.ibb.co/Hq5CnPY/We-Tech-Digital-What-We-Do.jpg"
                alt=""
              />
            </div>

            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">About Us</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">What We Do?</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  Are you trying to grow your sales conversations or traffics?
                  We try to solve your problem & make the most out of it with
                  our expert support system to help you! Our company is a
                  combination of digital marketing service & SEO service! We
                  have a bunch of creative thinkers who knows how to deal with
                  your business issues and ensure growth! After all, we believe
                  in good STRATEGY with CREATIVITY can make GROWTH.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">About us</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Our Mission</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  We are working towards building a digital marketing solution
                  eco-system. We will assist you and put your goals to action so
                  that your business may flourish. We will give you the
                  opportunity to showcase your products and services in front of
                  thousands of market audiences!
                </p>
              </div>
            </div>
            <div className="services-page-two-right p-4">
              <img
                className="image-1"
                src="https://i.ibb.co/ydF8761/We-Tech-DIgital-Our-Mission.jpg"
                alt=""
              />
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right">
                {/* <img
                  className="image-file"
                  src="https://i.ibb.co/ydF8761/We-Tech-DIgital-Our-Mission.jpg
                  "
                  alt=""
                /> */}
              </div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
// "start": "react-scripts start",