import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import link from "../../../assets/images/amazon-adv-management.png";
import smile from "../../../assets/images/smileMan.png";
import seoService1 from "../../../assets/images/rsz-home6.png";
import seoService2 from "../../../assets/images/rsz-seoservice2.png";
import seoService3 from "../../../assets/images/rsz-home2.png";
import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import Testimonial from "../../blocks/Testimonial";
import { Link } from "react-router-dom";

// import image1 from "../../../assets/images/rsz-seoservice4.png";
// import image2 from "../../../assets/images/rsz-seoservice4.png";
// import image3 from "../../../assets/images/rsz-seoservice4.png";
// import image4 from "../../../assets/images/rsz-seoservice4.png";
// import image5 from "../../../assets/images/";

function AmazonAdsManagement() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Amazon advertising management: amazon marketing packages | WeTech
          </title>
          <meta
            name="description"
            content=" Make your Amazon store more profitable with Amazon advertising management services. Check out WeTech Digital affordable Amazon advertising packages.
"
          ></meta>
          <meta
            name="keywords"
            content="amazon advertising management
,Secondary Keyword
,amazon advertising agency
,amazon marketing agency
,best amazon marketing agency
,amazon marketing service price
,amazon marketing campaigns"
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">
                    Amazon Advertising Management
                  </p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    WeTech Digital's Amazon PPC management services may help you
                    advertise on Amazon. We'll help your company decrease its
                    Advertising Cost of Sale while boosting sales and market
                    share with our bespoke and data-driven tactics
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={link} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Amazon Advertising Management
                  Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the best Amazon marketing agency in the
                  field. Playing on Amazon needs more than just an aggressive
                  SEO approach; it also necessitates a competitive pay-per-click
                  (PPC) program. By requesting a proposal now, you can start
                  earning more money from Amazon advertising, such as Sponsored
                  Products, with our Amazon PPC services, which include frequent
                  reporting, a dedicated account manager, and proactive bid
                  management. Amazon marketing campaigns can give the prices the
                  hit. As an Amazon marketing agency, you can completely trust
                  us.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Amazon Advertising Management Service{" "}
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital is a popular choice for firms selling and
                  wanting to expand on Amazon, with over 650 marketing campaigns
                  executed and more than 20 years of expertise. Almost 10% of
                  Amazon clicks now go to sponsored advertisements, indicating
                  that if you're not promoting them, you're not making as much
                  money as you might on the world's largest retail platform. We
                  can assist you in maximizing your profits. WeTech Digital has
                  an expert Amazon advertising agency team who can help you out.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Product Analysis </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Develop strategy </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Product Analysis report </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Keywords analysis</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Product optimization </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Monitoring </p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={seoService3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require Amazon Advertising Management services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We offer the best Amazon advertising management service in
                    the field.
                  </li>
                  <li className="page-six-li">
                    We offer amazon marketing service prices cheaply.
                  </li>
                  <li className="page-six-li">
                    We have many brands and product ads that have sponsored this
                    event.
                  </li>
                  <li className="page-six-li">
                    Rates in stock are being monitored by our experts.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platforms.
                  </li>
                  <li className="page-six-li">
                    Provide unique ad postings give the.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService2} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService4} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    As internet marketing service providers, we take care of
                    your image in the market field.
                  </li>
                  <li className="page-six-li">
                    A professional's expertise in Amazon marketing.
                  </li>
                  <li className="page-six-li">
                    Monitoring Amazon's inventory planning.
                  </li>
                  <li className="page-six-li">
                    Reach to target clients with the related product on Amazon.
                  </li>
                  <li className="page-six-li">
                    Continuously improving product pages optimization. Our
                    Recent Projects
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Percel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Amazon Advertising Management Service At The Right
                Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}
export default AmazonAdsManagement;
