import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
import seoService2 from "../../../assets/images/rsz-seoservice2.png";
import seoService3 from "../../../assets/images/rsz-home2.png";
import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import shopifyAd from "../../../assets/images/shopifyAD.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/shopifyAdvertising/1.group-people-working-out-business-plan-office 1.webp";
import image2 from "../../../assets/images/shopifyAdvertising/2.black-businessman-happy-expression 1.webp";
import { Link } from "react-router-dom";

function ShopifyAdsManagement() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Shopify advertising agency: Shopify advertising costs & packages
          </title>
          <meta
            name="description"
            content=" Get Shopify advertising agency services at an affordable cost from WeTech. And increase your Shopify store revenue with high-quality traffic & sales."
          ></meta>
          <meta
            name="keywords"
            content="
          
          shopify advertising agency
,shopify advertising costs
,shopify marketing campaign
,best shopify advertising
,shopify advertising campaign
,shopify advertising management


          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    Shopify Advertising Agency
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want Shopify advertising management services? Then we
                    are here to please you by providing that professionally. Our
                    Shopify advertising agency is famous in the market.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={shopifyAd} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Shopify Advertising
                  ManagementCompany?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top Shopify advertising agency in the
                  market. Shopify is one of the most popular ecommerce platforms
                  for a reason. It's easy to use while being flexible and it
                  includes a lot of useful features. With over 1.7 million
                  merchants using the platform, Shopify is a popular choice for
                  businesses in a range of industries. Shopify is designed to be
                  easy to use. Because it's a hosted platform, getting started
                  is a lot easier. It also has a user-friendly interface and a
                  choice of styles, making setting up a basic ecommerce site a
                  breeze. We provide best Shopify advertising. Our Shopify
                  Advertising management service is well known. We know that
                  there are many agencies but you can believe us as the best
                  Shopify advertising agency. For our focused management with
                  Shopify, we are introducing ourselves as the best Shopify
                  advertising management agency.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Shopify Advertising Management Services
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital is dedicated to providing clients with
                  interesting, relevant, and effective content as we are a
                  famous and reliable Shopify advertising agency. Advertising
                  management is a planned administrative process for overseeing
                  and controlling the different advertising activities included
                  in a programme to connect with a company's target market, with
                  the goal of influencing consumer purchase choices. Working on
                  that purpose, our Shopify advertising management services are
                  most reliable with affordable Shopify advertising costs.
                  Shopify advertising costs are normally high but we try to
                  decrease it as much as we can. As a Shopify store development
                  services company, we offer a wide range of Shopify advertising
                  management. We also arrange a Shopify advertising campaign for
                  you. Shopify advertising campaign is included with Shopify
                  marketing campaign. Our Shopify marketing campaign will help
                  you to spread your name and details. It is very effective to
                  participate in this Shopify marketing campaign. All of our
                  services lead you to get the best Shopify advertising.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Company background analysis</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Market Research</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Materials for Promoting</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Media Advertising</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Shopify Marketing Campaign</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={seoService3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do You Require Shopify Advertise Management Services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Assist you in comprehending target industry.
                  </li>
                  <li className="page-six-li">
                    We provide work that is more efficient.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platform.
                  </li>
                  <li className="page-six-li"> Vast promotional campaign.</li>
                  <li className="page-six-li">
                    Provide best Shopify advertising.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService2} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService4} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We examine the sales strategies and outcomes of your rivals.
                  </li>
                  <li className="page-six-li">
                    We provide adaptable and personalized services
                  </li>
                  <li className="page-six-li">
                    Designing custom content with high technology.
                  </li>
                  <li className="page-six-li">Well prepared media planning.</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Percel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Shopify Advertising Agency Service At The Right
                Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default ShopifyAdsManagement;
