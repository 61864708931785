import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import '../../../assets/css/services.css'
// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
//import MainContent from "../../../assets/images/crm-roi.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/crmRoi/1.PngItem_742005 1.png";
import image2 from "../../../assets/images/crmRoi/2.manager-leading-brainstorming-meeting 1 (1).png";
import image3 from "../../../assets/images/crmRoi/3.pexels-andrea-piacquadio-3760069 1.png";
import image4 from "../../../assets/images/crmRoi/4.workers-pointing-increase-sales 1.png";
import image5 from "../../../assets/images/crmRoi/5.portrait-of-a-man-looking-aside-with-expression-of-2021-12-28-06-27-57-utc 1.png";
import image6 from "../../../assets/images/crmRoi/6.businesspeople-celebrating-success 1 (1).png";
import { Link } from "react-router-dom";

function CRMROI() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            {" "}
            CRM ROI: Improve customer relationship management | WeTech
          </title>
          <meta
            name="description"
            content="Improve your customer relationship management with advanced CRM system services. Grow and optimized return on investment (ROI) with WeTech Digital.
"
          ></meta>
          <meta
            name="keywords"
            content="
          

          crm roi
,roi service
,return on investment company
,return on investment optimized service provider



          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">CRM ROI </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Choose to make much use of your sales and marketing data to
                    boost your small or midsized business's profitability? Raise
                    a toast to CRM ROI, a sales management system designed for
                    small sales teams with big ambitions.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top CRM ROI Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph" style={{ textAlign: 'justify' }}>
                  WeTech Digital provides the best CRM ROI services in today's
                  market. CRM refers to Customer relationship management and ROI
                  refers to Return on investment. CRM ROI is an economical
                  option for helping your team closes more sales if you don't
                  already have one. CRM ROI integrates unique product
                  information with best practices in lead management to make it
                  simpler to connect sales and marketing data. As a return on
                  Investment Company, we provide the best service to please you.
                  That's why our ROI service is very famous in the market.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to CRM ROI</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph" style={{ textAlign: 'justify' }}>
                  "Half the money I spend on advertising is squandered; the
                  issue is, I don't know which half," remarked marketing
                  pioneerJohn Wanamaker. It's difficult to analyze the outcomes
                  of your marketing activities without a CRM. On the other hand,
                  CRM ROI may assist you in tracking ROI and allocating funding
                  to the channels that generate the most sales and income.
                  WeTech Digital is known as a return on investment optimized
                  service provider. Our service is the best on this platform
                  that you can rely on.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Design Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Tracking Lead Sources</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Website Visits</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Info Import for Sales Call Tracking{" "}
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Dashboard for Lead Action and Follow-up
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Tools for lead scoring and qualification
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Routing of leads by email and SMS
                  </p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you need the best CRM ROI services?
                    <span
                      style={{ color: "#FFD872", fontFamily: "lora, serif" }}
                    ></span>
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-two-ol">
                  <li className="page-six-li">
                    We offer simple pipeline administration
                  </li>
                  <li className="page-six-li">
                    We take care of everything and from interactivity to buy
                  </li>
                  <li className="page-six-li">
                    Keep track of your sales funnel.
                  </li>
                  <li className="page-six-li">
                    We always take a good look at client's reviews.
                  </li>
                  <li className="page-six-li">
                    We review the idea in many aspects.
                  </li>
                  <li className="page-six-li">
                    Increase the number of transactions you close.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We keep track of your marketing budget's return on
                    investment in real-time.
                  </li>
                  <li className="page-six-li">
                    We follow your leads throughout their entire lifespan,
                    beginning with their initial visit to your website or brand.
                  </li>
                  <li className="page-six-li">
                    We provide lead management that runs smoothly
                  </li>
                  <li className="page-six-li">
                    Maximize your competitive edge.
                  </li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end CRMROI Service At The Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default CRMROI;
