import React, { createContext, useState, useEffect } from "react";

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState(() => {
    const storedState = localStorage.getItem("globalState");
    return storedState
      ? JSON.parse(storedState)
      : {
          activeRoute: "",
          // Add more properties to the global state as needed
        };
  });

  useEffect(() => {
    localStorage.setItem("globalState", JSON.stringify(globalState));
  }, [globalState]);

  const contextValue = {
    globalState,
    setGlobalState,
  };

  return (
    <GlobalStateContext.Provider value={contextValue}>
      {children}
    </GlobalStateContext.Provider>
  );
};
