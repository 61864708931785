import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import "../../../assets/css/services.css";
// photos
import smile from "../../../assets/images/smileMan.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import facebook from "../../../assets/images/facebook.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/facebookMarketPlace/1.data-analysis-marketing-business-report-concept 1.png";
import image2 from "../../../assets/images/facebookMarketPlace/2.handshake-businessmen 1.png";
import image3 from "../../../assets/images/facebookMarketPlace/3.businessman-drawing-red-line 1.png";
import image4 from "../../../assets/images/facebookMarketPlace/4.confident-businesspeople-standing-outside-office-building 1.png";
import image5 from "../../../assets/images/facebookMarketPlace/5.png";
import { Link } from "react-router-dom";

function FacebookMarketPlaceForBusiness() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Facebook marketplace services: grow your business on facebook
          </title>
          <meta
            name="description"
            content="Using Facebook marketplace services can grow your business on Facebook more effectively. WeTech Digital will help you to grow your business more easily."
          ></meta>
          <meta
            name="keywords"
            content="
          
          
facebook marketplace services
,facebook marketplace for business
,facebook marketplace for business pages
,facebook marketplace services agency



          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    Facebook Marketplace for Business
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Want to sell more items by diversifying your online sales
                    platforms? WeTech Digital can assist you in generating
                    income on the Facebook Marketplace for Business, which
                    provides you with access to over 800 million shoppers
                    worldwide.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span> </span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={facebook} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Facebook Marketplace for Business
                  Service Company?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top Facebook marketplace services agency
                  in the market. Facebook Marketplace, which is used monthly by
                  over 800 million people in 70 countries, allows users to buy
                  and sell goods such as furniture, clothes, and even vehicles.
                  Facebook has begun to roll out Marketplace for Business, which
                  is similar to how individual vendors utilize the site.
                  Facebook's Marketplace for Business is a fantastic option for
                  firms wanting to increase product sales online. Facebook
                  marketplace services help you to advertise your business.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Facebook Marketplace for Business
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  The Facebook Marketplace is a platform where individuals can
                  find, buy, and sell things. People may look through ads,
                  search for goods for sale in their region, and look for
                  products that can be delivered. Facebook marketplace for
                  business pages is recently very popular as most people are
                  active in this social platform. Facebook has launched
                  Marketplace, a platform for buying and selling things in your
                  local area. Individual vendors have typically utilized
                  Facebook Marketplace. However, Facebook just launched
                  Marketplace for Business, a new service aimed at assisting
                  businesses in increasing their online sales.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Market clients analysis </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Develop strategy </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Product listing </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Brand awareness </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Product optimization </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Easy interaction </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">SEO Service</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require Facebook Marketplace for business services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Even if you don't post directly on Marketplace, we make sure
                    to advertise your shop or products there.
                  </li>
                  <li className="page-six-li">
                    Our retail products, house rents, automobiles, and event
                    tickets are all on exhibition.
                  </li>
                  <li className="page-six-li">
                    We provide affordable services cost.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platforms.
                  </li>
                  <li className="page-six-li">
                    Provide social media postings that are unique.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image4} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image5} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We let consumers buy without having to leave Facebook.
                  </li>
                  <li className="page-six-li">
                    Freely advertise new or reconditioned products from your
                    Facebook Page store on Marketplace.
                  </li>
                  <li className="page-six-li">
                    As a Facebook marketplace for business service providers, we
                    take care of your image in the market field.
                  </li>
                  <li className="page-six-li">
                    Designing custom content with high technology.
                  </li>
                  <li className="page-six-li">
                    We offer social media network establishment, optimization,
                    and identity auditing services. Our Recent Projects
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Facebook Marketplace for Business Service At The
                Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default FacebookMarketPlaceForBusiness;
