import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import data from "./pricing/SeoAudit";
import jam from "../../../assets/images/jamCheck.png";
// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
//import seoAUDIT from "../../../assets/images/seoaudit.png";
import Testimonial from "../../blocks/Testimonial";

import seoAudit1 from "../../../assets/images/seoAudit/seo-audit-1.webp";
import seoAudit2 from "../../../assets/images/seoAudit/seo-audit-2.webp";
import seoAudit3 from "../../../assets/images/seoAudit/seo-audit-3.webp";
import seoAudit4 from "../../../assets/images/seoAudit/seo-audit-4.webp";
import seoAudit5 from "../../../assets/images/seoAudit/seo-audit-5.webp";
import seoAudit6 from "../../../assets/images/seoAudit/seo-audit-6.webp";
import { Link } from "react-router-dom";

function SEOAudit() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>WeTech - SEO Audit</title>
          <meta name="description" content="Basic content"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">SEO Audit</p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    We assist you in identifying your core SEO concerns and
                    developing a long-term approach to manage to beat your
                    competitors.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span>
                    <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={seoAudit1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top SEO Audit Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital listens to your strategic goals, learns about
                  your marketing strategy, marketplace, and competitive
                  environment, and then uses that information to build a
                  customized plan that takes you where you need to go and
                  beyond. One of the most important considerations when
                  selecting an SEO organization is how they assess campaign
                  success. While some SEO firms use the same standardized
                  reports all over every account, WeTech Digital creates
                  personalized reports based on the KPIs that are most relevant
                  to your company.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoAudit2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={seoAudit3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to SEO Service</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  We specify the keywords with both the highest search volume
                  and verify that your website ranks for all those aspects. We
                  also analyze search queries to identify specific information
                  people have about the products and services you provide. We
                  will drive more traffic to websites by optimizing these search
                  queries and ensuring your content contains the information
                  your audience is looking for.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Keyword research</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Competitor Analysis</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">On-page-optimization</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Off-page-optimization</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Detailed Analytics reporting</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={seoAudit4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Want to achieve Proven
                    <br />
                    and affordable SEO Audit{" "}
                    <span
                      style={{ color: "#FFD872", fontFamily: "lora, serif" }}
                    >
                      to grow Your Business?
                    </span>
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us work together to make it happen
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We have global SEO specialists (Included international SEO
                    capabilities)
                  </li>
                  <li className="page-six-li">
                    We seem more than the best SEO services provider; also we
                    can able to handle all types of client’s needs
                  </li>
                  <li className="page-six-li">
                    We use robust techniques to grow any business with an online
                    presence
                  </li>
                  <li className="page-six-li">
                    Clear and Customized reporting
                  </li>
                  <li className="page-six-li">
                    We provide professional SEO services for small to large
                    businesses.
                  </li>
                  <li className="page-six-li">
                    Your Competitive SEO audit is carried out by skilled SEO
                    strategists.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoAudit5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={seoAudit6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">Result-oriented outcomes</li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements
                  </li>
                  <li className="page-six-li">
                    We plan, execute, and refine strategy through our experts.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Local & Global SEO Audit Service At The Right
                Price
              </p>
            </div>
            <div className="pricing-main">
              <div className="pricing-table-section">
                <div className="table-header">
                  <div className="feature-section">
                    <h2 className="table-title">Features</h2>
                  </div>
                  <div className="silver-section">
                    <h2 className="table-title">Silver</h2>
                  </div>
                  <div className="gold-section">
                    <h2 className="table-title">Gold</h2>
                  </div>
                </div>
                <div className="table-content1">
                  <div className="feature">
                    <p></p>
                  </div>

                  <div className="silver">
                    <p>$1,200</p>
                  </div>
                  <div className="gold">
                    <p>$1,800</p>
                  </div>
                </div>
                <div className="table-content1">
                  <div className="feature">
                    <p></p>
                  </div>

                  <div className="silver">
                    <p>30 Day Duration</p>
                  </div>
                  <div className="gold">
                    <p>45 Day Duration</p>
                  </div>
                </div>

                {data.map((data) => (
                  <div className="table-content1">
                    <div className="feature">
                      <p>{data.name}</p>
                    </div>

                    <div className="silver">
                      {data.silver === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.silver}</p>
                      )}
                    </div>
                    <div className="gold">
                      {data.gold === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.gold}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default SEOAudit;
