import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import data from "./data/SeoCopywrite";
import jam from "../../../assets/images/jamCheck.png";

// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
//import MainContent from "../../../assets/images/website-copyright.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/seoCopywriting/1.pngegg (3) 1.png";
import image2 from "../../../assets/images/seoCopywriting/2.manager-leading-brainstorming-meeting 1.png";
import image3 from "../../../assets/images/seoCopywriting/3.business-man-holding-paper-with-why-choose-us-question_23-2148932314 1.png";
import image4 from "../../../assets/images/seoCopywriting/4.workers-pointing-increase-sales 2.png";
import image5 from "../../../assets/images/seoCopywriting/5.man-thinking-2021-09-24-03-40-11-utc 1.png";
import image6 from "../../../assets/images/seoCopywriting/6.businesspeople-celebrating-success 2.png";
import { Link } from "react-router-dom";

function WebsiteSEOCopywriting() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            SEO Copywriting Services: Package & Pricing | WeTech Digital
          </title>
          <meta
            name="description"
            content="Search engine optimized content is one of the best ways to increase the number of visitors to your website. WeTech Digital is offering SEO copywriting services at an affordable price."
          ></meta>
          <meta
            name="keywords"
            content="
          
seo copywriting services
,seo content writing services
,writing content for website
,seo copywriting service
,seo copywriting agency
,seo copywriting company
,seo optimized content writing
,seo copywriting pricing


          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">
                    Website SEO Copywriting
                  </p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    WeTech Digital is a top SEO copywriting agency that helps
                    you achieve online and offline accomplishment by generating
                    original, appealing copy for anything from blog posts to
                    sales material to online tutorials. Our website SEO
                    copywriting services can help your business reach its
                    objectives, whether it's to improve engagement, online
                    traffic, or sales. Check out our service and price options
                    underneath to understand more about our industry-leading
                    online copywriting services.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Website SEO Copywrite Company?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  Our WeTech Digital is renowned for its SEO copywriting
                  services. One of the most important aspects of your website is
                  the copy. It links your firm with your potential customers and
                  convinces them to buy your goods, contact you, or even come
                  into your store. Invest in expert website copywriting services
                  for appealing material that resonates with readers and ranks
                  at the top of search results. The excellent reputation of
                  WeTech Digital is there for SEO content writing services
                  worldwide. To generate accurate and appealing text, our
                  copywriting team includes specialists from various
                  backgrounds, including technical and non-technical. WeTech
                  Digital is the lead website SEO Copywriting Company, which
                  also can write content for websites. So you can get the most
                  out of your marketing dollars quickly with our help. We've put
                  together a team of experts to assist this in managing,
                  tracking, following up with, and growing valuable leads – and
                  eventually income.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Website SEO Copywrite Service
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  You may have heard the phrase "SEO content" bandied about in
                  marketing meetings if you're new to the realm of search
                  marketing. For SEO-optimized content writing, we follow many
                  strategies. We don’t only focus on search engine traffic
                  because of bad results. We provide value above and beyond
                  search engine optimization to delight search engines and
                  potential consumers and repeat visits. To put it another way,
                  we don't create "thin" content that ranks and gets clicks but
                  doesn't give value to the search engine user. We ensure that
                  SEO Copywrite pricing can be affordable, and we choose the
                  right one for our client. For every type of SEO content,
                  WeTech Digital works hard to make sure an apparent success.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Define Goals</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Analysis audiences</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Prepare a calendar</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Development</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you want to get a first-class Website SEO Services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us work with you.
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We optimize your content for readers and search engine.
                  </li>
                  <li className="page-six-li">
                    We provide service packages for clients’ websites.
                  </li>
                  <li className="page-six-li">
                    We always keep the focus on Robust security
                  </li>
                  <li className="page-six-li">
                    Very user-friendly, customizable, sales focused & technical
                    SEO website Copywrite services.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Provide distinct types of SEO copywriting for your website.
                  </li>
                  <li className="page-six-li">
                    Also working on E-commerce copywriting
                  </li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements.
                  </li>
                  <li className="page-six-li">
                    Our SEO copywriting price is very affordable.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Website SEO Copywriting Service At The Right Price
              </p>
            </div>
            <div className="pricing-main">
              <div className="pricing-table-section">
                <div className="table-header">
                  <div className="feature-section">
                    <h2 className="table-title">Features</h2>
                  </div>
                  <div className="silver-section">
                    <h2 className="table-title">Silver</h2>
                  </div>
                  <div className="gold-section">
                    <h2 className="table-title">Gold</h2>
                  </div>
                </div>

                {data.map((data) => (
                  <div className="table-content1">
                    <div className="feature">
                      <p>{data.name}</p>
                    </div>

                    <div className="silver">
                      {data.silver === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.silver}</p>
                      )}
                    </div>
                    <div className="gold">
                      {data.gold === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.gold}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default WebsiteSEOCopywriting;
