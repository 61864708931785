import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
//import MainContent from "../../../assets/images/info-and-motion-graphics.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/infographiceAndMotion/1.Daco_6151126 1.png";
import image2 from "../../../assets/images/infographiceAndMotion/2.retoucher-woman-editor-working-computer-with-two-monitors-stylus 1.png";
import image3 from "../../../assets/images/infographiceAndMotion/3.business-people-shaking-hands-together 1.png";
import image4 from "../../../assets/images/infographiceAndMotion/4.businessman-with-light-bulb-his-hand 1.png";
import image5 from "../../../assets/images/infographiceAndMotion/5.beautiful-thinking-blonde-business-woman-isolated-white-wall 1.png";
import image6 from "../../../assets/images/infographiceAndMotion/6.cheerful-positive-young-european-woman-with-dark-hair-broad-shining-smile-points-with-thumb-aside 1.png";
import { Link } from "react-router-dom";

function InfoAndMotionGraphics() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Creative 3D motion graphics: infographic design agency | WeTech
          </title>
          <meta
            name="description"
            content="Get creative 2D or 3D Motion Graphics & Infographic design to deliver your message to your audiences. And represent your brand and services more uniquely.
"
          ></meta>
          <meta
            name="keywords"
            content="
          
motion graphics company
,motion graphics services
,3d motion graphics services
,motion graphics agency
,motion graphics production
,infographic design agency
,infographic companies


          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    Infographics and Motion Graphics
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Infographics are a great way to deliver material to your
                    audience in a fun, interactive manner, and they're also far
                    more likely to go viral and get a lot of attention than
                    other forms of content. Our principal aim is to give you the
                    best infographics and motion graphics.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>

              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top InfoGraphics and Motion Graphics
                  Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital understands what it takes to create the ideal
                  infographics for your company and industry: Our bespoke
                  infographic pricing is completely transparent, so you always
                  know where your money is going. As a motion graphics company,
                  we take care of your needs. Our motion graphics services are
                  well known in the market. 3D motion graphics services are
                  nowadays very popular among everybody because of its
                  eye-catching sight. Infographics come in a variety of shapes
                  and sizes, and they may express a variety of ideas. Wetech
                  Digital specializes in the bespoke image, motion/video, and
                  micro infographics as a leading Infographic design business.
                  There are many Infographic companies out there but you have to
                  find the perfect one. You can rely on our motion graphics
                  production.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to InfoGraphics and Motion Graphics
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  We develop content that has the potential to go viral,
                  increasing your website's total reach and brand exposure.
                  Infographic design agency as well as motion graphics agency,
                  WeTech Digital have experts in data visualization that create
                  compelling information visuals that explain and engage
                  visitors on a certain issue in different media. They help
                  people engage emotionally with your brand and establish your
                  firm as an industry leader with a depth of expertise on a
                  certain subject. Our digital advertising and public relations
                  team creates a plan that reflects your brand and industry
                  while also being likely to be shared on popular websites.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Case study</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Research user view</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Make a process</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Multiple rounds edit</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Image attribution license & tracking
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Source file delivery</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Composing a blog entry</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you already have a website that you need to add
                    InfoGraphics and Motion Graphics?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Throughout the visual information creation process, our
                    Infographic designers keep this in mind.
                  </li>
                  <li className="page-six-li">
                    We pay close attention to customer feedback at all times.
                  </li>
                  <li className="page-six-li">
                    We examine your matter with care.
                  </li>
                  <li className="page-six-li">
                    We create visuals that are both entertaining and
                    informative.
                  </li>
                  <li className="page-six-li">
                    We are eager to keep in contact with you while working and
                    also optimistic about your opinion.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Optimize your user Experience as a unique user-friendly
                    website.
                  </li>
                  <li className="page-six-li">
                    Our data visualization graphics are easy to understand and
                    captivating.
                  </li>
                  <li className="page-six-li">
                    We recommend the best fit for you depending on your
                    marketing objectives.
                  </li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements.
                  </li>
                  <li className="page-six-li">
                    Help to grow local & international business.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Infographics and Motion Graphics Service At The
                Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-main">
                <div className="pricing-table-section">
                  <div className="table-header">
                    <div className="feature-section">
                      <h2 className="table-title">Features</h2>
                    </div>
                    <div className="silver-section">
                      <h2 className="table-title">Silver</h2>
                    </div>
                    <div className="gold-section">
                      <h2 className="table-title">Gold</h2>
                    </div>
                    <div className="platinum-section">
                      <h2 className="table-title">Platinum</h2>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p></p>
                    </div>
                    <div className="silver">
                      <p></p>
                    </div>
                    <div className="gold">
                      <p></p>
                    </div>
                    <div className="platinum">
                      <p></p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p></p>
                    </div>
                    <div className="silver">
                      <p></p>
                    </div>
                    <div className="gold">
                      <p></p>
                    </div>
                    <div className="platinum">
                      <p></p>
                    </div>
                  </div>
                  <div className="table-content1">
                    <div className="feature">
                      <p>Infographic strategy development</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Industry Research</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Topic research</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Data Analysis</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Infographic wireframe development</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Initial infographic prototype</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Infographic design revisions</p>
                    </div>
                    <div className="silver">
                      <p>1 rounds of edits</p>
                    </div>
                    <div className="gold">
                      <p>2 rounds of edits</p>
                    </div>
                    <div className="platinum">
                      <p>3 rounds of edits</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>
                        Up to 40 hours of development time creating
                        interactivity (includes edits)
                      </p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>iFrame Embed Code Creation</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Final infographic </p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Delivery of source files</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Setup of sharing buttons on web page</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Dedicated Project Manager</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>

                  <div className="table-content">
                    <div className="feature">
                      <p>Infographic "Best Practices Guide"</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Social Media Promotion Budget</p>
                    </div>
                    <div className="silver">
                      <p>{"$100"}</p>
                    </div>
                    <div className="gold">
                      <p>{"$150"}</p>
                    </div>
                    <div className="platinum">
                      <p>{"$150"}</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Blog post writing</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Social media marketing</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Social Shares</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Outreach to influencers</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Final traffic campaign report</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Final social interaction campaign report</p>
                    </div>
                    <div className="silver">
                      <p>/</p>
                    </div>
                    <div className="gold">
                      <p>/</p>
                    </div>
                    <div className="platinum">
                      <p>/</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Investment</p>
                    </div>
                    <div className="silver">
                      <p>{"$1200"}</p>
                    </div>
                    <div className="gold">
                      <p>{"$3000"}</p>
                    </div>
                    <div className="platinum">
                      <p>{"$5000"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default InfoAndMotionGraphics;
