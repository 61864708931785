import React, { useState } from "react";
import { toast } from "react-toastify";

function ContactWetech() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    // subject: "",
    message: "",
  });

  const { name, email, phone, message } = formData;

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newUser = {
      name,
      email,
      phone,
      message,
    };
    try {
      const dataInserted = await import("../../utils/services/form.service").then(
        async (service) => await service.postContactForm({ data: newUser })
      );
      if (dataInserted?.status === 201 || dataInserted?.status === 200) {
        toast.success('Successfully sent.', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      console.error(err.response.data);
    }
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  return (
    <div className="services-page-eleven">
      <div className="page-eleven-left">
        <p className="eleven-left-title1">
          Contact WeTech
          <span
            style={{
              borderTop: "1px solid black",
              width: "60px",
              marginLeft: "5px",
              marginTop: "12px",
            }}
          ></span>
        </p>
        <p className="eleven-left-title2">Need More Details?</p>
        <p className="eleven-left-title3">
          Contact our User Experience Expert and share your thoughts.
        </p>
        <img
          className="image-file"
          src="https://i.ibb.co/dWMdJSB/We-Tech-Digital-Need-More-Details.jpg
          "
          alt=""
        />
      </div>
      <div className="page-eleven-right">
        <div className="services-contact-form-section">
          <form action="" className="services-contact-form">
            <p className="services-contact-title">For Free Consultancy</p>
            <div className="services-contact-input-section">
              <input
                className="services-contact-input"
                type="text"
                placeholder="Full Name"
                name="name"
                id="name"
                value={name}
                onChange={handleChange}
              />
              <input
                className="services-contact-input"
                type="text"
                placeholder="Email Address"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
              />
              <input
                className="services-contact-input"
                type="number"
                placeholder="Phone Number"
                id="phone"
                name="phone"
                value={phone}
                onChange={handleChange}
              />
              {/* <input
                className="services-contact-input"
                type="text"
                placeholder="Website link"
                id="website"
                name="web"
                value=""
                onChange={handleChange}
              /> */}
              <textarea
                className="services-contact-msg-input"
                rows="2"
                cols="40"
                type="text"
                placeholder="Your Message"
                id="message"
                name="message"
                value={message}
                onChange={handleChange}
              />

              <button
                className="services-contact-button"
                type="submit"
                onClick={handleSubmit}
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    // <div className="services-page-eleven">
    //   <div className="page-eleven-left">
    //     <p className="eleven-left-title1">
    //       Contact WeTech
    //       <span
    //         style={{
    //           borderTop: "1px solid black",
    //           width: "60px",
    //           marginLeft: "5px",
    //           marginTop: "12px",
    //         }}
    //       ></span>
    //     </p>
    //     <p className="eleven-left-title2">Need More Details?</p>
    //     <p className="eleven-left-title3">
    //       Contact our User Experience Expert and share your thoughts.
    //     </p>
    //   </div>
    //   <div className="page-eleven-right">
    //     <div className="services-contact-form-section">
    //       <form action="" className="services-contact-form">
    //         <p className="services-contact-title">For Free Consultancy</p>
    //         <div className="services-contact-input-section">
    //           <input
    //             className="services-contact-input"
    //             type="text"
    //             placeholder="Full Name"
    //             name="name"
    //             id="name"
    //             onChange={handleChange}
    //           />
    //           <input
    //             className="services-contact-input"
    //             type="text"
    //             placeholder="Email Address"
    //             id="email"
    //             name="email"
    //             onChange={handleChange}
    //           />
    //           <textarea
    //             className="services-contact-msg-input"
    //             rows="2"
    //             cols="40"
    //             type="text"
    //             placeholder="Your Message"
    //             id="message"
    //             name="message"
    //             onChange={handleChange}
    //           />

    //           <button
    //             className="services-contact-button"
    //             type="submit"
    //             onClick={handleSubmit}
    //           >
    //             Send Message
    //           </button>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    // </div>
  );
}

export default ContactWetech;
