import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BANNER from "../../assets/images/internship/banner4.jpg";
import { SiExpensify } from "react-icons/si";
import { GrMail } from "react-icons/gr";
import { FaSearchLocation } from "react-icons/fa";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import logo from "../../assets/logos/weTechBlack.png";
import about from "../../assets/images/internship/about.png";
import who from "../../assets/images/internship/who-apply.png";
import open from "../../assets/images/internship/open-position.png";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";
import HandsOnExperience from "../../assets/images/internship/Hands-On-Experience.png";
import ExpertMentorship from "../../assets/images/internship/Expert-Mentorship.png";
import SkillDevelopment from "../../assets/images/internship/Skill-Development.png";
import NetworkingOpportunities from "../../assets/images/internship/Networking-Opportunities.png";
import MotionGraphicsDesigner from "../../assets/images/internship/Motion-Graphics-Designer.png";
import ContentWriter from "../../assets/images/internship/Content-Writer.png";
import SEOSpecialist from "../../assets/images/internship/SEO-Specialist.png";
import DigitalMarketingExecutive from "../../assets/images/internship/Digital-Marketing-Executive.png";
import CorporateSales from "../../assets/images/internship/Corporate-Sales-&-Communication-Manager.png";

import JobDescription from "./job-description";
import { GlobalStateContext } from "../../../store/store";

const jobDescriptionData = [
  {
    title: "Motion Graphics Designer",
    icon: MotionGraphicsDesigner,
    description:
      "If you have a passion for creating visually captivating motion graphics and animations, this internship is for you. Join our team and collaborate with us to develop engaging visual content for both local and international projects.",
    toDo: [
      "Create visually appealing motion graphics and animations.",
      "Collaborate with the team to develop engaging and dynamic visual content.",
      "Proficient in motion graphics software and techniques.",
      "Portfolio showcasing previous motion graphics work is required.",
    ],
    qualities: [
      "Basic knowledge of motion graphics software such as Adobe After Effects, Cinema 4D, or similar tools.",
      "Strong interest and passion for motion graphics design.",
      "Ability to work collaboratively in a team and meet project deadlines.",
    ],
  },
  {
    title: "Content Writer",
    icon: ContentWriter,
    description:
      "Are you a skilled wordsmith with a flair for crafting compelling content? Join us as a Content Writer Intern and unleash your creativity to create engaging and informative content for various platforms, honing your writing skills and gaining valuable experience in digital content creation.",
    toDo: [
      "Create compelling and engaging content for various platforms.",
      "Conduct research and generate ideas for content topics.",
      "Excellent writing skills and attention to detail.",
      "Familiarity with digital content creation and SEO best practices.",
    ],
    qualities: [
      "Excellent writing skills with a good command of grammar and vocabulary.",
      "Basic understanding of digital content creation and writing for online platforms.",
      "Strong research skills to gather information on various topics.",
    ],
  },
  {
    title: "SEO Specialist",
    icon: SEOSpecialist,
    description:
      "Dive into the world of search engine optimization as an SEO Specialist Intern. Work with our team to develop and implement effective strategies to improve organic search rankings, conduct keyword research, and stay updated with the latest SEO trends and techniques.",
    toDo: [
      "Develop and implement SEO strategies to improve organic search rankings.",
      "Conduct keyword research and optimize website content.",
      "Monitor website performance and make data-driven recommendations.",
      "Stay updated with the latest SEO trends and algorithm changes.",
    ],
    qualities: [
      "Basic knowledge of search engine optimization principles and techniques.",
      "Familiarity with keyword research and analysis tools.",
      "Analytical mindset with the ability to interpret website analytics data.",
    ],
  },
  {
    title: "Digital Marketing Executive",
    icon: DigitalMarketingExecutive,
    description:
      "Explore the dynamic field of digital marketing and become a Digital Marketing Executive Intern. Assist in planning and executing digital marketing campaigns, managing social media accounts, and analyzing campaign performance to enhance our online presence and engage our target audience.",
    toDo: [
      "Plan and execute digital marketing campaigns across various channels.",
      "Manage social media accounts and create engaging content.",
      "Monitor campaign performance and analyze metrics.",
      "Familiarity with digital marketing tools and platforms.",
    ],
    qualities: [
      "Basic understanding of digital marketing channels such as social media, email marketing, and paid advertising.",
      "Familiarity with digital marketing tools and platforms, such as Google Ads, Facebook Ads Manager, or similar tools.",
      "Creative mindset to assist in creating content for digital campaigns.",
    ],
  },
  {
    title: "Corporate Sales & Communication",
    icon: CorporateSales,
    description:
      "If you have excellent communication skills and a passion for sales, this internship is an excellent opportunity for you. As a Communication & Sales Manager Intern, you will assist in developing and executing sales strategies, identifying potential clients, building strong relationships, and contributing to our business growth.",
    toDo: [
      {
        title: "Motion Graphics (International & Local: 2 positions)",
        description: [
          "Conducting sales calls and engaging with potential clients.",
          "Building and maintaining client relationships through effective communication.",
          "Assisting in sales presentations and materials preparation.",
          "Supporting the sales team in achieving targets.",
        ],
      },
      {
        title: "Digital Marketing (International & Local: 2 positions)",
        description: [
          "Collaborate with the sales team to create targeted messaging.",
          "Support digital marketing campaigns to generate leads and drive sales.",
          "Make outbound calls to prospects and follow up on leads.",
          "Manage social media presence to engage prospects and drive sales.",
          "",
        ],
      },
      {
        title: "Software Products (2 positions)",
        description: [
          "Each position will have four (04) software products.",
          "Conducting market research.",
          "Making sales calls and engaging with clients.",
          "Collaborating with the sales team.",
        ],
      },
      {
        title: "Business Content Marketer (1 position)",
        description: [
          "Promoting and selling business content for a blog site",
          "Developing effective communication strategies to attract clients.",
          "Collaborating with the marketing team to create engaging content.",
          "Making sales calls and engaging with potential clients.",
          "Communicate with clients to understand their content needs.",
        ],
      },
    ],
    qualities: [
      "Strong interpersonal and communication skills.",
      "Basic knowledge of corporate sales and business development.",
      "Market research skills to identify potential clients and business opportunities.",
    ],
  },
];

const featuresInternship = [
  {
    title: "Hands-On Experience",
    icon: HandsOnExperience,
  },
  {
    title: "Expert Mentorship",
    icon: ExpertMentorship,
  },
  {
    title: "Networking Opportunities",
    icon: NetworkingOpportunities,
  },
  {
    title: "Skill Development",
    icon: SkillDevelopment,
  },
];

const InternshipProgram = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const context = useContext(GlobalStateContext);

  const { setGlobalState } = context;

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setGlobalState((prevState) => ({
      ...prevState,
      activeRoute: "/internship-program",
    }));
    return () =>
      setGlobalState((prevState) => ({
        ...prevState,
        activeRoute: "",
      }));
  }, []);

  return (
    <div className="w-full">
      <div
        className="w-full min-h-[80vh]"
        style={{
          backgroundImage: `url(${BANNER})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}>
        <div
          className="w-full min-h-[80vh] flex flex-col justify-center items-center text-center"
          style={{
            backdropFilter: "blur(2px)",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}>
          <div className="text-white font-bold text-4xl mb-5">
            Career at Digital Agency - Internship 2.0
          </div>
          <div className=" text-white italic text-3xl">
            <div>“Unleash Your Potential And Kick-Start</div>
            <div>Your Career With WeTech Digital”</div>
          </div>
          <div className="mt-5">
            <button
              onClick={() => window.open("https://forms.gle/1f1g2u2zxmWMaWR6A")}
              className="button-sign-in !w-[10rem]">
              Apply Now
            </button>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-[80%] my-5">
          <div className="w-full mb-8 flex flex-col-reverse items-center lg:flex-row space-y-5 lg:space-x-5">
            <div className="w-full lg:w-[50%] mt-5 lg:mt-0 space-y-4">
              <div className="text-black font-bold text-2xl text-center">
                About Career at Digital Agency
              </div>
              <div className="text-black text-justify">
                At Wetech Digital, we believe in nurturing the talent of
                tomorrow. Whether you're a coding genius, a creative designer, a
                marketing enthusiast, or a business strategist, we have the
                perfect opportunity waiting for you to explore.
              </div>
            </div>
            <div className="w-full lg:w-[50%] flex flex-row justify-center items-center">
              <img src={about} alt="internship" className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center bg-[#0b5ed7]/10">
        <div className="w-[80%] my-5">
          <div className="w-full text-center">
            <div className="w-full text-3xl text-black font-bold mb-2">
              Features of the Internship
            </div>
            <div className="mb-5">
              Discover a Wide Selection Of Books Suitable For Every Reader's
              Taste. Shop Now.
            </div>
            <div className="flex flex-wrap justify-between">
              {featuresInternship?.map((feature, index) => {
                return (
                  <div
                    key={index}
                    className="py-2 px-3 w-full sm:w-1/4 items-center justify-center flex flex-col space-y-4 group hover:scale-105 transition-all">
                    <div className="bg-[#0b5ed7]/10 flex items-center justify-center rounded-md group-hover:shadow text-[#0099dd]">
                      <img
                        src={feature?.icon}
                        alt={feature?.title}
                        className="h-20"
                      />
                    </div>
                    <div className="text-[#0099dd] font-semibold">
                      {feature.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-[80%] my-5">
          <div className="w-full mb-8 flex flex-col-reverse lg:flex-row space-y-5 lg:space-x-5">
            <div className="w-full lg:w-[50%]">
              <img src={who} alt="internship" className="w-full h-full" />
            </div>
            <div className="w-full lg:w-[50%] mt-5 lg:mt-0 space-y-4">
              <div className="text-black font-bold text-2xl text-center">
                Who Can Apply ?
              </div>
              <div className="text-black text-justify flex flex-col space-y-4">
                <div>
                  This internship is designed for passionate students eager to
                  embark on an exciting journey in the world of technology and
                  innovation.
                </div>
                <div>
                  Our Internship Program is open to ambitious students from all
                  disciplines and backgrounds. Whether you're an undergraduate,
                  graduate, or recent graduate, as long as you have a passion
                  for technology and a desire to learn, we encourage you to
                  apply.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center bg-[#0b5ed7]/10">
        <div className="w-[80%] my-5">
          <div className="w-full mb-8 flex flex-col-reverse lg:flex-row space-y-5 lg:space-x-5">
            <div className="w-full lg:w-[50%] mt-5 lg:mt-0 space-y-4">
              <div className="text-black font-bold text-2xl text-center">
                Open Positions for Internship
              </div>
              <div className="text-black text-justify">
                We are offering a range of positions you can fit yourself in for
                the internship. Choose as per your preference (However, we
                encourage you to go out of your comfort zone and dare learning
                something new and exciting!)
              </div>
            </div>
            <div className="w-full lg:w-[50%]">
              <img src={open} alt="internship" className="w-full h-full" />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-start sm:justify-center mt-5">
            {jobDescriptionData?.map((jobDescription, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    openModal();
                    setJobDetails(jobDescription);
                  }}
                  className="py-2 px-2 m-2 shadow rounded items-center flex flex-row space-x-4 cursor-pointer group hover:scale-105 transition-all">
                  <div className="bg-[#0b5ed7]/10 flex items-center justify-center rounded-md group-hover:shadow text-[#0099dd]">
                    <img
                      src={jobDescription.icon}
                      alt={jobDescription.title}
                      className="h-12"
                    />
                  </div>
                  <div className="text-[#0099dd] font-semibold">
                    {jobDescription?.title}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-5 w-full flex flex-row justify-center items-center">
            <Link to="/internship-program">
              <button
                onClick={() =>
                  window.open("https://forms.gle/1f1g2u2zxmWMaWR6A")
                }
                className="button-sign-in !w-[10rem]">
                Apply Now
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-[80%] my-5">
          <div className="w-full flex flex-wrap justify-between space-y-3 sm:space-y-0">
            <div className="w-full sm:w-[30%]">
              <a
                href="/"
                onClick={() =>
                  setGlobalState((prevState) => ({
                    ...prevState,
                    activeRoute: "",
                  }))
                }>
                <img src={logo} alt="internship" className="w-[200px]" />
              </a>
            </div>
            <div className="w-full sm:w-[30%]">
              <div className="text-xl font-bold">Contact Us</div>
              <div className="flex flex-row space-x-5 mt-2">
                <div
                  onClick={() =>
                    window.open("mailto:contact@wetechdigital.com")
                  }
                  className="bg-[#0b5ed7]/10 p-2 cursor-pointer hover:shadow hover:scale-105 flex items-center justify-center rounded-md text-[#0099dd]">
                  <GrMail size={25} />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://www.google.com/maps/place/WeTech+Digital/@23.7529091,90.3816866,17z/data=!3m1!4b1!4m5!3m4!1s0x3755b9bece9995b1:0xb019b8000bb2cfbd!8m2!3d23.7529042!4d90.3838753"
                    )
                  }
                  className="bg-[#0b5ed7]/10 p-2 cursor-pointer hover:shadow hover:scale-105 flex items-center justify-center rounded-md text-[#0099dd]">
                  <FaSearchLocation size={25} />
                </div>
                <div
                  onClick={() => window.open("tel:+8801817698776")}
                  className="bg-[#0b5ed7]/10 p-2 cursor-pointer hover:shadow hover:scale-105 flex items-center justify-center rounded-md text-[#0099dd]">
                  <BsFillTelephoneForwardFill size={25} />
                </div>
              </div>
            </div>
            <div className="w-full sm:w-[30%]">
              <div className="text-xl font-bold">Social Links</div>
              <div className="flex flex-row space-x-5 mt-2">
                <div
                  onClick={() =>
                    window.open("https://www.facebook.com/WeTechDigitalGlobal/")
                  }
                  className="bg-[#0b5ed7]/10 p-2 cursor-pointer hover:shadow hover:scale-105 flex items-center justify-center rounded-md text-[#0099dd]">
                  <AiFillFacebook size={25} />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/wetechdigitalglobal/"
                    )
                  }
                  className="bg-[#0b5ed7]/10 p-2 cursor-pointer hover:shadow hover:scale-105 flex items-center justify-center rounded-md text-[#0099dd]">
                  <AiFillInstagram size={25} />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://bd.linkedin.com/company/wetechdigitalglobal"
                    )
                  }
                  className="bg-[#0b5ed7]/10 p-2 cursor-pointer hover:shadow hover:scale-105 flex items-center justify-center rounded-md text-[#0099dd]">
                  <AiFillLinkedin size={25} />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UC2bEtF_v_-zOiJqULPCAj5w"
                    )
                  }
                  className="bg-[#0b5ed7]/10 p-2 cursor-pointer hover:shadow hover:scale-105 flex items-center justify-center rounded-md text-[#0099dd]">
                  <AiFillYoutube size={25} />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-full mb-8 flex flex-col-reverse lg:flex-row space-y-5 lg:space-x-5">
            <div className="w-full lg:w-[50%]">
              
            </div>
            <div className="w-full lg:w-[50%] mt-5 lg:mt-0 space-y-4"></div>
          </div> */}
        </div>
      </div>
      {modalOpen && (
        <JobDescription data={jobDetails} closeModal={closeModal} />
      )}
    </div>
  );
};

export default InternshipProgram;
