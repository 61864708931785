import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "../../../../assets/css/career.css";

function JobApply() {
  //const myRef = useRef(null);
  const [applyInfo, setApplyInfo] = useState({});
  const [allCareerList, setCareerList] = useState([]);
  console.log(allCareerList)

  // const executeScroll = () =>
  //   myRef.current.scrollIntoView({
  //     behavior: "smooth",
  //   });

  const getAllCareerList = async () => {
    try {
      const dataFetched = await import("../../../../utils/services/career.service").then(
        async (service) => await service.getCareerList({})
      );
      if (dataFetched) {
        if (dataFetched.data.length === 0) {
          setCareerList(dataFetched.data);
        } else if (dataFetched.data.length > 0) {
          setCareerList(dataFetched.data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllCareerList();
  }, [])

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setApplyInfo((pre) => ({
      ...pre,
      [name]: value
    })
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const form = new FormData()
      const a = Object.entries(applyInfo)
      a.map(b => form.append(b[0], b[1]))
      const dataInserted = await import("../../../../utils/services/career.service").then(
        async (service) => await service.postCareerApply({ data: form })
      );
      if (dataInserted?.status === 201 || dataInserted?.status === 200) {
        setApplyInfo({})
        toast.success('Successfully submit.', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title> Career | WeTech</title>
          <meta name="description" content="Career | WeTech"></meta>
          <meta name="keywords" content="Career"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one" style={{ marginBottom: "4rem" }}>
            <div className="page-one-box">
              <div className="box-left px-4">
                <div className="box-one-title">
                  <h1 className="services-header-title">Career</h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    WeTech Digital is an all-in-one digital marketing services agency that is dedicated to developing your business's outstanding success with online advertising and marketing facilities.
                  </p>
                </div>
              </div>
              <div className="first-box-right px-4">
                <div className="right-image-section">
                  <img
                    className="image-file"
                    src="https://i.ibb.co/Q8D0VwS/268628588-310552550943818-4547320090692996864-n.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          {/* <div className="row">
            <div className="col-md-12">
              <h2 className="text-muted mb-5">Job Application Form</h2>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-12" style={{ background: "#0099DD" }}>
              <div className="jobDetailsCard" >
                <form action="" onSubmit={(e) => handleSubmit(e)} className="my-3">
                  <p className="services-contact-title text-start">Apply Now</p>
                  <div className="">
                    <label htmlFor="">Full Name</label>
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder="Full Name"
                      name="name"
                      id="name"
                      value={applyInfo?.name || ""}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="">Email Address</label>
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder="Email Address"
                      id="email"
                      name="email"
                      value={applyInfo?.email || ""}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="">Phone Number</label>
                    <input
                      className="form-control mb-3"
                      type="number"
                      placeholder="Phone Number"
                      id="phone"
                      name="phone"
                      value={applyInfo?.phone || ""}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="">Select Position</label>
                    <select
                      className="form-control mb-3"
                      type="select"
                      id="position"
                      name="position"
                      value={applyInfo?.position || ""}
                      onChange={(e) => handleChange(e)}
                    >
                      <option>Select</option>
                      {
                        allCareerList.map(data =>
                          <option value={data?.title}>{data?.title}</option>
                        )
                      }

                    </select>
                    <label htmlFor="">Upload Your CV</label>
                    <input
                      className="form-control mb-3"
                      type="file"
                      name="cv"
                      onChange={(e) => {
                        e.persist();
                        setApplyInfo((pre) => ({
                          ...pre,
                          cv: e.target.files[0]
                        }))
                      }}
                    />
                    <label htmlFor="" className="text-light">Upload Your cover letter</label>
                    <input
                      className="form-control mb-3"
                      type="file"
                      placeholder="Your Message"
                      id="message"
                      name="message"
                      // value={message}
                      onChange={handleChange}
                    />

                    <button className="career-button" type="submit">
                      <span>Apply</span>
                    </button>
                  </div>
                </form>

              </div>

            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default JobApply;
