import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
//import MainContent from "../../../assets/images/Partnership-deed.png";
import jam from "../../../assets/images/jamCheck.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/partnershipDeed/1.Partnership-deed-removebg-preview 1.png";
import image2 from "../../../assets/images/partnershipDeed/2.manager-leading-brainstorming-meeting 1 (5).png";
import image3 from "../../../assets/images/partnershipDeed/3.business-man-holding-paper-with-why-choose-us-question_23-2148932314 1 (5).png";
import image4 from "../../../assets/images/partnershipDeed/4.workers-pointing-increase-sales 2 (5).png";
import image5 from "../../../assets/images/partnershipDeed/5.portrait-of-middle-aged-woman-professional-psychol-2022-01-14-00-05-09-utc 1.png";
import image6 from "../../../assets/images/partnershipDeed/6.businesspeople-celebrating-success 2 (5).png";
import { Link } from "react-router-dom";

function PartnershipDeed() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Business partnership agreement consultant in Bangladesh | WeTech
          </title>
          <meta
            name="description"
            content="WeTech Digital is a Business partnership agreement consultant in Bangladesh. To develop your business get partnership deed and consultation services.
"
          ></meta>
          <meta
            name="keywords"
            content="
          

business partnership agreement consultant
,partnership deed
,Partnership deed Registration
,cost of Partnership deed Registration
,partnership deed service agency
,partnership deed service providers
,service partnership agreement
,business partnership agreement
,Business Development Agreement service
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">Partnership Deed</p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want to get Partnership Deed in Bangladesh? Then
                    we're here to help you out competently. WeTech Digital has
                    the best business partnership agreement consultant team.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span> &gt; </span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top business partnership agreement
                  consultant Agency in Bangladesh?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top business partnership agreement
                  consultant Agency in Bangladesh. A partnership deed is an
                  agreement between a firm's partners that spells out the terms
                  and conditions of their partnership. This is what the
                  partnership deed is for. One cannot deny the importance of
                  business partnership agreement in collaborative business. It
                  defines conditions like profit/loss sharing, salary, interest
                  on capital, draws and new partner admission and so on. We
                  offer that lawfully which give you uniqueness and firmness.
                  Partnership deed requires a well decided name, all partners'
                  information, related information, the amount of money each
                  partner has put in. At first you have to apply for Partnership
                  deed Registration. It seems to be that Partnership deed
                  Registration is very costly, but we promised to do that at a
                  comparatively low cost. Our partnership deed services are very
                  popular in Bangladeshi market and it introduces us as the best
                  partnership deed service agency in Bangladesh.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Partnership Deed in Bangladesh
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital is dedicated partnership deed service agency
                  who provides business partnership agreement. Each investor
                  will directly contribute to the firm's equity. The firm's
                  profits and losses will be shared evenly among the partners.
                  Partners will not be paid a compensation for executing company
                  tasks. There will be no interest authorized on the capitals of
                  partners. These things should be strongly followed. WeTech
                  Digital will help to maintain these all. Cost of Partnership
                  deed Registration is not very much. As partnership deed
                  service providers, we are much focused to sort out all related
                  matters. A service partnership agreement, also known as a firm
                  contractual relationship, is a legal document that spells out
                  the terms and conditions of your company's connection with its
                  service partner. So you will need service partnership
                  agreement if you have joint business. So, you understand the
                  importance of Business development Agreement service in your
                  business.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Research Business details</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Market research & detect location
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Select business name</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Prepare agreements terms & conditions
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Apply for granted</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Collect & delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require business partnership agreement consultant in
                    Bangladesh?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <Link to="/contact">
                      <button className="services-five-button">
                        Contact Us
                      </button>
                    </Link>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <hr
                    style={{
                      width: "4rem",
                      backgroundColor: "white",
                      margin: "12px 10px",
                      height: "1px",
                    }}
                  />
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We always balancing customer requirements
                  </li>
                  <li className="page-six-li">
                    One of the reliable partnership deed service providers.
                  </li>
                  <li className="page-six-li">
                    We provide more efficient work.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platforms.
                  </li>
                  <li className="page-six-li">
                    We have affordable service cost.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Very low cost of Partnership deed Registration.
                  </li>
                  <li className="page-six-li">
                    Cost effective Business development Agreement service.
                  </li>
                  <li className="page-six-li">
                    Best consultant team provider.
                  </li>
                  <li className="page-six-li">Dedicated and expert team.</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Local & Global Partnership Deed Service At The
                Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-main">
                <div className="pricing-table-section">
                  <div className="table-header">
                    <div className="feature-section">
                      <h2 className="table-title">Features</h2>
                    </div>
                    <div className="silver-section">
                      <h2 className="table-title">Silver</h2>
                    </div>
                    <div className="gold-section">
                      <h2 className="table-title">Gold</h2>
                    </div>
                    <div className="platinum-section">
                      <h2 className="table-title">Platinum</h2>
                    </div>
                  </div>
                  <div className="table-content1">
                    <div className="feature">
                      <p>
                        Product Imagery List (list of products that do not meet
                        Amazon recommended image number or have image errors)
                      </p>
                    </div>
                    <div className="silver">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum">
                      <img src={jam} alt="" />
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Initial Photo Editing</p>
                    </div>
                    <div className="silver">
                      <p>Up to 15 child ASINs</p>
                    </div>
                    <div className="gold">
                      <p>Up to 15 child ASINs</p>
                    </div>
                    <div className="platinum">
                      <p>Up to 15 child ASINs</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>In-Stock Rate Monitoring</p>
                    </div>
                    <div className="silver">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum">
                      <img src={jam} alt="" />
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Initial Photo Editing</p>
                    </div>
                    <div className="silver">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum">
                      <img src={jam} alt="" />
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Perfect Order Percentage Monitoring</p>
                    </div>
                    <div className="silver">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum">
                      <img src={jam} alt="" />
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Order Defect Rate Monitoring</p>
                    </div>
                    <div className="silver">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum">
                      <img src={jam} alt="" />
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Quarterly Product Analysis</p>
                    </div>
                    <div className="silver">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum">
                      <img src={jam} alt="" />
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Monthly Sales Monitoring</p>
                    </div>
                    <div className="silver">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum">
                      <img src={jam} alt="" />
                    </div>
                  </div>

                  {/* <div className="pricing-btn-section">
                    <div className="button-section">
                      <div className="silver-button">
                        <div className="button-header">
                          <p className="button-content">
                            Tk 300{" "}
                            <span className="btn-content-span">/mon</span>
                          </p>
                        </div>
                        <div className="buttons">
                          <button className="pricing-btn-silver">Select</button>
                        </div>
                      </div>
                      <div className="gold-button">
                        <div className="button-header">
                          <p className="button-content">
                            Tk 300{" "}
                            <span className="btn-content-span">/mon</span>
                          </p>
                        </div>
                        <div className="buttons">
                          <button className="pricing-btn-gold">Select</button>
                        </div>
                      </div>
                      <div className="platinum-button">
                        <div className="button-header">
                          <p className="button-content">
                            Tk 300{" "}
                            <span className="btn-content-span">/mon</span>
                          </p>
                        </div>
                        <div className="buttons">
                          <button className="pricing-btn-platinum">
                            Select
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default PartnershipDeed;
