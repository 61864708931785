const data = [
  {
    name: "App Design",
    silver: "",
    gold: "",
  },
  {
    name: "App Prototyping",
    silver: "",
    gold: "",
  },
  {
    name: "App Programming",
    silver: "",
    gold: "",
  },
  {
    name: "User Testing",
    silver: "",
    gold: "",
  },
  {
    name: "App Markplace Submission & Approval",
    silver: "",
    gold: "",
  },
  {
    name: "App Marketing",
    silver: "",
    gold: "",
  },
  {
    name: "Investment",
    silver: "Starting at $3,000",
    gold: "Starting at $7,000",
  },
];
export default data;
