import React, { useRef } from "react";
import ContactWetech from "../../blocks/ContactWetech";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
//import link from "../../../assets/images/ecommerce-socail-serveice.png";
import jam from "../../../assets/images/jamCheck.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/eCommerceSocialAdsService/1.Daco_4973678 1.png";
import image2 from "../../../assets/images/eCommerceSocialAdsService/2.social-media-communication-networking-online-concept 1.png";
import image3 from "../../../assets/images/eCommerceSocialAdsService/3.businessman-with-tablet-after-closing-deal 1.png";
import image4 from "../../../assets/images/eCommerceSocialAdsService/4.graph-growth-development-improvement-profit-success-concept 1.png";
import image5 from "../../../assets/images/eCommerceSocialAdsService/5.png";
import image6 from "../../../assets/images/eCommerceSocialAdsService/6.aerial-view-business-team 1.png";
import { Link } from "react-router-dom";

function EcommerceSocialAdsServices() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Ecommerce social media advertising services packages | WeTech
          </title>
          <meta
            name="description"
            content="Grow your Ecommerce store traffic & sales conversion with Ecommerce social media advertising services. Check out our affordable e-commerce packages."></meta>
          <meta
            name="keywords"
            content="
          
ecommerce social media advertising services
,ecommerce social media marketing agency
,ecommerce social media advertising agency
,ecommerce digital marketing agency
,social media advertising agency for ecommerce"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">
                    Ecommerce Social Advertising
                  </p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want Ecommerce Social Advertising Services? Then
                    we're here to make you happy by offering that service in a
                    professional manner. It is very essential for your business
                    success.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Ecommerce Social Media Advertising
                  Services Company?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top e-commerce social media advertising
                  agency in the market. You can also call it an eCommerce
                  digital marketing agency. Paid advertising on sites like
                  Facebook, Twitter, Instagram, Linkedln, YouTube, and others is
                  used for e-commerce social media advertising. Remunerated
                  social advertising extends your influence on social networks
                  beyond your organic social media approach. If the processes
                  above seem overwhelming, we recommend collaborating with
                  eCommerce advertising professionals, such as our team at
                  WeTech Digital. An expert e-commerce social media advertising
                  firm can guide you through each stage of the process of
                  creating ads that provide results for your company. As an
                  eCommerce social media marketing agency, we provide our
                  service professionals
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Ecommerce Social Advertising Services.
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  Nowadays social media advertising agency for eCommerce plays
                  an important role. Exactly did you notice that 74 percent of
                  the total of consumers make purchasing decisions based on
                  social media? As a result, the social media advertisements on
                  your e-commerce site can have a direct influx directly
                  influence on me. Contact WeTech Digital to learn more about
                  how our eCommerce social media advertising services as a
                  Facebook Marketing Partner may help you develop your
                  e-commerce business! It's more difficult than ever to reach
                  clients organically in their social feeds, thanks to algorithm
                  tweaks on sites like Facebook. That's why, especially for
                  eCommerce shops wanting to expand their online sales, social
                  media advertising should be considered.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Development Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Social media selection </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Content Developing </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Landing pages customization </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Landing pages customization </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Media advertise launching </p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require Ecommerce Social Advertising Services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why WeTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Assist you in comprehending the target industry.
                  </li>
                  <li className="page-six-li">
                    We provide work that is more efficient.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platforms.
                  </li>
                  <li className="page-six-li">Vast promotional campaign</li>
                  <li className="page-six-li">
                    We have a fully focused advertising manager
                  </li>
                  <li className="page-six-li">Unique advertising idea.</li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why WeTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    {" "}
                    Substantial assistance from members of the social team who
                    are experts on each network
                  </li>
                  <li className="page-six-li">
                    We use customer records or social links can be used to
                    create bespoke audiences.
                  </li>
                  <li className="page-six-li">
                    Cost-effective campaign arrangements.
                  </li>
                  <li className="page-six-li">
                    The special management and optimization services.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Ecommerce Social Advertising Service At The Right
                Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-main">
                <div className="pricing-table-section">
                  <div className="table-header">
                    <div className="feature-section">
                      <h2 className="table-title">Features</h2>
                    </div>
                    <div className="silver-section-ex">
                      <h2 className="table-title">Silver</h2>
                    </div>
                    <div className="gold-section-ex">
                      <h2 className="table-title">Gold</h2>
                    </div>
                    <div className="platinum-section-ex">
                      <h2 className="table-title">Platinum</h2>
                    </div>
                    <div className="platinum-section-ex">
                      <h2 className="table-title">extra</h2>
                    </div>
                  </div>
                  <div className="table-content1">
                    <div className="feature">
                      <p>
                        Product Imagery List (list of products that do not meet
                        Amazon recommended image number or have image errors)
                      </p>
                    </div>
                    <div className="silver-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <p>Lorem ipsum dolor sit amet consectetur</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Initial Photo Editing</p>
                    </div>
                    <div className="silver-ex">
                      <p>Up to 15 child ASINs</p>
                    </div>
                    <div className="gold-ex">
                      <p>Up to 15 child ASINs</p>
                    </div>
                    <div className="platinum-ex">
                      <p>Up to 15 child ASINs</p>
                    </div>
                    <div className="platinum-ex">
                      <p>Up to 15 child ASINs</p>
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>In-Stock Rate Monitoring</p>
                    </div>
                    <div className="silver-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Initial Photo Editing</p>
                    </div>
                    <div className="silver-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Perfect Order Percentage Monitoring</p>
                    </div>
                    <div className="silver-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Order Defect Rate Monitoring</p>
                    </div>
                    <div className="silver-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Quarterly Product Analysis</p>
                    </div>
                    <div className="silver-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                  </div>
                  <div className="table-content">
                    <div className="feature">
                      <p>Monthly Sales Monitoring</p>
                    </div>
                    <div className="silver-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="gold-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                    <div className="platinum-ex">
                      <img src={jam} alt="" />
                    </div>
                  </div>

                  {/* <div className="pricing-btn-section">
                    <div className="button-section">
                      <div className="silver-button-ex">
                        <div className="button-header">
                          <p className="button-content">
                            Tk 300{" "}
                            <span className="btn-content-span">/mon</span>
                          </p>
                        </div>
                        <div className="buttons">
                          <button className="pricing-btn-silver">Select</button>
                        </div>
                      </div>
                      <div className="gold-button-ex">
                        <div className="button-header">
                          <p className="button-content">
                            Tk 300{" "}
                            <span className="btn-content-span">/mon</span>
                          </p>
                        </div>
                        <div className="buttons">
                          <button className="pricing-btn-gold">Select</button>
                        </div>
                      </div>
                      <div className="platinum-button-ex">
                        <div className="button-header">
                          <p className="button-content">
                            Tk 300{" "}
                            <span className="btn-content-span">/mon</span>
                          </p>
                        </div>
                        <div className="buttons">
                          <button className="pricing-btn-platinum">
                            Select
                          </button>
                        </div>
                      </div>
                      <div className="platinum-button-ex">
                        <div className="button-header">
                          <p className="button-content">
                            Tk 300{" "}
                            <span className="btn-content-span">/mon</span>
                          </p>
                        </div>
                        <div className="buttons">
                          <button className="pricing-btn-platinum">
                            Select
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default EcommerceSocialAdsServices;
