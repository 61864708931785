import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import search from "../../../assets/images/searchAD.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/localSearchaAds/1.confident-business-team-with-leader 1.webp";
import image2 from "../../../assets/images/localSearchaAds/2.dark-businesswoman-shaking-hands-with-male-colleague 1.webp";
import image3 from "../../../assets/images/localSearchaAds/3.front-view-businessman-with-colorful-cones-representing-growth 1.webp";
import image4 from "../../../assets/images/localSearchaAds/4.no-idea 1.webp";
import image5 from "../../../assets/images/localSearchaAds/5.webp";
import { Link } from "react-router-dom";

function LocalSearchAdsManagement() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Local Search Ads: Google Local Ads services agency | WeTech
          </title>
          <meta name="description" content="Basic content"></meta>
          <meta
            name="keywords"
            content="
          
google local ads services
,Secondary Keywords
,local service ads by google
,local ad agency
,google ads agency price
,best google ads agency
,google ads agency
,google local search ads
,local search ads google
,local ads personal
,home depot local ads


          
          "></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">
                    Local Search Ads Management
                  </p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want to enhance local leads, traffic volume, and
                    conversions for your company? If that's the case, Google
                    Local Services advertisements are a wise investment, and
                    WeTech Digital makes ad administration a breeze. We have the
                    best solutions for you.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={search} alt="Search Ad" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Local search Ads Management Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  Local search advertising is a method for companies to be
                  highlighted and stand out in Google's local results area. When
                  a person searches for a specific service near them, they may
                  find adverts that include your address, phone number, hours of
                  operation, and ratings. Connecting with local search ads
                  management, you will make contact with people in your area who
                  are most likely to use your services; with Google Guarantee,
                  you can build trust, Pay just for leads who are interested in
                  your products and services. We arrange local search ads for
                  Google which is a part of the local results section of a
                  Google search. Google local ads services appear on user
                  screens near their location. Google local search ads can also
                  show your information as an advertisement in your locality. We
                  ensure you give perfect local search ads management. Local ads
                  personal can improve your business personality.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to Local Search Ads</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  Management Service We specify each of the research carefully
                  and verify which campaign is going to cover your companies
                  demand. We work on voice search optimization, budget
                  management, review information of request, Custom reporting.
                  We have dedicated local marketing experts who focus on
                  geographic targeting. Also, we are concerned with home depot
                  local ads, which send emails about special promotions, offers,
                  how-to design & project ideas. Google realized this fact and
                  began offering a solution for small companies in 2018 called
                  Local Services Ads to try to fair competition. Local Services
                  Ads are promoted business ads that appear in search results
                  for service-related inquiries. We arrange local service ads by
                  Google for you. We ensure that the best Google ads agency is
                  collaborating with a local ad agency for your need. There
                  seems to be no lowest possible expenditure on Google Ads,
                  however, daily expenditure of $2 or $5 is almost hard to gain
                  from. But still WeTech Digital makes a compromise Google ads
                  agency price for you.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process </p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Work on Google ads account</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Update My Business listing</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Target Locality & bidding</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Optimization & Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Want a well-managed Local Search Ads Management!
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us.</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">We confirm your eligibility.</li>
                  <li className="page-six-li">Prepare a business profile.</li>
                  <li className="page-six-li">
                    Take care on adding license & insurance details.
                  </li>
                  <li className="page-six-li">Make a friendly budget.</li>
                  <li className="page-six-li">
                    CCreate a way for you to track appointments easily{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image4} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image5} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Give you a way to get more valuable leads.
                  </li>
                  <li className="page-six-li">
                    We make sure that you only pay for valid leads.
                  </li>
                  <li className="page-six-li">
                    Fulfill local industry-specific requirements.
                  </li>
                  <li className="page-six-li">
                    We plan, execute, and refine strategy through our experts
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Local Search Ads Management Service At The Right
                Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default LocalSearchAdsManagement;
