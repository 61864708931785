import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
import seoService1 from "../../../assets/images/rsz-home6.png";
import seoService2 from "../../../assets/images/rsz-seoservice2.png";
import seoService3 from "../../../assets/images/rsz-home2.png";
import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import jam from "../../../assets/images/jamCheck.png";
import data from "./data/ContentMarketingService";
import Testimonial from "../../blocks/Testimonial";
import { Link } from "react-router-dom";

function ContentMarketingService() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>WeTech - Content Marketing services</title>
          <meta name="description" content="Basic content"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box-custom">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">
                    Content Marketing services
                  </p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Our content marketing services make content marketing
                    simple. Our professional team produces, writes, edits, and
                    promotes unique as well as search engine compatible, content
                    for your business as part of our content marketing service
                    packages. WeTech Digital can handle everything from blog
                    entries to online instructions to videos.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img
                    className="service-smile-image-landing"
                    src={smile}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Content Marketing Services Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  One of the most effective digital marketing tactics is content
                  marketing. Content marketing is critical to growing your
                  company's income since it can double website conversion rates,
                  as well as generate brand awareness and website traffic. You
                  can anticipate bespoke material that adheres to search engine
                  optimization guidelines when you work with Wetech Digital as
                  your content marketing services partner. Even more, you can
                  rely on honesty - there are no hidden costs or methods. WeTech
                  Digital is an affordable content marketing agency. As there
                  are many content marketing service providers, Wetech Digital
                  is also reliable.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Content Marketing Services
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  All of our content marketing services packages include a
                  personalized content strategy tailored to your company,
                  audience, and objectives. Then, for your company's must-share
                  material, develop a content schedule. Whether it's a blog
                  post, an online tutorial, or a video, our in-house copywriting
                  and marketing team gets to work on your bespoke content. SEO,
                  which optimizes your content for both consumers and search
                  engines, is included in your content marketing management
                  services. We help market your content online in the relevant
                  areas very much to your audience by using our network of
                  targeted audiences. We have content creation packages with
                  different content creation price lists. Our content marketing
                  pricing packages can meet your need.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Design Process </p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Contents agenda </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Keywords research</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Contents innovation </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Development </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Optimization</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Promotion </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Post on media </p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={seoService3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you want to get first-class Content Marketing Services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let's get to work collectively
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We offer the best hands-on approach, a tailored plan, and a
                    project manager who is devoted.
                  </li>
                  <li className="page-six-li">
                    We examine the concept from a variety of perspectives.
                  </li>
                  <li className="page-six-li">
                    We may also create bespoke solutions to meet your company's
                    specific requirements.
                  </li>
                  <li className="page-six-li">Make it mobile-friendly.</li>
                  <li className="page-six-li">
                    We are eager to keep in contact with you while working and
                    also positive about your opinion.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService2} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService4} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We work with your organization to gain a thorough
                    understanding of your firm's mission, goals, and industry.
                  </li>
                  <li className="page-six-li">
                    We assist you in creating a unique content marketing plan.
                  </li>
                  <li className="page-six-li">
                    We meant to aid in the ranking of your website's content in
                    search engine results and the conversion of consumers.
                  </li>
                  <li className="page-six-li">
                    We increase the amount of high-quality visitors to your
                    website
                  </li>
                  <li className="page-six-li">
                    Maximize your competitive edge
                  </li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements{" "}
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="page-ten-header">
            <p className="page-ten-title">
              Find High-end Content Marketing Services At The Right Price
            </p>
          </div>

          <div className="pricing-main">
            <div className="pricing-table-section">
              <div className="table-header">
                <div className="feature-section">
                  <h2 className="table-title">Features</h2>
                </div>
                <div className="silver-section">
                  <h2 className="table-title">Silver</h2>
                </div>
                <div className="gold-section">
                  <h2 className="table-title">Gold</h2>
                </div>
                <div className="platinum-section">
                  <h2 className="table-title">Platinum</h2>
                </div>
              </div>
              <div className="table-content1">
                <div className="feature">
                  <p></p>
                </div>

                <div className="silver">
                  <p>$900</p>
                </div>
                <div className="gold">
                  <p>$1,800</p>
                </div>
                <div className="platinum">
                  <p>$3,000</p>
                </div>
              </div>
              {data.map((data) => (
                <div className="table-content1">
                  <div className="feature">
                    <p>{data.name}</p>
                  </div>

                  <div className="silver">
                    {data.silver === "" ? (
                      <img src={jam} alt="" />
                    ) : (
                      <p>{data.silver}</p>
                    )}
                  </div>
                  <div className="gold">
                    {data.gold === "" ? (
                      <img src={jam} alt="" />
                    ) : (
                      <p>{data.gold}</p>
                    )}
                  </div>
                  <div className="platinum">
                    {data.platinum === "" ? (
                      <img src={jam} alt="" />
                    ) : (
                      <p>{data.platinum}</p>
                    )}
                  </div>
                </div>
              ))}

              {/* <div className="pricing-button-section">
                <div className="button-section">
                  <div className="silver-button">
                    <div className="button-header">
                      <p className="button-content">
                        Tk 300 <span className="btn-content-span">/mon</span>
                      </p>
                    </div>
                    <div className="buttons">
                      <button className="pricing-btn-silver">Select</button>
                    </div>
                  </div>
                  <div className="gold-button">
                    <div className="button-header">
                      <p className="button-content">
                        Tk 300 <span className="btn-content-span">/mon</span>
                      </p>
                    </div>
                    <div className="buttons">
                      <button className="pricing-btn-gold">Select</button>
                    </div>
                  </div>
                  <div className="platinum-button">
                    <div className="button-header">
                      <p className="button-content">
                        Tk 300 <span className="btn-content-span">/mon</span>
                      </p>
                    </div>
                    <div className="buttons">
                      <button className="pricing-btn-platinum">Select</button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default ContentMarketingService;
