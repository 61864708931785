import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import appStore1 from "../../../assets/images/app-store-optimization-1.png";
import appStore2 from "../../../assets/images/app-store-optimization-2.png";
import PricingTable from "./pricing/AppStoreOptimization";
import Testimonial from "../../blocks/Testimonial";

import appStoreSeo1 from "../../../assets/images/appStoreOptimize/1.diverse-excited-best-friends-giving-high-five-together-cafe-meeting.webp";
import appStoreSeo2 from "../../../assets/images/appStoreOptimize/2.man-holding-white-arrow-showing-east-side 1.webp";
import appStoreSeo3 from "../../../assets/images/appStoreOptimize/3.top-view-office-desk-with-growth-chart-analyzed-with-magnifying-glass 1.webp";
import appStoreSeo4 from "../../../assets/images/appStoreOptimize/4.vertical-shot-contemplative-thoughtful-dark-skinned-man-keeps-hand-chin-looks-thoughtfully-upwards 1.webp";
import appStoreSeo5 from "../../../assets/images/appStoreOptimize/5.creative-idea-innovation-knowledge.webp";
import { Link } from "react-router-dom";

function AppStoreOptimizaton() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>WeTech - App Store Optimization</title>
          <meta name="description" content="Basic content"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">
                    App Store Optimization
                  </p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Want to Increase Visibility Of your App on Google Display &
                    App Store?
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file-double" src={appStore1} alt="" />
                  <img className="image-file-double" src={appStore2} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top ASO Service Provider?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  Our approach is based on a fundamental fact about app store
                  optimization: It is critical to managing your app's
                  credibility! A positive app reputation might boost your
                  conversion rate. We can assist in the following ways:
                  Recognize problems in your user evaluations that necessarily
                  involve fast action, Display information & feedback quickly
                  convert low reviews into good ones. We have a different and
                  proven algorithm to increase your Apps visibility which will
                  engage potential users and encourage conversions.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={appStoreSeo1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={appStoreSeo2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to App Store Optimization</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  Our expert team knows better the procedure of marketing a game
                  or program to optimize the presence across stores in Explore
                  (when users search) and Discover (when users browse), create
                  visibility to listing, and enhance profit margin in order to
                  produce the highest percentage of organic installations.
                  Organic installs are more important because they are based on
                  people's explicit desire to find and install a specific
                  program to fill a specific need, which is not the case when
                  they are exposed to an ad. It's a higher-quality customer
                  experience from the start, and we promise to give the best
                  service possible through our top-rated professionals.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Execution</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Review</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Optimization</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Manual Test</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={appStoreSeo3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Let our E-commerce SEO experts help your online store
                    through our best works!
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us work together to make it happen
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    A detailed analysis of your app store visibility.
                  </li>
                  <li className="page-six-li">
                    A glimpse at your competitors' activity.
                  </li>
                  <li className="page-six-li">
                    To showcase your app's USPs and captivate users, employ
                    strategic language and creative images.
                  </li>
                  <li className="page-six-li">
                    An increase in the organic discoverability of your app.
                  </li>
                  <li className="page-six-li">
                    An increase in the conversion rate of app store visitors to
                    proactive app users.
                  </li>
                  <li className="page-six-li">
                    Day after day will increase more organic downloads.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={appStoreSeo4} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={appStoreSeo5} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <hr
                    style={{
                      width: "4rem",
                      backgroundColor: "white",
                      margin: "12px 10px",
                      height: "1px",
                    }}
                  />
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We convert development into profitable solutions.
                  </li>
                  <li className="page-six-li">Result-oriented outcomes</li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="page-ten-header">
            <p className="page-ten-title">
              Find High-end App Store Optimization Service At The Right Price
            </p>
          </div>

          <PricingTable />

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default AppStoreOptimizaton;
