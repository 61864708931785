import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import data from "./data/LandingPageDesign";
// photos
import smile from "../../../assets/images/smileMan.png";
import seoService1 from "../../../assets/images/rsz-home6.png";
import seoService2 from "../../../assets/images/rsz-seoservice2.png";
import seoService3 from "../../../assets/images/rsz-home2.png";
import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import landing from "../../../assets/images/landing.png";
import jam from "../../../assets/images/jamCheck.png";
import Testimonial from "../../blocks/Testimonial";
import { Link } from "react-router-dom";

function LandingPageDesign() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Landing page design services: How much does it cost? | WeTech
          </title>
          <meta
            name="description"
            content=" Landing pages are a powerful way to increase conversion rates from your PPC campaigns. WeTech Digital will provide you landing page development services at an affordable cost.
"
          ></meta>
          <meta
            name="keywords"
            content="
          landing page design services
          ,landing page design agency
          ,landing page design experts
          ,landing page development services
          ,best landing page services
          ,landing page designing services
          
          
          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">Landing Page Design</p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Are you looking for strategies to increase your sales and
                    boost your profits? Landing pages are a crucial component of
                    any marketing and advertising strategy. Using skilled
                    landing page design services may help you improve the impact
                    of your landing pages. Our experienced Landing page creators
                    are here to help you out.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={landing} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Landing Page Design Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  A landing page is a page on your website that is created with
                  the goal of converting visitors into leads. Wetech Digital can
                  help your firm get a competitive advantage. Our landing page
                  designer services provide a turn-key solution for creating,
                  deploying, and upgrading landing pages, resulting in more
                  leads, sales, and money for your company. Landing page design
                  services can help you convert visitors into sales. Instead of
                  sending visitors to your homepage, send them to a highly
                  focused landing page. Landing pages persuade visitors to
                  remain and do a certain action, such as buying a product. We
                  successfully do all these for our clients as we have best
                  landing page design experts.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to Landing Page Design</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  You may create a page dedicated to your dog walking business
                  using a custom landing page design. You may drive potential
                  customers to this specific page instead of your homepage when
                  they click on your ad. They'll be more likely to complete the
                  purchase if they're given fast access to information, price,
                  and other details about your dog walking service. You'll get a
                  different conversion strategy and design for each landing
                  page. Working with a competent account manager can help you
                  get the most bangs for your buck. Create landing pages that
                  are mobile-friendly, desktop-friendly, and tablet-friendly.
                  Conversion tracking codes should be included to all of your
                  landing pages. Our landing page development services are world
                  class. Wetech gives the best landing page services.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Idea Outline</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Benefits & solution list</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">CTA preparing</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Design & Optimization</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={seoService3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you want to get a first-class landing page design?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us work with you.
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We have best Landing Page Development Teams.
                  </li>
                  <li className="page-six-li">
                    We provide our focus sincerely on any product.
                  </li>
                  <li className="page-six-li">
                    We make headlines that will catch people's attention.
                  </li>
                  <li className="page-six-li">
                    We establish a landing page design that is consistent.
                  </li>
                  <li className="page-six-li">
                    We examine the concept from a variety of perspectives.
                  </li>
                  <li className="page-six-li">
                    Make strong appeals to action (CTAs)
                  </li>
                  <li className="page-six-li">Integrate cues of trust</li>
                  <li className="page-six-li">
                    Increase your conversion rates.
                  </li>
                  <li className="page-six-li">We boost your revenue.</li>
                  <li className="page-six-li">Make it mobile-friendly.</li>
                  <li className="page-six-li">
                    Keep forms to a minimum and include at least one image.
                  </li>
                  <li className="page-six-li">
                    We are eager to keep contacts with you while working and
                    also positive with your opinion.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService2} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService4} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We turn campaigns into money-making solutions by landing
                    page designing services
                  </li>
                  <li className="page-six-li">
                    Outcomes that are aimed towards achieving a certain goal
                  </li>
                  <li className="page-six-li">
                    Meet industry-specific specifications
                  </li>
                  <li className="page-six-li">
                    Maximize your competitive edge
                  </li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Percel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Local & Global Landing Page Design Service At The
                Right Price
              </p>
            </div>
            <div className="pricing-main">
              <div className="pricing-table-section">
                <div className="table-header">
                  <div className="feature-section">
                    <h2 className="table-title">Features</h2>
                  </div>
                  <div className="silver-section">
                    <h2 className="table-title">Static</h2>
                  </div>
                  <div className="gold-section">
                    <h2 className="table-title">Dynamic with Testing</h2>
                  </div>
                  <div className="platinum-section">
                    <h2 className="table-title">Platinum</h2>
                  </div>
                </div>
                <div className="table-content1">
                  <div className="feature">
                    <p></p>
                  </div>

                  <div className="silver">
                    <p>$700/page</p>
                  </div>
                  <div className="gold">
                    <p>$1200/page</p>
                  </div>
                  <div className="platinum">
                    <p>$1600/page</p>
                  </div>
                </div>

                {data.map((data) => (
                  <div className="table-content1">
                    <div className="feature">
                      <p>{data.name}</p>
                    </div>

                    <div className="silver">
                      {data.silver === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.silver}</p>
                      )}
                    </div>
                    <div className="gold">
                      {data.gold === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.gold}</p>
                      )}
                    </div>
                    <div className="platinum">
                      {data.platinum === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.platinum}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default LandingPageDesign;
