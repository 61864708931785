const data = [
  {
    name: "Initial CRO campaign (First month) CRO & UX Assets",
    silver: "2 CRO or UX Assets",
    gold: "4 CRO or UX Asset",
    platinum: "8 CRO or UX Asset",
  },
  {
    name: "Monthly Ongoing CRO & UX Assets",
    silver: "1 CRO or UX Asset",
    gold: "2 CRO or UX Assets",
    platinum: "4 CRO or UX Assets",
  },
  {
    name: "Dedicated UX project manager",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monthly user experience reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "150+ SMEs behind campaign driving results",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Online project management scheduling",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial Website User Testing Videos - Up to 15 minute video (Q&A + summary of findings for each video)",
    silver: "1 video",
    gold: "2 videos",
    platinum: "4 videos",
  },
  {
    name: "Initial heatmap and click stream testing and analysis",
    silver: "Up to 4 pages",
    gold: "Up to 8 pages",
    platinum: "Up to 16 pages",
  },
  {
    name: "Conversion tracking code setup",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Confirmation/thank you page setup",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Goal Funnels Setup - Initial Analytics + Reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial Conversion Audit - What Portions of Site to Test/Optimize for Conversions + Optimization Suggestions",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "VisitorRecorder - Web video capture of all converted leads (available for 60 days)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "CRO & UX Assets Consist of:",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Use of Google Optimize for A/B testing (Client Google Analytics access is required)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Raw Heat Map Data Provided",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "ROI & split test reporting schedule",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Creative design for A/B testing",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Market research",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Conversion strategy",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Conversion best practices documentation",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Static calls to action design",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Lead form setup/modifications",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Navigational modifications",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Multivariate conversion testing",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "USP and headline copywriting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Setup of auto respondersg",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Incoming traffic reporting and recommendations",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Conversion path implementation",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Lead forms integrated into supported CRMs ",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Performance test reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Conversion reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Incoming traffic analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ecommerce websites",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Shopping cart abandonment testing",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Google Ads landing page conversion audit (one-time)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Landing page creation",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Additional CRO & UX Assets = $600 each",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial Setup Month 1",
    silver: "$750",
    gold: "$1,450",
    platinum: "$2,900",
  },
  {
    name: "Conversion Rate Optimization Ongoing Investment",
    silver: "$300",
    gold: "$750",
    platinum: "$1,500",
  },
];

export default data;
