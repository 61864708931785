import React, { useRef } from "react";
import ContactWetech from "../../blocks/ContactWetech";
import { Helmet } from "react-helmet";
import data from "./data/ConverstionOptimizaton";

// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import jam from "../../../assets/images/jamCheck.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/conversionRateOptimization/1.PinClipart 2 (3).png";
import image2 from "../../../assets/images/conversionRateOptimization/2.3d-illustration-conversion-funnel-with-entry-exit-business-marketing-concept-leads-sales-ratio-horizontal-image 1.png";
import image3 from "../../../assets/images/conversionRateOptimization/3.businessman-colleague-shaking-hands 1.png";
import image4 from "../../../assets/images/conversionRateOptimization/4.paper-sheet-with-conversion-rates-statistics-rubber-stamp-slogan-with-text-increase-your-conversion-rate-3d-illustration 1.png";
import image5 from "../../../assets/images/conversionRateOptimization/5.png";
import image6 from "../../../assets/images/conversionRateOptimization/6.excited-stylish-curly-haired-girl-sunglasses-pointing-right-showing-way 1.png";
import { Link } from "react-router-dom";

function ConversionRateOptimization() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            {" "}
            CRO: Conversion rate optimization agency | WeTech Digital
          </title>
          <meta name="description" content="Basic content"></meta>
          <meta
            name="keywords"
            content="conversion rate optimization agency

,ecommerce conversion rate optimization agency
,conversion rate optimization strategies
,cro agency
,ecommerce cro agency
,conversion rate optimization services
,conversion rate optimization services pricing
,ecommerce conversion rate optimization"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box-custom">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">
                    Conversion Rate Optimization
                  </p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want to raise the number of people who complete the
                    desired action on a website? We are here to please you only
                    by speed up your page’s Conversion Rate Optimization
                    professionally.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="img-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Conversion Rate Optimization
                  Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  The technique of raising the percentage of users or website
                  visitors that do the desired action is known as conversion
                  rate optimization. Conversion rate optimization (CRO) is the
                  practice of improving the likelihood of a visitor doing
                  desired actions (conversions) on a website or landing page by
                  optimizing the site or landing page experience based on
                  website visitor behavior. If your traffic isn't turning into
                  paying customers, sales, or clients, you're wasting your time.
                  Conversion rate optimization is an essential aspect of digital
                  marketing that we at Wetech Digital are well-versed in. Though
                  a top CRO agency, our CRO pricing is entirely open, so you
                  always know where your money is going and how it is helping
                  your site generate income. Our expert optimization team makes
                  possible a good conversion rate optimization for you. We also
                  knew as an E-commerce conversion rate optimization agency. We
                  provide a high-level E-commerce conversion rate optimization.
                  As an E-commerce CRO agency, our success rate is desirable.
                  You can try our conversion rate optimization services.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Conversion Rate Optimization
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital has tactful conversion rate optimization
                  strategies. Every homepage has its own set of objectives. A
                  lead generation website, an eCommerce store that sells things,
                  and a blog that transforms visitors into subscribers are
                  examples of industrial websites. Unfortunately, many website
                  owners don't pay as much attention to how effectively they
                  meet those objectives as they should, and many marketers
                  define success in terms of traffic and rankings rather than
                  conversions. CRO (conversion rate optimization) has become
                  highly significant since it allows you to determine what isn't
                  working, why it isn't working, and how to solve it.
                  Identifying "leaks" in your sales funnel can significantly
                  influence the performance of your website. The sales funnel
                  refers to the complete sales process, from generating interest
                  to affecting potential consumers that they need your product
                  or service to eventually sealing the transaction. Identifying
                  "leaks" in this funnel can have a significant influence on the
                  performance of your website. We take care of them.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Development Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Identify potential clients</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Survey & Analysis</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">A/B testing</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Test A/B again</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Development</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Want conversion Rate optimization?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">We do usability analysis.</li>
                  <li className="page-six-li">
                    We do a conversion analysis on ‘internet marketing
                  </li>
                  <li className="page-six-li">Conversion funnel analysis</li>
                  <li className="page-six-li">
                    Provide an affordable conversion rate optimization services
                    pricing
                  </li>
                  <li className="page-six-li">Website copy analysis</li>
                  <li className="page-six-li">Aesthetic analysis</li>
                  <li className="page-six-li">
                    Solving your problems steadily
                  </li>
                  <li className="page-six-li">Again testing the results</li>
                  <li className="page-six-li">
                    Delivery to you after we are satisfied
                  </li>
                  <li className="page-six-li">
                    Will work on it again if you are still not satisfied.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We convert optimization into profitable solutions.
                  </li>
                  <li className="page-six-li">
                    We increase revenue with a custom CRO package
                  </li>
                  <li className="page-six-li">Result-oriented outcomes</li>
                  <li className="page-six-li">
                    Fulfill requirements in the best manner
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Conversion Rate Optimization At The Right Price
              </p>
            </div>
            <div className="pricing-main">
              <div className="pricing-table-section">
                <div className="table-header">
                  <div className="feature-section">
                    <h2 className="table-title">Features</h2>
                  </div>
                  <div className="silver-section">
                    <h2 className="table-title">Silver</h2>
                  </div>
                  <div className="gold-section">
                    <h2 className="table-title">Gold</h2>
                  </div>
                  <div className="platinum-section">
                    <h2 className="table-title">Platinum</h2>
                  </div>
                </div>

                <div className="table-content1">
                  <div className="feature">
                    <p></p>
                  </div>

                  <div className="silver">
                    <p>$600/month</p>
                  </div>
                  <div className="gold">
                    <p>$1250/month</p>
                  </div>
                  <div className="platinum">
                    <p>$2125/month</p>
                  </div>
                </div>
                {data.map((data) => (
                  <div className="table-content1">
                    <div className="feature">
                      <p>{data.name}</p>
                    </div>

                    <div className="silver">
                      {data.silver === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.silver}</p>
                      )}
                    </div>
                    <div className="gold">
                      {data.gold === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.gold}</p>
                      )}
                    </div>
                    <div className="platinum">
                      {data.platinum === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.platinum}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default ConversionRateOptimization;
