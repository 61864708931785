import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

function PortfolioDetails() {
  const myRef = useRef(null);
  // const secId = JSON.parse(localStorage.getItem("weTSectionId"));
  const [section, setSection] = useState({});
  const [allPortfolioData, setPortfolioData] = useState([]);
  const [noPortfolio, setNoPortfolio] = useState(false);
  const { customUrl } = useParams();
  // console.log(section);
  // console.log(allPortfolioData);
  // console.log(secId);

  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  const getAllSectionList = async () => {
    try {
      const dataFetched = await import("../../../utils/services/portfolio.service").then(
        async (service) => await service.getSection({})
      );
      if (dataFetched) {
        if (dataFetched.data.length > 0) {
          const sectionData = dataFetched.data.find(data => data.customUrl === customUrl);
          setSection(sectionData);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  const getPortfolioList = async () => {
    try {
      const dataFetched = await import("../../../utils/services/portfolio.service").then(
        async (service) => await service.getPortfolio({})
      );
      // console.log(dataFetched);
      if (dataFetched) {
        if (dataFetched.data.length > 0) {
          const portfolioData = dataFetched.data.filter(data => data.section.customUrl === customUrl);
          setPortfolioData(portfolioData);
          if (portfolioData.length > 0) {
            setNoPortfolio(false);
          } else {
            setNoPortfolio(true);
          }

        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllSectionList();
    getPortfolioList();
  }, [])

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            {`${section?.customTitle ? section?.customTitle : ""} | WeTech`}

          </title>
          <meta
            name="description"
            content="Pay Per Click Portfolio | WeTech"
          ></meta>
          <meta
            name="keywords"
            content="web design portfolio"
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box pb-5 pb-md-0">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">{section?.name} </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    {
                      section?.metaDescription
                    }
                  </p>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={section?.file} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          {
            (allPortfolioData.length > 0) && (noPortfolio === false) ? (
              allPortfolioData.map((data, i) =>
                (i % 2 === 0) ? (
                  <div className="services-page-two" ref={myRef}>
                    <div className="services-page-two-left">
                      <div className="page-two-header">
                        <span className="header">
                          <p className="mb-0">{section?.name}</p>
                          <span
                            style={{
                              borderTop: "1px solid white",
                              width: "60px",
                              marginLeft: "5px",
                              marginTop: "12px",
                            }}
                          ></span>
                        </span>
                      </div>
                      <div className="page-two-title">
                        <p className="title">
                          {data?.title}
                        </p>
                      </div>
                      <div className="page-two-paragraph">
                        <p className="paragraph">
                          {data?.description}
                        </p>
                        <div className="row mt-5">
                          <div className="col-6">
                            <h4 className="percentage">{data?.cri}{data.cri !== undefined && "%"}</h4>
                            <span className="text-light">Conversion Rate <br /> Increase</span>
                          </div>
                          <div className="col-6">
                            <h4 className="percentage">{data?.cpl}{data.cpl !== undefined && "%"}</h4>
                            <span className="text-light">Cost Per Lead Improvement</span>
                          </div>
                        </div>
                        <div className="box-one-button">
                          <button className="services-button" >
                            <a
                              href={data?.link}
                              rel="nofollow"
                              target="_blank" className="text-light">See website</a><span>{">"}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="services-page-two-right p-5 portfolio-bg">
                      <img className="image-1" src={data?.file} alt="" />
                    </div>
                  </div>
                ) : (
                  <div className="services-page-three">
                    <div className="services-page-three-left p-5 portfolio-bg">
                      <img className="image-1" src={data?.file} alt="" />
                    </div>
                    <div className="services-page-three-right">
                      <div className="page-three-header">
                        <span className="header">
                          <p className="mb-0">{section?.name}</p>
                          <span
                            style={{
                              borderTop: "1px solid white",
                              width: "60px",
                              marginLeft: "5px",
                              marginTop: "12px",
                            }}
                          ></span>
                        </span>
                      </div>
                      <div className="page-three-title">
                        <p className="title">{data?.title}</p>
                      </div>
                      <div className="page-two-paragraph">
                        <p className="paragraph">
                          {data?.description}
                        </p>
                        <div className="row mt-5">
                          <div className="col-6">
                            <h4 className="percentage">{data?.cri}{data.cri !== undefined && "%"}</h4>
                            <span className="text-light">Conversion Rate <br /> Increase</span>
                          </div>
                          <div className="col-6">
                            <h4 className="percentage">{data?.cpl}{data.cpl !== undefined && "%"}</h4>
                            <span className="text-light">Cost Per Lead Improvement</span>
                          </div>
                        </div>
                        <div className="box-one-button">
                          <button className="services-button" >
                            <a
                              href={data?.link}
                              rel="nofollow"
                              target="_blank" className="text-light">See website</a> <span>{">"}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (allPortfolioData.length === 0) && (noPortfolio === true) ? (
              <div className="mb-5">
                <h1 className="text-center mb-5 text-muted">There is no portfolio details!</h1>
              </div>
            ) : null

          }

        </div>
      </div>
    </div>
  );
}

export default PortfolioDetails;
