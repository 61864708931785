import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import ContactWetech from "../../blocks/ContactWetech";
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
// import MainContent from "../../../assets/images/3d-video-production.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/3dProductVisualization/1.1baded71212857 1.png";
import image2 from "../../../assets/images/3dProductVisualization/2.manager-leading-brainstorming-meeting 1 (4).png";
import image3 from "../../../assets/images/3dProductVisualization/3.business-man-holding-paper-with-why-choose-us-question_23-2148932314 1 (4).png";
import image4 from "../../../assets/images/3dProductVisualization/4.workers-pointing-increase-sales 2 (4).png";
import image5 from "../../../assets/images/3dProductVisualization/5.pensive-woman-touching-chin-and-thinking-about-imp-2022-01-04-20-21-16-utc 1.png";
import image6 from "../../../assets/images/3dProductVisualization/6.businesspeople-celebrating-success 2 (4).png";
import { Link } from "react-router-dom";

function ThreeDProductVisualization() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>WeTech - 3D Product Visualization</title>
          <meta
            name="description"
            content="Get 3D Product Visualization service to represent your product in a more attractive way. As a creative Product Animation & Visualization company, we serve worldwide, check our affordable packages now. "
          ></meta>
          <meta
            name="keywords"
            content="
          3d product visualization
          ,3d product visualization companies
          ,3d product visualization services
          ,3d product animation services
          ,3d product animation cost
          ,3d product animation company
          ,product animation services
          
          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">
                    3D Product Visualization
                  </p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want high-quality 3D product visualization services?
                    Then we will offer you, professionally, exactly what you
                    need.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top 3D Product Visualization Services
                  Company?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top 3D Product Visualization Services
                  Company in the market. The representation of 3D products is
                  just the development of 3D product designs. Three-dimensional
                  design offers captivating images that display items far more
                  impressively and thoroughly than a basic description of a
                  written product. Visualization of 3D products is an art–and
                  not only the design of the product! 3D product visualization
                  helps online retailers to enhance conversions, minimize
                  returns, extend their product range and improve consumer
                  experience, from real-time product modification to increased
                  real-life. Product visualization services of Wetech Digital
                  are very good. We also provide 3D product animation services.
                  The visualization of products, sometimes called the rendering
                  of products, is a technique enabling rapid and realistic
                  visual representation of items or architecture. When a
                  product, programme or concept is still in its earliest stage,
                  3D rendering and viewing may be utilized to generate
                  attractive pictures to communicate the storyline. Our services
                  like product animation services are very popular among
                  customers. Wetech Digital is one of the best 3d product
                  visualization companies.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to 3D Product Visualization Services
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  Wetech Digital is dedicated to providing clients with
                  interesting, relevant, and effective content. Working on that
                  purpose, our product visualization services are most reliable
                  with affordable 3D product animation cost. As a 3D product
                  animation company, we offer a wide range of 3D product
                  animation services. We provide cost effective product
                  animation services. There are many product visualization
                  companies but you can blindly rely on us for his service.
                  Wetech Digital is the best 3d product animation company on the
                  market.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Development Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Collect needed contents</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Select best hardware & software
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">3D Imagery </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">High quality 3D rendering use</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require 3D Product Visualization Services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We always balancing customer requirements.
                  </li>
                  <li className="page-six-li">
                    Help you understand the target industry.
                  </li>
                  <li className="page-six-li">
                    We deliver more efficient work.
                  </li>
                  <li className="page-six-li">
                    We offer cost-effective services.
                  </li>
                  <li className="page-six-li">
                    For various platforms, we arrange different services.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">We focused on rich quality.</li>
                  <li className="page-six-li">
                    We provide adaptable and personalized services.
                  </li>
                  <li className="page-six-li">
                    Designing custom content with high technology.
                  </li>
                  <li className="page-six-li">
                    We consider customer preference.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Web Video 3D Product Visualization Service At The
                Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default ThreeDProductVisualization;
