import React from "react";
import "../../../../assets/css/pricing.css";
import jam from "../../../../assets/images/jamCheck.png";

function PricingTable() {
  return (
    <div className="pricing-main">
      <div className="pricing-table-section">
        <div className="table-header">
          <div className="feature-section">
            <h2 className="table-title">Features</h2>
          </div>
          <div className="silver-section">
            <h2 className="table-title">Silver</h2>
          </div>
          <div className="gold-section">
            <h2 className="table-title">Gold</h2>
          </div>
          <div className="platinum-section">
            <h2 className="table-title">Platinum</h2>
          </div>
        </div>
        <div className="table-content1">
          <div className="feature">
            <p>{/* Number of keyphrases optimized */}</p>
          </div>
          <div className="silver">
            <p>$600</p>
          </div>
          <div className="gold">
            <p>$1200</p>
          </div>
          <div className="platinum">
            <p>$1600</p>
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Number of keyphrases optimized</p>
          </div>
          <div className="silver">
            <p>Up to 50</p>
          </div>
          <div className="gold">
            <p>Up to 100</p>
          </div>
          <div className="platinum">
            <p>Up to 150</p>
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Pages Optimized</p>
          </div>
          <div className="silver">
            <p>Up to 15</p>
          </div>
          <div className="gold">
            <p>Up to 25</p>
          </div>
          <div className="platinum">
            <p>Up to 35</p>
          </div>
        </div>
        <div className="table-content">
          <div className="feature">
            <p>Technical Website Analysis & Opportunity Report</p>
          </div>
          <div className="silver">
            <img src={jam} alt="" />
          </div>
          <div className="gold">
            <img src={jam} alt="" />
          </div>
          <div className="platinum">
            <img src={jam} alt="" />
          </div>
        </div>

        <div className="pricing-btn-section">
          <div className="button-section">
            <div className="silver-button">
              <div className="button-header">
                <p className="button-content">
                  Tk 300 <span className="btn-content-span">/mon</span>
                </p>
              </div>
              <div className="buttons">
                <button className="pricing-btn-silver">Select</button>
              </div>
            </div>
            <div className="gold-button">
              <div className="button-header">
                <p className="button-content">
                  Tk 300 <span className="btn-content-span">/mon</span>
                </p>
              </div>
              <div className="buttons">
                <button className="pricing-btn-gold">Select</button>
              </div>
            </div>
            <div className="platinum-button">
              <div className="button-header">
                <p className="button-content">
                  Tk 300 <span className="btn-content-span">/mon</span>
                </p>
              </div>
              <div className="buttons">
                <button className="pricing-btn-platinum">Select</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingTable;
