import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
//import MainContent from "../../../assets/images/web-video-production.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/webVideoProduction/1.3-1 1.png";
import image2 from "../../../assets/images/webVideoProduction/2.video-maker-editing-movie-using-post-production-software-working-creative-start-up-agency-office 1.png";
import image3 from "../../../assets/images/webVideoProduction/3.png";
import image4 from "../../../assets/images/webVideoProduction/4.index-finger-touching-purple-arrow 1.png";
import image5 from "../../../assets/images/webVideoProduction/5.close-up-portrait-uncertain-indecisive-young-man-with-bristle-shrugging-make-confused-face-dont-know-why 1.png";
import image6 from "../../../assets/images/webVideoProduction/6.impressed-surprised-man-points-away-blank-space 1.png";
import { Link } from "react-router-dom";

function WebVideoProductionService() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Affordable customized web video production services | WeTech
          </title>
          <meta
            name="description"
            content="Get attractive web videos to more engagement with your Website audiences. WeTech Digital provides online video production services at affordable prices."></meta>
          <meta
            name="keywords"
            content="
          web video production services
,video marketing agency
,online video production service
,corporate video production company
,creative video agency
,video marketing services
,online video marketing services
,internet marketing service providers


          
          "></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    Web Video Production Services
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want well to build up web video production services?
                    Then we are here to please you providing that
                    professionally.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span> </span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Web Video Production Service
                  Company?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top video marketing agency in the
                  market, providing professional online films that are engaging,
                  interesting, and successful in gaining viewers' confidence.
                  WeTech Digital understands the financial effect that a single,
                  well-shot video can have. Our pricing for custom video
                  creation services is completely open, so you always know at
                  which your money is going. Our web video production services
                  are very affordable. As a creative video agency, we have a
                  good reputation among clients. It has been proven many times
                  again that firms that offer video creation services are seen
                  as more trustworthy by their consumers. Don't lose out on the
                  chance to showcase your business to clients in the method in
                  which the vast majority of customers seek information. So you
                  can contact us for an online video production service
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Web Video Production Service
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital is dedicated to providing clients with
                  interesting, relevant, and effective content. What finer
                  approach to achieve this than by using web videos? Our product
                  portfolio videos put your message, services, and products in
                  the spotlight. A professionally written screenplay and
                  recorded narration are used in video commercials to tell a
                  tale. A current customer's video testimonial is an excellent
                  approach to create credibility. Online video marketing
                  services have a favorable influence on 82 percent of
                  marketers' businesses, organizations, or institutions. Print
                  and direct mail advertising are 600% less successful than
                  video marketing. If you're ready to engage your audience like
                  never before, it's time to consider using video to tell your
                  story. That’s why we are here to provide video marketing
                  services at reasonable prices
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Develop strategy</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Content script & editing</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Storyboarding & shoots, voice-overs
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">3D, 4D & motion graphics</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Customized requirements</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require web video production services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us.</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Professional whiteboard films are available to assist you in
                    engaging and converting more consumers.
                  </li>
                  <li className="page-six-li">
                    We are a corporate video production company
                  </li>
                  <li className="page-six-li">
                    We provide affordable services cost.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platforms.
                  </li>
                  <li className="page-six-li">
                    Provide social media postings that are unique.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    As internet marketing service providers, we take care of
                    your image in the market field.
                  </li>
                  <li className="page-six-li">
                    Designing custom content with high technology.
                  </li>
                  <li className="page-six-li">
                    We offer social media network establishment, optimization,
                    and identity auditing services.
                  </li>
                  <li className="page-six-li">
                    We offer brand reputation analysis, improved post-ad
                    management, and more.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Web Video Production Services At The Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default WebVideoProductionService;
