import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import image0 from "../../../assets/images/portfolio/6.design-software-resize-icon-concept 1.png";
import image2 from "../../../assets/images/portfolio/explaining-strategy 1.png";
import image3 from "../../../assets/images/portfolio/Mask.png";

function CreativeDesignPortfolio() {

  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            {" "}
            Creative Design Portfolio | WeTech
          </title>
          <meta
            name="description"
            content="Creative Design Portfolio | WeTech"
          ></meta>
          <meta
            name="keywords"
            content="web design portfolio"
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box pb-5 pb-md-0">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">Creative Design <br /> Portfolio</h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                  Do you want well to build up Competitor Analysis services? Then we are here to please you providing that professionally.
                  </p>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image0} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Social Media Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                Pixel Space Development
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/W5djc2C/land-wonted-1.png" alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-4">
               <div className="secondary-clr">
                   <h3 className="fw-bold">People Reviews <br /> Of Wetech Digital</h3>
                   <p className="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis tortor posuere a velit pharetra sit id felis. sit amet, consectetur adipiscing elit</p>
               </div>
            </div>
            <div className="services-page-three-right p-5 primary-text-clr">
                
                <div>
                    <div>
                      <img className="img-fluid" src={image2} alt="" />
                    </div>
                   <div className="d-flex mt-4">
                      <div>
                        <img src={image3} style={{width: "60px", marginTop: "10px"}} alt="" />
                      </div>
                      <div className="mx-3">
                         <p>Meg Rigden <br /> <span >Type something</span></p>
                          
                      </div>
                   </div> 
                </div>
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/LdZW3vk/pion-1.png" alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Social Media Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Peon</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Social Media Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                Pixel Space Development
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/W5djc2C/land-wonted-1.png" alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/LdZW3vk/pion-1.png" alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Social Media Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Peon</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Social Media Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                GONTOBBO XPRESS
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/HB2j1xS/258467740-293396902659383-242713258579826168-n-1.png" alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/dmF01CG/premium-green-1.png" alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Logo Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Premium Green Coco</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Social Media Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                DD Travels
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/pwMXKJT/DD-Travel-static-1.png" alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/Z8Sy9cV/Pure-Home-Logo-1.png" alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Logo Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Pure Home</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Logo Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                Santa Eats
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/0rNDRWK/Santa-Easts-logo-365-04-1.png" alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/dm5nHvK/Badhon-tiles-main-logo-1-1.png" alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Logo & Social Media Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Badhon Tiles & Sanitary</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Logo Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                NABAKATHAN
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/QmZSwMP/nobokothon-1.png" alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/ZYhwMbp/Unique-Fashion-1-1.png" alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Logo Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">UNIQUE Fashion</p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Logo Design</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                Premium Dairy
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                    Wetech Digital is the top Competitor Analysis services company in the market. Every day,
                </p>
                <div className="row mt-5">
                    <div className="col-6">
                        <h4 className="percentage">73%</h4>
                        <span className="text-light">Conversion Rate <br /> Increase</span> 
                    </div>
                    <div className="col-6">
                        <h4 className="percentage">23%</h4>
                        <span  className="text-light">Cost Per Lead Improvement</span> 
                    </div>
                </div>
                <div className="box-one-button">
                  <button className="services-button" >
                    <span>See website</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="services-page-two-right p-5 portfolio-bg">
              <img className="image-1" src="https://i.ibb.co/k51F9pt/premium-1.png" alt="" />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default CreativeDesignPortfolio;
