import React from "react";
import "../assets/css/mega.css";
import menu from "../assets/logos/menubar.png";
import close from "../assets/logos/close.png";

function MegaMenuTest() {
  return (
    <nav className="sticky">
      <div className="wrapper">
        <input type="radio" name="slider" id="menu-btn" />
        <input type="radio" name="slider" id="close-btn" />
        <ul className="nav-links">
          <label htmlFor="close-btn" className="btn close-btn">
            <img
              style={{ color: "white" }}
              className="img-fluid"
              src={close}
              alt=""
            />
          </label>
          <li className="nav-link-li">
            <a href="/" className="desktop-item">
              SEO & SALES CONVERSION
            </a>
            <input type="checkbox" id="showMega1" />
            <label htmlFor="showMega1" className="mobile-item">
              SEO & SALES CONVERSION
            </label>
            <div className="mega-box dropdown-menu" id="megaBox-1">
              <div className="content">
                <div className="row bg-primary-1 seo1_bg">
                  <header>Organic Search Pricing</header>
                  <ul className="mega-links">
                    <li>
                      <a className="dropdown-item" href="/seo-service">
                        SEO Services
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/eCommerce-seo">
                        E-Commerce SEO
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/local-seo">
                        Local SEO Services
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/seo-audit">
                        SEO Audit
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/link-building-service"
                      >
                        Link Building Services
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/app-store-optimization"
                      >
                        App Store Optimization
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row bg-primary-2 seo2_bg">
                  <header>Paid Marketing Pricing</header>
                  <ul className="mega-links">
                    <li>
                      <a className="dropdown-item" href="/ppc-management">
                        PPC Management
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/eCommerce-ppc-service"
                      >
                        E-Commerce PPC Services
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/display-ads-management"
                      >
                        Display Ads Management
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/local-search-ads-management"
                      >
                        Local Search Ads Management
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row bg-primary-3 seo3_bg">
                  <header>Market Place Pricing</header>
                  <ul className="mega-links">
                    <li>
                      <a
                        className="dropdown-item"
                        href="/amazon-seo-and-production"
                      >
                        Amazon SEO & Product Optimization
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/amazon-ads-management"
                      >
                        Amazon Advertising Management
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/facebook-market-price-for-business"
                      >
                        Facebook Marketplace for Business
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/shopify-optimization-service"
                      >
                        Shopify Optimization Services
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/shopify-ads-management"
                      >
                        Shopify Advertising Management
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li className="nav-link-li">
            <a href="/" className="desktop-item">
              SOCIAL & CREATIVE CONTENT
            </a>
            <input type="checkbox" id="showMega2" />
            <label htmlFor="showMega2" className="mobile-item">
              SOCIAL & CREATIVE CONTENT
            </label>
            <div className="mega-box dropdown-menu" id="megaBox-2">
              <div className="content">
                <div className="row bg-primary-1 social_bg">
                  <header>Social Media Pricing</header>
                  <ul className="mega-links">
                    <li>
                      <a
                        className="dropdown-item"
                        href="/social-media-management-service"
                      >
                        Social Media Management Services
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/social-media-design">
                        Social Media Design
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/social-media-advertising"
                      >
                        Social Media Advertising
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/eCommerce-social-ads-service"
                      >
                        E-Commerce Social Advertising Services
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/youtube-ads-service">
                        YouTube Advertising Services
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row bg-primary-2 web_bg">
                  <header>Web Content Pricing</header>
                  <ul className="mega-links">
                    <li>
                      <a
                        className="dropdown-item"
                        href="/website-seo-copyWrite"
                      >
                        Website SEO Copywriting
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/content-marketing-service"
                      >
                        Content Marketing Services
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/personalized-web-content"
                      >
                        Personalized Web Content
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/web-video-production-service"
                      >
                        Web Video Production Services
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/product-photography-service"
                      >
                        Product Photography Services
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row bg-primary-3 video_bg">
                  <header>Video & Animation</header>
                  <ul className="mega-links">
                    <li>
                      <a
                        className="dropdown-item"
                        href="/promo-video-production"
                      >
                        Promo Video Production
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/infographics-and-motiongraphics"
                      >
                        Infographics & Motion Graphics{" "}
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/animation-video">
                        Animation Video (2D&3D)
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/character-animation">
                        Character Animation
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/3D-Product-Visualization"
                      >
                        3D Product Visualization
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li className="nav-link-li">
            <a href="/" className="desktop-item">
              RESEARCH & OPTIMIZATION
            </a>
            <input type="checkbox" id="showMega3" />
            <label htmlFor="showMega3" className="mobile-item">
              RESEARCH & OPTIMIZATION
            </label>
            <div className="mega-box dropdown-menu" id="megaBox-3">
              <div className="content">
                <div className="row bg-primary-1 analysis_bg">
                  <header>Analysis</header>
                  <ul className="mega-links">
                    <li>
                      <a className="dropdown-item" href="/crm-roi">
                        CRM ROI
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/lead-management-solution"
                      >
                        Lead Management Solution
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/custom-seo-reporting">
                        Custom SEO Reporting
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row bg-primary-2 optimization_bg">
                  <header>Optimization</header>
                  <ul className="mega-links">
                    <li>
                      <a
                        className="dropdown-item"
                        href="/conversion-rate-optimization"
                      >
                        Conversion Rate Optimization
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/page-speed-optimization"
                      >
                        Page Speed Optimization
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li className="nav-link-li">
            <a href="/" className="desktop-item">
              DESIGN & DEVELOPMENT
            </a>
            <input type="checkbox" id="showMega4" />
            <label htmlFor="showMega4" className="mobile-item">
              DESIGN & DEVELOPMENT
            </label>
            <div className="mega-box dropdown-menu" id="megaBox-4">
              <div className="content">
                <div className="row bg-primary-2 design_bg">
                  <header>Design Pricing</header>
                  <ul className="mega-links">
                    <li>
                      <a className="dropdown-item" href="/ux-website-design">
                        UX Website Design
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/user-experience-testing"
                      >
                        User Experience Testing
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/landing-page-design">
                        Landing Page Design
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row bg-primary-1 development_bg">
                  <header>Development Pricing</header>
                  <ul className="mega-links">
                    <li>
                      <a className="dropdown-item" href="/website-development">
                        Website Development
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/eCommerce-development"
                      >
                        E-Commerce Development
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/portfolio-site">
                        Portfolio Site
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/mobile-application">
                        Mobile Application
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/shopify-store-development"
                      >
                        Shopify Store Development
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/website-maintenance">
                        Website Maintenance
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/content-management-system"
                      >
                        Content Management System
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li className="nav-link-li">
            <a href="/" className="desktop-item">
              BRANDING & BUSINESS SOLUTION
            </a>
            <input type="checkbox" id="showMega5" />
            <label htmlFor="showMega5" className="mobile-item">
              BRANDING & BUSINESS SOLUTION
            </label>
            <div className="mega-box dropdown-menu" id="megaBox-5">
              <div className="content">
                <div className="row bg-primary-3 strategy_bg">
                  <header>Branding Strategy</header>
                  <ul className="mega-links">
                    <li>
                      <a className="dropdown-item" href="/brand-identity">
                        Brand Identity
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/business-development-strategy"
                      >
                        Business Development Strategy{" "}
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/market-research">
                        Market Research
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/competitor-analysis">
                        Competitor Analysis
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/community-management">
                        Community Management
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row bg-primary-1 awareness_bg">
                  <header>Brand Awareness</header>
                  <ul className="mega-links">
                    <li>
                      <a className="dropdown-item" href="/tvc-marketing">
                        TVC Marketing
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/ovc-marketing">
                        OVC Marketing
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/influence-marketing">
                        Influencer Marketing
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/newspaper-advertisement"
                      >
                        Newspaper/Portal Ads
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row bg-primary-2 legalDoc_bg">
                  <header>Legal Documentation</header>
                  <ul className="mega-links">
                    <li>
                      <a
                        className="dropdown-item"
                        href="/business-registration"
                      >
                        Business Registration
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/licence-and-certification"
                      >
                        License & Certification
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/trade-mark-application"
                      >
                        Trade Mark Application Service
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/partnership-deed">
                        Partnership Deed
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li className="nav-link-li">
            <a href="/" className="desktop-item">
              WHO WE ARE
            </a>
            <input type="checkbox" id="showMega6" />
            <label htmlFor="showMega6" className="mobile-item">
              WHO WE ARE
            </label>
            <div className="mega-box dropdown-menu" id="megaBox-6">
              <div className="content">
                <div className="row bg-primary-1 company_bg_img">
                  <header>Company</header>
                  <ul className="mega-links">
                    <li>
                      <a className="dropdown-item" href="/about">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/contact">
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/career">
                        Career
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row bg-primary-2 resource_bg_img">
                  <header>Resource</header>
                  <ul className="mega-links">
                    <li>
                      <a className="dropdown-item" href="/blog">
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/portfolio"
                      >
                        Our Portfolio
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <label htmlFor="menu-btn" className="btn menu-btn">
          <img className="img-fluid" src={menu} alt="" />
        </label>
      </div>
    </nav>
  );
}

export default MegaMenuTest;

// function HideMegaBox() {
//   var x = document.getElementById("megaBox-1");
//   if ((x.style.visibility = "visible")) {
//     x.style.visibility = "hidden";
//   }
// }
