import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../assets/css/home.css";
//import Swal from "sweetalert2";
// Photos
import man from "../assets/images/smileMan.png";
import home1 from "../assets/images/rsz-home1.png";
import home2 from "../assets/images/businessman-draw-growing-line-symbolize-growing-company-sales 1.webp";
import home3 from "../assets/images/rsz-home3.png";
import home4 from "../assets/images/rsz-home4.png";
import home5 from "../assets/images/rsz-home5.png";
import home6 from "../assets/images/rsz-home6.png";
import { toast } from "react-toastify";
import { GlobalStateContext } from "../../store/store";

function Home() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const context = useContext(GlobalStateContext);

  const { setGlobalState } = context;

  const { name, email, phone, message } = formData;

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newUser = {
      name,
      email,
      phone,
      message,
    };
    try {
      const dataInserted = await import("../utils/services/form.service").then(
        async (service) => await service.postContactForm({ data: newUser })
      );
      if (dataInserted?.status === 201 || dataInserted?.status === 200) {
        toast.success('Successfully sent.', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      console.error(err.response.data);
    }
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  useEffect(() => {
    setGlobalState((prevState) => ({
      ...prevState,
      activeRoute: "",
    }));
  }, []);

  return (
    <div>
      <div>
        <div>
          <Helmet htmlAttributes>
            <title> WeTech Digital  </title>
            <meta property="og:title" content="Wisdom International School" />
            <meta name="description" content="Basic content"></meta>
            <meta name="keywords" content=""></meta>
          </Helmet>
        </div>
        <div className="home-main-container">
          <div className="home-first-section">
            <div className="first-section-header">
              <h1 className="first-section-title">
                A Trusted{" "}
                <span style={{ color: "#0099DD", fontFamily: "Lora, serif" }}>
                  Digital Marketing Agency
                </span>{" "}
                To Help Your Business Reach The Ultimate Goal
              </h1>
            </div>
            <div className="first-section-content">
              <div className="home-contact-form-section">
                <form action="" className="home-contact-form">
                  <p className="home-contact-title">For Free Consultancy</p>
                  <div className="home-contact-input-section">
                    <input
                      className="home-contact-input"
                      type="text"
                      placeholder="Full Name"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                    />
                    <input
                      className="home-contact-input"
                      type="text"
                      placeholder="Email Address"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                    />
                    <input
                      className="home-contact-input"
                      type="text"
                      placeholder="Phone Number"
                      name="phone"
                      id="phone_number"
                      value={phone}
                      onChange={handleChange}
                    />
                    <textarea
                      className="home-contact-msg-input"
                      rows="2"
                      cols="40"
                      type="text"
                      placeholder="Your Message"
                      id="message"
                      name="message"
                      value={message}
                      onChange={handleChange}
                    />

                    <button
                      className="home-contact-button"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
              <div className="first-section-image-container">
                <img className="first-section-image" src={home1} alt="" />
                <div className="overlay-image-section">
                  <img className="overlay-image" src={man} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="home-second-section">
            <div className="second-section-header">
              <h1 className="first-section-title">Why WeTech Digital</h1>
            </div>
            <div className="second-section-content">
              <div className="second-section-content-left">
                <span className="second-section-content-p1">
                  <p className="mb-0">Why WeTech Digital? </p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "2px",
                    }}
                  ></span>
                </span>

                <p className="second-section-content-p2">
                  Stay Top & Increase Sales
                </p>
                <p className="second-section-content-p3">
                  In order to increase the visitors and sales of a Website, it
                  is important to stay on top. If you want, you can bring free
                  traffic through Organic SEO, or you can increase sales through
                  paid traffic through PPC Campaigns.
                </p>
              </div>
              <div className="second-section-content-right">
                <img className="first-section-image" src={home2} alt="" />
              </div>
            </div>
          </div>
          <div className="home-third-section">
            <div className="third-section-content">
              <div className="third-section-content-left">
                <span className="third-section-content-p1">
                  <p className="mb-0">Why WeTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "2px",
                    }}
                  ></span>
                </span>
                <p className="third-section-content-p2">Engage and Entertain</p>
                <p className="third-section-content-p3">
                  Marketing in social media is all about connecting with
                  audiences. More than 3 billion people worldwide use social
                  media regularly. This is a great opportunity to grow your
                  brand. WeTech will make your Brand unique on Social Platform.
                </p>
              </div>
              <div className="third-section-content-right">
                <img className="first-section-image" src={home3} alt="" />
              </div>
            </div>
          </div>
          <div className="home-fourth-section">
            <div className="second-section-content">
              <div className="second-section-content-left">
                <span className="third-section-content-p1">
                  <p className="mb-0">Why WeTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "2px",
                    }}
                  ></span>
                </span>
                <p className="third-section-content-p2">
                  Get the best ROI possible
                </p>
                <p className="third-section-content-p3">
                  There is a need for data to stay ahead of the competition in
                  business in today's world. The more information and data you
                  have, the more you will survive in the market. We will help
                  you get the highest return on investment by doing research on
                  your brand and your competitors.
                </p>
              </div>
              <div className="second-section-content-right">
                <img className="first-section-image" src={home4} alt="" />
              </div>
            </div>
          </div>
          <div className="home-third-section">
            <div className="third-section-content">
              <div className="third-section-content-left">
                <span className="third-section-content-p1">
                  <p className="mb-0">Why WeTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "2px",
                    }}
                  ></span>
                </span>
                <p className="third-section-content-p2">
                  For a better virtual experience
                </p>
                <p className="third-section-content-p3">
                  The needs of the audience are changing with the times. To be
                  in the best position here, you have to compete to win the
                  visitors' hearts with a good quality website. Continue with
                  WeTech Digital to get an experience of the best design and
                  fastest website ever.
                </p>
              </div>
              <div className="third-section-content-right">
                <img className="first-section-image" src={home5} alt="" />
              </div>
            </div>
          </div>

          <div className="home-fourth-section">
            <div className="second-section-content">
              <div className="second-section-content-left">
                <span className="third-section-content-p1">
                  <p className="mb-0">Why WeTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "2px",
                    }}
                  ></span>
                </span>
                <p className="third-section-content-p2">
                  Differentiate the Brand from others
                </p>
                <p className="third-section-content-p3">
                  Branding plays a vital role in new or old companies. To give
                  you the best from your competitors, easily solve unique
                  marketing strategies and other important issues and make a
                  different brand face with WeTech Digital.
                </p>
              </div>
              <div className="second-section-content-right">
                <img className="first-section-image" src={home6} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Home;
