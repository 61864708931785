const data = [
  {
    name: "Words per page",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Keyword Research",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "SEO Copywriting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Professional Editing",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "New Webpage Implementationg",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "SEO Optimization for New Webpage",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Research",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Strategic keyword use in articles & co-citation",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Linking to internal & external content",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Custom Graphics",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Graphics sized for social media",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Webpage Formatting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Promotion",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Total",
    silver: "$125 / page",
    gold: "$375 / page",
    platinum: "$750 / page",
  },
  {
    name: " ",
    silver: " ",
    gold: " ",
    platinum: " ",
  },

  {
    name: "SEO Ecommerce Copywriting Services",
    silver: "SEO Product Description Copywriting",
  },
  {
    name: "Optimized ecommerce product descriptions",
    silver: "",
  },
  {
    name: "~150 words per",
    silver: "",
  },
  {
    name: "Up to 15 min research per",
    silver: "",
  },
  {
    name: "Strategic keyword use in copy",
    silver: "",
  },
  {
    name: "Basic keyword research included",
    silver: "",
  },
  {
    name: "CTA included in website sales copy",
    silver: "",
  },
  {
    name: "Total",
    silver: "$25 / per page",
  },
];
export default data;
