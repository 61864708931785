const data = [
  {
    name: "Fully Responsive Design Across All Devices (Desktop, Mobile, Tablet)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Conversion tracking code setup",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Online project management scheduling",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Dedicated account representative",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Market research",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Conversion strategy",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Conversion best practices documentation",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "2 hours coaching on strategy implementation",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Development of unique selling position",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Landing page design",
    silver: "",
    gold: "Unlimited Iterations",
    platinum: "Unlimited Iterations",
  },
  {
    name: "Landing page programming/build",
    silver: "",
    gold: "1 Iteration",
    platinum: "3 Iteration",
  },
  {
    name: "Multivariate conversion testing",
    silver: "",
    gold: "1 Iteration",
    platinum: "3 Iteration",
  },
  {
    name: "Copywriting",
    silver: "",
    gold: "1 Iteration",
    platinum: "3 Iterations",
  },
  {
    name: "User testing",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Setup of auto responders",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Confirmation/Thank you page",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "PPC campaign review",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Flash/jQuery interactive calls to action",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Conversion path implementation",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Lead forms integrated into CRM",
    silver: "",
    gold: "",
    platinum: "",
  },
  
  {
    name: "Performance test reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Conversion reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Call tracking",
    silver: "Additional $100",
    gold: "Additional $100",
    platinum: "",
  },
  {
    name: "150+ SMEs behind campaign driving results",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Database integration",
    silver: "By Quote",
    gold: "By Quote",
    platinum: "By Quote",
  },
  {
    name: "Video creation & implementation",
    silver: "By Quote",
    gold: "By Quote",
    platinum: "By Quote",
  },
  {
    name: "Time frame",
    silver: "3 weeks",
    gold: "5 weeks",
    platinum: "7 to 10 weeks",
  },
  {
    name: "Investment",
    silver: "$700",
    gold: "$1,200",
    platinum: "$1,600",
  },
];
export default data;
