import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import eCommerce from "../../../assets/images/eCommerce.png";
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import data from "./pricing/EcommerceSeo";
import "../../../assets/css/pricing.css";
import jam from "../../../assets/images/jamCheck.png";
import Testimonial from "../../blocks/Testimonial";
import eComSeoImg2 from "../../../assets/images/ecommerce Seo page 2.webp";
import eComSeoImg3 from "../../../assets/images/ecommerce Seo page 3.webp";
import eComSeoImg4 from "../../../assets/images/ecommerce Seo page 4.webp";
import eComSeoImg6 from "../../../assets/images/ecommerce Seo page 6.webp";
import eComSeoImg7 from "../../../assets/images/ecommerce Seo page 7.webp";
import { Link } from "react-router-dom";

function EcommerceSEO() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            E-Commerce SEO service: rank higher & increase sales | WeTech
          </title>
          <meta
            name="description"
            content="By doing E-commerce SEO, increase your website rank. Generate more traffic & sales with an expert eCommerce SEO team."
          ></meta>
          <meta
            name="keywords"
            content="
        e commerce seo service
        ,e commerce seo services
        ,e commerce seo company
        ,e commerce seo packages
        ,ecommerce seo consultant
        ,ecommerce seo companies
        ,best ecommerce seo companies
        ,ecommerce seo agency
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">E-Commerce SEO</h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Taking your store online e-commerce solutions that work for
                    your business and SEO is one of the most cost-effective and
                    efficient strategies to expand your eCommerce shop and
                    increase sales.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={eCommerce} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top E-Commerce SEO Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital offers dedicated developers and programmers to
                  support your team. Top companies and start-ups choose us to
                  provide the highest quality stress-free e-commerce service &
                  solutions, including SEO service. Our special committee of
                  highly qualified and trained developers is committed to
                  ensuring that your requirements are met. With 100% project
                  delivery success, we deliver E-commerce SEO services to our
                  clients to boost their eCommerce store's rankings.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={eComSeoImg2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={eComSeoImg3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to E-Commerce SEO </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  Our E-Commerce SEO services are based on extensive analysis
                  and followed some steps with quality levels based on prices.
                  We help you to increase your online orders. Your website will
                  rank better in search results for your target audience's terms
                  if you employ our eCommerce SEO services. As an eCommerce SEO
                  company, we provide the best E-commerce SEO consultant to you.
                  They work with search volume, CPC, SEO difficulty, etc., to
                  get the best result. The far more essential phrases you can
                  effectively target, the more qualified customers will click on
                  your items, and as a result, your conversions and income will
                  rise. We believe in providing the best service inside the
                  local and global markets. Always care about your service to be
                  better with our special effort and ensure that you'll get
                  precisely what you want.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Keyword research</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Competitor Analysis</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">On-Page-Optimization</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Off-Page-Optimization</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Detailed Analytics Reporting</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={eComSeoImg4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Let our{" "}
                    <span
                      style={{ color: "#FFD872", fontFamily: "lora, serif" }}
                    >
                      E-Commerce SEO <br /> experts
                    </span>{" "}
                    help your online store through our best works!
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us work together to make it happen
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We provide fully prepared E-Commerce SEO experts to you to
                    help your business expand.
                  </li>
                  <li className="page-six-li">
                    Ensure your website appear in the top search.
                  </li>
                  <li className="page-six-li">
                    We always keep the focus on Robust security.
                  </li>
                  <li className="page-six-li">
                    E-Commerce SEO connects you with high-value shoppers.
                  </li>
                  <li className="page-six-li">
                    We optimize your product line.{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={eComSeoImg6} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={eComSeoImg7} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Custom E-Commerce SEO approaches.
                  </li>
                  <li className="page-six-li">
                    Keyword research and optimization
                  </li>
                  <li className="page-six-li">
                    Increasing your competitive advantage
                  </li>
                  <li className="page-six-li">
                    Increasing your competitive advantage
                  </li>
                  <li className="page-six-li">Product markup</li>
                  <li className="page-six-li">Product SEO Copywrite</li>
                  <li className="page-six-li">
                    Google Analytics setup and Search Console setup
                  </li>
                  <li className="page-six-li">
                    Professional blog or article copywriting
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel Mobile App</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food Delivery App</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">E-Commerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="page-ten-header">
            <p className="page-ten-title">
              Find High-end E-Commerce SEO Service At The Right Price
            </p>
          </div>

          <div className="pricing-main">
            <div className="pricing-table-section">
              <div className="table-header">
                <div className="feature-section">
                  <h2 className="table-title">Features</h2>
                </div>
                <div className="silver-section">
                  <h2 className="table-title">Silver</h2>
                </div>
                <div className="gold-section">
                  <h2 className="table-title">Gold</h2>
                </div>
                <div className="platinum-section">
                  <h2 className="table-title">Platinum</h2>
                </div>
              </div>
              <div className="table-content1">
                <div className="feature">
                  <p></p>
                </div>

                <div className="silver">
                  <p>$800</p>
                </div>
                <div className="gold">
                  <p>$13000</p>
                </div>
                <div className="platinum">
                  <p>$18,00</p>
                </div>
              </div>
              {data.map((data) => (
                <div className="table-content1">
                  <div className="feature">
                    <p>{data.name}</p>
                  </div>

                  <div className="silver">
                    {data.silver === "" ? (
                      <img src={jam} alt="" />
                    ) : (
                      <p>{data.silver}</p>
                    )}
                  </div>
                  <div className="gold">
                    {data.gold === "" ? (
                      <img src={jam} alt="" />
                    ) : (
                      <p>{data.gold}</p>
                    )}
                  </div>
                  <div className="platinum">
                    {data.platinum === "" ? (
                      <img src={jam} alt="" />
                    ) : (
                      <p>{data.platinum}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default EcommerceSEO;
