import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import data from "./pricing/DisplayAds";
import ContactWetech from "../../blocks/ContactWetech";
// photos
import smile from "../../../assets/images/smileMan.png";
import seoService1 from "../../../assets/images/rsz-home6.png";
import seoService2 from "../../../assets/images/rsz-seoservice2.png";
import seoService3 from "../../../assets/images/rsz-home2.png";
import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import display from "../../../assets/images/GDisplay.png";
import jam from "../../../assets/images/jamCheck.png";
import Testimonial from "../../blocks/Testimonial";
import { Link } from "react-router-dom";

function DisplayAdsManagement() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Google display ads: Responsive display ads management agency
          </title>
          <meta
            name="description"
            content="Get Google Display Ads services to reach & show your product/service to the audiences. Check out our responsive google display ads management packages."
          ></meta>
          <meta
            name="keywords"
            content="
          
          ,google display ads
          ,responsive display ads
          ,responsive display ads google
          ,google responsive display ads
          ,display ads management
          ,google display advertising
          ,google display ads campaign


          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">Google Display Ads</h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want to meet too many local leads, maximize traffic
                    flow, and boost maximum conversions for your company? Then
                    you are in the right place to get Google Display Ads.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={display} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As Best Google Display Ads Service Provider??
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top Google responsive display ads
                  agency. Responsive display ads are ads that automatically
                  adjust their size, appearance, and format to fit available ad
                  spaces on the Google Display Network. Responsive display ads
                  Google are well known. Google responsive display Ads help you
                  measure the way your goals are met. See which websites are
                  running your announcement, which advertisements are the most
                  popular and which websites provide the lowest value. They also
                  optimize your inputs automatically for more conversions.
                  Broader reach with customizable dimensions, which helps you
                  save time. Our expert team knows what it takes to build an
                  effective Google advertisement campaign for their customers.
                  We continuously develop premium and scalable strategic ads. It
                  is not a good job to run Google Ads without a historical and
                  strategic review. We carefully evaluate your business, market,
                  and major participants. We always use world-class tools and
                  software to review and analyze the competitor’s ads' to
                  compete with them to add more value for your company.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to Google Display Ads</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  The service of Google display advertising offered by WeTech
                  Digital is very high-notch. Every year, we handle millions of
                  dollars in Google Ads campaign spend, increasing search ad
                  conversions by more than 320%. We operate your Google Ads
                  (previously Google AdWords) account, focusing on the keywords
                  which will bring customers to your website. Precisely focused
                  keyword research will explore one of the most expensive
                  strategies for attracting consumers looking for exactly what
                  you have to sell. Continued investment in Google Ads would
                  certainly assist highly motivated buyers in discovering your
                  website that may not have found it otherwise by traditional
                  (organic) search engine optimization techniques. Our mission
                  is to transform every dollar you spend into several dollars of
                  profit, resulting in a long-lasting and profitable initiative.
                  Our display ads management is very effective and good.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Work Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Research & Discovery </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Competitive Research</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Develop Strategy </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Campaign</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Optimize </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Grow</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={seoService3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require Google Display Ads Services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let Us Build Your Google Display Ads Campaigns Successful
                    With Our Display Ads Management
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We have a dedicated team who always keeps track of Local and
                    global Marketing expertise.
                  </li>
                  <li className="page-six-li">
                    Target potential customer for your service
                  </li>
                  <li className="page-six-li">
                    Our Google Display Ads campaign is low-cost compare to other
                    kinds of marketing.
                  </li>
                  <li className="page-six-li">
                    Improve operational efficiency and customer satisfaction
                  </li>
                  <li className="page-six-li">
                    Enhance the experience and improve operational efficiency
                  </li>
                  <li className="page-six-li">Mitigate risk</li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService2} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService4} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We convert campaigns into profitable solutions.
                  </li>
                  <li className="page-six-li">Result-oriented outcomes</li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements.
                  </li>
                  <li className="page-six-li">
                    Focused and top-notched Google display advertising.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Percel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Google Display Ads Services At The Right Price
              </p>
            </div>
            <div className="pricing-main">
              <div className="pricing-table-section">
                <div className="table-header">
                  <div className="feature-section">
                    <h2 className="table-title">Features</h2>
                  </div>
                  <div className="silver-section">
                    <h2 className="table-title">Silver</h2>
                  </div>
                  <div className="gold-section">
                    <h2 className="table-title">Gold</h2>
                  </div>
                  <div className="platinum-section">
                    <h2 className="table-title">Platinum</h2>
                  </div>
                </div>

                <div className="table-content1">
                  <div className="feature">
                    <p></p>
                  </div>

                  <div className="silver">
                    <p>$175/month</p>
                  </div>
                  <div className="gold">
                    <p>14.% of ad budget</p>
                  </div>
                  <div className="platinum">
                    <p>12% of ad budget</p>
                  </div>
                </div>
                <div className="table-content1">
                  <div className="feature">
                    <p></p>
                  </div>

                  <div className="silver">
                    <p>$500 INITIAL INVESTMENT</p>
                  </div>
                  <div className="gold">
                    <p>$850 INITIAL INVESTMENT</p>
                  </div>
                  <div className="platinum">
                    <p>$1200 INITIAL INVESTMENT</p>
                  </div>
                </div>
                {data.map((data) => (
                  <div className="table-content1">
                    <div className="feature">
                      <p>{data.name}</p>
                    </div>

                    <div className="silver">
                      {data.silver === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.silver}</p>
                      )}
                    </div>
                    <div className="gold">
                      {data.gold === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.gold}</p>
                      )}
                    </div>
                    <div className="platinum">
                      {data.platinum === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.platinum}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default DisplayAdsManagement;
