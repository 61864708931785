import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/ovcMarketing/1.breaking-news-concept_74855-6902-removebg-preview 2.png";
import image2 from "../../../assets/images/ovcMarketing/2.manager-leading-brainstorming-meeting 1 (4).png";
import image3 from "../../../assets/images/ovcMarketing/3.business-man-holding-paper-with-why-choose-us-question_23-2148932314 1 (4).png";
import image4 from "../../../assets/images/ovcMarketing/4.workers-pointing-increase-sales 2 (4).png";
import image5 from "../../../assets/images/ovcMarketing/5.adult-it-professional-2021-09-24-04-08-01-utc 1.png";
import image6 from "../../../assets/images/ovcMarketing/6.businesspeople-celebrating-success 2 (4).png";
import { Link } from "react-router-dom";

function OVCMarketing() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>OVC Marketing: Online Video Commercial Agency | WeTech</title>
          <meta
            name="description"
            content="Get OVC Marketing services at affordable packages. WeTech Digital Is a Creative Online Video Commercial Agency in Bangladesh."
          ></meta>
          <meta
            name="keywords"
            content="
          ovc marketing
          ,online video commercial
          ,Online Video Commercial Package
          ,video marketing service provider in Bangladesh
          ,Animated Online Video Commercial
          ,Social Video Marketing
          
          

          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">OVC Marketing</p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want Online Video Commercial? Then we're here to help
                    you out competently by giving OVC marketing services.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span> &gt; </span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Online Video Commercial Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top Online Video Commercial Agency in
                  Bangladesh. Although video commercials refer to advertising
                  that happens before, during, and/or after a recorded video on
                  an online platform, it is widely understood that they relate
                  to promotion that occurs before, during, and/or after a video
                  stream on the World Wide Web. In this digital era, OVC
                  marketing not only has good effectiveness but is also highly
                  needed. That's why online video commercial services are given
                  by our agency to keep pace with hype. YouTube, Facebook,
                  Instagram can be the best platform for this. Also as a social
                  video marketing agency we are at top in the market. Social
                  video marketing is nowadays very popular. It requires experts
                  and well equipped agency for the best results. We can proudly
                  say that, we are the top notch video marketing service
                  provider in Bangladesh.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to Online Video Commercial</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital is dedicated to providing clients with
                  interesting, relevant, and effective video contents services
                  since these are under OVC marketing. You may use online videos
                  to better describe your product, service, or concept. It has
                  to do with how individuals consume information. People who use
                  the internet have short attention spans, and videos allow them
                  to ingest more information in a shorter amount of time. Videos
                  are far more likely to engage visitors than static text,
                  therefore more interactive and visual material is critical.
                  Animated online video commercial is also a popular feature in
                  OVC marketing. Animation has the ability to captivate the
                  hearts of people of all ages. The client has already given you
                  their attention; now it's up to you to persuade them to keep
                  paying attention to you. Online video commercial helps to
                  brought them. WeTech Digital has online video commercial
                  package. Our online video commercial package is very cost
                  effective. Our social video marketing team can help you to
                  solve your issues. You can anytime contact with them.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">
                    Business Origin & structure analysis
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Product and Content research</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Interest of target audience</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">OVC making</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Platform detection </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Circulation of commercial</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require Online Video Commercial Services in
                    Bangladesh?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best ideas!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <Link to="/contact">
                      <button className="services-five-button">
                        Contact Us
                      </button>
                    </Link>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We are always balancing the needs of our customers.
                  </li>
                  <li className="page-six-li">Our job is more efficient.</li>
                  <li className="page-six-li">
                    We provide a variety of services for many platforms.
                  </li>
                  <li className="page-six-li">
                    Our service fees are reasonable.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Provide high quality animated Online Video Commercial
                  </li>
                  <li className="page-six-li">
                    We arrange social platform to showing your online video
                    commercial.
                  </li>
                  <li className="page-six-li">
                    Informative, creative and sometimes animated online video
                    commercial are show our unique ideas.
                  </li>
                  <li className="page-six-li">
                    Spreads your fame everywhere by online video commercial.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Local & Global OVC Marketing Service At The Right
                Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default OVCMarketing;
