import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import data from "./data/AmazonSeo";

// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import amazon from "../../../assets/images/amazon-seo-and-product-optimization.png";
import jam from "../../../assets/images/jamCheck.png";
import Testimonial from "../../blocks/Testimonial";

import amazonSeo1 from "../../../assets/images/amazonSeo/1.business-people-posing-together-office 1.webp";
import amazonSeo2 from "../../../assets/images/amazonSeo/2.blurred-front-view-business-man 1.webp";
import amazonSeo3 from "../../../assets/images/amazonSeo/3.businessman-doing-ok-sign-while-holding-growth-chart 1.webp";
import amazonSeo4 from "../../../assets/images/amazonSeo/4.photo-indifferent.webp";
import amazonSeo5 from "../../../assets/images/amazonSeo/5.pretty-girl.webp";
import { Link } from "react-router-dom";

function AmazonSEOAndProductOptimizaton() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Amazon SEO services: Product optimization & SEO agency | WeTech
          </title>
          <meta
            name="description"
            content="Increase your Amazon store traffic and stay on top of the SERP. Check out WeTech Digital Amazon SEO services & product optimization affordable packages.
"></meta>
          <meta
            name="keywords"
            content="
          
amazon seo services
,amazon seo agency
,amazon seo packages
,amazon optimization services
,amazon listing optimization service
,best amazon listing optimization service"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    Amazon SEO & Product Optimization
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want to optimize your product listing so that it
                    appears near the top of Amazon search results? Then we are
                    here to please you providing Amazon optimization services
                    professionally. Learn More
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={amazon} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Amazon SEO Services & Product
                  Optimization Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is a top Amazon SEO business that provides
                  results-driven Amazon SEO services that include
                  industry-leading tools and cutting-edge product listing
                  optimization techniques to boost sales, online exposure, and
                  market domination. When it comes to looking for a new product
                  to buy, around half of all customers go to Amazon, which
                  accounts for more than 40% of all online sales. We help your
                  business and your items become the preferred choice of
                  customers all around the world with our performance-driven
                  Amazon SEO services. This leads to more sales, income, and
                  growth for your company, allowing you to accomplish more with
                  it. As an Amazon SEO agency, our services towards listing
                  products optimization and most importantly at SEO services are
                  very well known.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={amazonSeo1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={amazonSeo2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Amazon SEO & Product Optimization Service{" "}
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  With Amazon's highly competitive search queries and the
                  growing dominance of Amazon listings in normal search results,
                  it's becoming increasingly important for businesses to hire an
                  Amazon SEO firm. WeTech Digital provides specialist Amazon SEO
                  services to help your company not only sustain but also expand
                  its sales statistics, implying that you're moving more goods
                  regularly. Our Amazon SEO packages and Amazon listing
                  optimization services are much affordable. Our Growth Process
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Product Analysis </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Develop strategy </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Product Analysis report </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Keywords analysis</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Product optimization </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Monitoring </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">SEO Service </p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={amazonSeo3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require Amazon SEO & Product Optimization services?
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We offer the best Amazon listing optimization service in the
                    field.
                  </li>
                  <li className="page-six-li">
                    We provide affordable services cost.
                  </li>
                  <li className="page-six-li">
                    Rates in stock are being monitored by our experts.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platforms.
                  </li>
                  <li className="page-six-li">Provide unique ad postings.</li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={amazonSeo4} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={amazonSeo5} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    As internet marketing service providers, we take care of
                    your image in the market field.
                  </li>
                  <li className="page-six-li">
                    Monitoring Amazon's inventory planning.
                  </li>
                  <li className="page-six-li">
                    Continuously improving product pages optimization.
                  </li>
                  <li className="page-six-li">
                    Videos and images are being added regularly in this.
                  </li>
                  <li className="page-six-li">
                    Designing custom content with high technology.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Amazon SEO & Product Optimization Service At The
                Right Price
              </p>
            </div>
            <div className="pricing-main">
              <div className="pricing-table-section">
                <div className="table-header">
                  <div className="feature-section">
                    <h2 className="table-title">Features</h2>
                  </div>
                  <div className="silver-section">
                    <h2 className="table-title">Silver</h2>
                  </div>
                  <div className="gold-section">
                    <h2 className="table-title">Gold</h2>
                  </div>
                  <div className="platinum-section">
                    <h2 className="table-title">Platinum</h2>
                  </div>
                </div>

                <div className="table-content1">
                  <div className="feature">
                    <p></p>
                  </div>

                  <div className="silver">
                    <p>$600/month</p>
                  </div>
                  <div className="gold">
                    <p>$1250/month</p>
                  </div>
                  <div className="platinum">
                    <p>$2125/month</p>
                  </div>
                </div>
                {data.map((data) => (
                  <div className="table-content1">
                    <div className="feature">
                      <p>{data.name}</p>
                    </div>

                    <div className="silver">
                      {data.silver === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.silver}</p>
                      )}
                    </div>
                    <div className="gold">
                      {data.gold === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.gold}</p>
                      )}
                    </div>
                    <div className="platinum">
                      {data.platinum === "" ? (
                        <img src={jam} alt="" />
                      ) : (
                        <p>{data.platinum}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default AmazonSEOAndProductOptimizaton;
