import React from "react";
import "../../assets/css/header.css";

function JobDescription({ closeModal, data }) {
  return (
    <div className="modal fade show" tabIndex="-1" style={{ display: "block" }}>
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5">{data?.title}</h1>
            <div
              type="button"
              className="text-2xl font-bold text-gray-500 hover:text-red-400 p-1 rounded-md hover:border-1 hover:border-red-400"
              onClick={closeModal}
              aria-label="Close">X</div>
          </div>
          <div className="modal-body overflow-x-auto">
            <h4>Company Introduction</h4>
            <p className="text-justify">
              <b>WeTech Digital</b> is one of the fastest-growing digital
              marketing and software solution agencies that handles various
              projects for many reputed organisations. By considering our
              clients' products and services as our own, our main focus is to
              deliver high-quality and scalable custom applications and IT
              services with online advertising and marketing facilities. Our
              process is efficient, and our services are customer-driven.
            </p>
            <h4>Job Description</h4>
            <p className="text-justify">
              We are thrilled to announce multiple exciting internship
              opportunities within our organisation. Our{" "}
              <b>“Career at Digital Agency - Internship 2.0”</b> offers a range
              of positions in various fields, providing invaluable hands-on
              experience and the chance to work alongside our talented team.
              {data?.description}
            </p>
            <h4 className="mb-4">What are you going to do?</h4>
            {data?.toDo?.map((value, index) => {
              if (typeof value === "string") {
                return (
                  <p key={index}>
                    <b>{index + 1}.</b> {value}
                  </p>
                );
              } else {
                return (
                  <div key={index} className="mb-4">
                    <p>
                      <b>
                        {index + 1}. {value?.title}
                      </b>
                    </p>
                    {value?.description?.map((desc, index) => {
                      return (
                        <div key={index} className="ml-4 ">
                          ● {desc}
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
            <h4 className="mb-4">The qualities we seek in you</h4>
            {data?.qualities?.map((quality, index) => {
              return (
                <p key={index}>
                  <b>{index + 1}.</b>
                  {" " + quality}
                </p>
              );
            })}
            <p>
              Note: Graduation in any relevant field is preferred for all
              internship positions.
            </p>
            <h4>Code of Conduct</h4>
            <p className="text-justify">
              At <b>WeTech Digital</b>, we are deeply committed to maintaining a
              safe, respectful, and ethical work environment for all employees.
              We have a zero-tolerance policy towards any form of unwanted
              behavior, including misconduct, exploitation, abuse, lack of
              integrity, and financial misconduct. We firmly believe that every
              individual deserves to work in an environment free from
              harassment, discrimination, or any other form of inappropriate
              conduct.
            </p>
            <p>
              <b>Job Type:</b> Full-time, In House, Intern.
            </p>
            <p>
              <b>Duration: </b> Six (06) months.
            </p>
            <p>
              <b>Job Location:</b> (44 F/7(Rongon Tower), West
              Panthapath,Dhaka).
            </p>
            <p>
              <b>Working Hours: </b> 09:00 am - 06:00 pm.
            </p>
            <p>
              <b>Working Days: </b> Saturday – Thursday.
            </p>
            <h4>What you will have</h4>
            <p>
              We believe, "If we take care of our people, they will take care of
              our customers." And if we have satisfied customers, they will take
              care of our profits."
            </p>
            <p>So, we will give you</p>
            <p>● The friendliest working environment</p>
            <p>● Opportunity to work with local and foreign clients.</p>
            <p>
              ● The best employee of the month receives a bonus every month.
            </p>
            <p>● Remuneration:</p>
            <p className="ml-8">1. First 3 months: 5,000 BDT.</p>
            <p className="ml-8">2. Last 3 months: 8,000 BDT.</p>
            <p className="text-justify">
              Welcome to our Internship Program “Career at Digital Agency -
              Internship 2.0”. Each position offers a unique learning experience
              and an opportunity to contribute to our team. Join us and unleash
              your potential, learn from industry experts, and gain valuable
              skills for your future career.
            </p>
          </div>
          <div className="modal-footer">
            <div
              type="button"
              className="bg-gray-400 text-white py-2 px-3 shadow-md cursor-pointer"
              onClick={closeModal}>
              Close
            </div>

            <button type="button" onClick={() => window.open("https://forms.gle/1f1g2u2zxmWMaWR6A")} className="button-sign-in bg-[#0099dd] !w-[10rem]">
              Apply Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobDescription;
