const data = [
  {
    name: "Monthly PPC spend with PPC networks",
    silver: "$0 - $5,000/month ad spend (paid to networks)",
    gold: "$5,001 - $20,000+/month ad spend (paid to networks)",
    platinum: "$20,001+ monthly ad spend",
  },
  {
    name: "Google PPC Network",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Bing PPC Networks",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ecommerce PPC ROI reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Google & Bing Shopping Feed Management (Client is responsible for creating/programming/updating Google/Bing Merchant Center feed)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "If - Website development and programming is needed to setup the Shopping Feed",
    silver:
      "One-time: $800 for Magento, Woo, & Shopify | $1,200 for All Other Storefronts",
    gold: "One-time: $800 for Magento, Woo, & Shopify | $1,200 for All Other Storefronts",
    platinum:
      "One-time: $800 for Magento, Woo, & Shopify | $1,200 for All Other Storefronts",
  },
  {
    name: "Google Text Ad Remarketing & Banner Remarketing (if banner ads are included, provided by the client, or creative banner design is paid for)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Google Display Network",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "In-Store Visit Reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Google customer match & audience match",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Gmail Competitor Ads",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Facebook Dynamic Remarketing",
    silver: "By Quote",
    gold: "By Quote",
    platinum: "By Quote",
  },
  {
    name: "Google Dynamic Remarketing (Client website access is needed)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Campaigns will be managed through client's Google Ads account (Client will retain Admin/owner access of all campaigns created)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "GoDataFeed, DataFeedWatch, etc Management (Monthly GoDataFeed, DataFeedWatch, etc fee paid for by client) {Optional}",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial campaign development & strategy",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ad copy performance testing",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Advanced keyword research and selection",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Industry analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ad campaign copywriting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ad copy performance testing",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ongoing keyword development and tweaking",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Dynamic keyword insertion into ads",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Google Analytics integration & goal tracking",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Results analysis/reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Strategic bid management",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "PPC account settings monitoring (Ex. Geotargeting)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Single account representative",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Optional - Shopping cart abandonment emails (For clients running - Magento, Shopify, & WooCommerce clients {Other ecommerce stores - Setup and development billed hourly})",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Up to two personal consultations per month",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monitoring clicks, conversions and click fraud activity",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Setup and management of rule based bidding",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "International PPC campaign management (English only campaigns)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Social Media Product Advertising: Facebook, Instagram, LinkedIn, Pinterest, & Snapchat",
    silver: "By Quote",
    gold: "By Quote",
    platinum: "By Quote",
  },
  {
    name: "Setup of 1 Unbounce Landing Page template (optional)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Website conversion analysis reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "300+ SMEs behind campaign driving results",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Banner ad design for display and remarketing",
    silver: "Add $350",
    gold: "1 set included",
    platinum: "1 set included",
  },
  {
    name: "Quarterly banner ad design for display and remarketing (A total of 4 banner sets per year)",
    silver: "Add $350",
    gold: "Add $225 a month (since 1 set is included each year)",
    platinum: "Add $225 a month (since 1 set is included each year)",
  },
  {
    name: "Website conversion analysis implementation",
    silver: "",
    gold: "By Quote",
    platinum: "By Quote",
  },
  {
    name: "Landing page performance testing",
    silver: "",
    gold: "By Quote",
    platinum: "By Quote",
  },
  {
    name: "Multivariant testing ecommerce store & checkout/cart process",
    silver: "",
    gold: "By Quote",
    platinum: "By Quote",
  },
  {
    name: "Monthly performance & analysis reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "One time setup (1st month)",
    silver: "$475 + 1 Months Management Fee $850 minimum",
    gold: "$350 + 1 Months Management Fee $1,450 minimum",
    platinum: "1 Months Management Fee $3,500 minimum",
  },
  {
    name: "Progressive monthly management cost",
    silver: "$350 or 15% of ad spend, whichever is higher",
    gold: "14% of ad spend, whichever is higher",
    platinum: "12% of ad spend, whichever is higher",
  },
];
export default data;
