const data = [
  {
    name: "Dedicated Amazon Marketing Specialist",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial Product Analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Comprehensive Product Analysis Report",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Competitive Product Price Analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Product User Engagement Analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Keyword Research for Individual Products (50 keywords per product)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Product Listings Keyword Mapping and Optimization",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Search Term Keyword Additions",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Product Title Optimization",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Product Imagery List (list of products that do not meet Amazon recommended image number or have image errors)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Product key features optimization",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Product Category Optimization",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Product Review Evaluation",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial Product Optimization",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial Product Copywriting (includes product copy for 5 featured bullet points and product description)",
    silver: "Up to 15 child ASINs",
    gold: "Up to 30 child ASINs",
    platinum: "Up to 50 child ASINs",
  },
  {
    name: "Video Additions (Provided by client)",
    silver: "15 pages",
    gold: "30 pages",
    platinum: "50 pages",
  },
  {
    name: "Image Additions (provided by client)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial Photo Editing",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial campaign development & strategy",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial inventory and backend review",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "New Products Consulting & Integration into Amazon Account (if new products are part of above 20 targeted products)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Existing Product Optimization Suggestions and Implementations (if part of above 20 targeted products)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Amazon Case Management (if case arises from above 20 targeted products)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "In-Stock Rate Monitoring",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Perfect Order Percentage Monitoring",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Order Defect Rate Monitoring",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Amazon Inventory Planning Monitoring",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Global Marketplace Suggestions",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ongoing Product Optimization",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Quarterly Product Analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Quarterly Earned Media Content Assets - Content, Product, Review Updates, and more",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Quarterly Competitor Analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monthly Pricing Monitoring",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monthly Sales Monitoring",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monthly Amazon Reporting (2 hours included)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monthly Amazon Consulting Call",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial Campaign Investment (One-Time: Two-Month Duration)",
    silver: "$700",
    gold: "$1450",
    platinum: "$2350",
  },
  {
    name: "Progressive Monthly Management Cost",
    silver: "$1,250",
    gold: "$1,250",
    platinum: "$2,125",
  },
];
export default data;
