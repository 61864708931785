import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../../assets/css/home.css";
import { IoCallOutline } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { toast } from "react-toastify";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const { name, email, phone, message } = formData;

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newUser = {
      name,
      email,
      phone,
      message,
    };
    try {
      const dataInserted = await import("../../utils/services/form.service").then(
        async (service) => await service.postContactForm({ data: newUser })
      );
      if (dataInserted?.status === 201 || dataInserted?.status === 200) {
        toast.success('Successfully sent.', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      console.error(err.response.data);
    }
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  return (
    <div>
      <div>
        <Helmet >
          <title>
            {" "}
            contact us | WeTech
          </title>
          <meta
            name="description"
            content="Contact us | WeTech"
          ></meta>
          <meta
            name="keywords"
            content="contact us"
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one" style={{ marginBottom: "3rem" }}>
            <div className="row" style={{ background: "#0099dd", marginTop: "2.8rem", }}>
              <div className="col-md-6 p-md-4 p-2">
                <div className="">
                  <form action="" className="services-contact-form" style={{ width: "100%" }}>
                    <p className="services-contact-title text-start">Contact Us</p>
                    <div className="services-contact-input-section">
                      <input
                        className="services-contact-input"
                        type="text"
                        placeholder="Full Name *"
                        name="name"
                        id="name"
                        required
                        value={name}
                        onChange={handleChange}
                      />
                      <input
                        className="services-contact-input"
                        type="text"
                        placeholder="Email Address *"
                        id="email"
                        name="email"
                        required
                        value={email}
                        onChange={handleChange}
                      />
                      <input
                        className="services-contact-input"
                        type="number"
                        placeholder="Phone Number *"
                        id="phone"
                        name="phone"
                        required
                        value={phone}
                        onChange={handleChange}
                      />
                      <textarea
                        className="services-contact-msg-input"
                        rows="2"
                        cols="40"
                        type="text"
                        required
                        placeholder="Your Message *"
                        id="message"
                        name="message"
                        value={message}
                        onChange={handleChange}
                      />

                      <button
                        className="services-contact-button "
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-md-6 p-md-4 p-2 mt-md-5 mt-1">

                <div className="right-image-section align-items-start" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div className="row text-light" >
                    <div className="col-md-7 text-center text-md-left">
                      <h4 className="fw-bold"><IoCallOutline /> Call</h4>
                      <p>01810122108</p>
                    </div>

                    <div className="col-md-5 text-center text-md-left">
                      <h4 className="fw-bold"><AiOutlineMail /> Email</h4>
                      <p>contact@wetechdigital.com</p>
                    </div>
                  </div>
                  <img className="image-file" src="https://i.ibb.co/Qp3rMKN/We-Tech-Digital-Contact-Us.jpg
" alt="" />
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="container">
        <div className="row">
          <iframe width="100%" height="450" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=wetech%20digital%20Dhaka+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=5cfdda1e753fd3c4bfd387b1df4f18b16c74cb25'></script>
        </div>
      </div>
    </div>
  );
}


export default ContactUs;
