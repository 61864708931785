import React, {useContext, useEffect, useState} from "react";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import './components/assets/css/global.css';
import Footer from "./components/header/Footer";
import Header from "./components/header/Header";
import Home from "./components/pages/Home";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import MessengerCustomerChat from "react-messenger-customer-chat";
import ScrollToTop from "../src/components/utils/scrollToTop";
import MegaMenu from "./components/header/MegaMenu";
import InfluencerMarketing from "./components/pages/branding-and-business-solution/brand-awareness/InfluencerMarketing";
import NewsPaperAds from "./components/pages/branding-and-business-solution/brand-awareness/NewsPaperAds";
import OVCMarketing from "./components/pages/branding-and-business-solution/brand-awareness/OVCMarketing";
import TVCMarketing from "./components/pages/branding-and-business-solution/brand-awareness/TVCMarketing";
import BrandIdentity from "./components/pages/branding-and-business-solution/brand-strategy/BrandIdentity";
import BusinessDevelopmentStrategy from "./components/pages/branding-and-business-solution/brand-strategy/BusinessDevelopmentStrategy";
import CommunityManagement from "./components/pages/branding-and-business-solution/brand-strategy/CommunityManagement";
import CompetitorAnalysis from "./components/pages/branding-and-business-solution/brand-strategy/CompettorAnalysis";
import MarketResearch from "./components/pages/branding-and-business-solution/brand-strategy/MarketResearch";
import BusinessRegistration from "./components/pages/branding-and-business-solution/legal-documentation/BusinessRegistration";
import LicenceAndCertification from "./components/pages/branding-and-business-solution/legal-documentation/LicenceAndCertification";
import PartnershipDeed from "./components/pages/branding-and-business-solution/legal-documentation/PartnershipDeed";
import TradeMarkApplication from "./components/pages/branding-and-business-solution/legal-documentation/TradeMarkApplication";
import UxWebsiteDesign from "./components/pages/design-and-development/design-pricing/UxWebsiteDesign";
import LandingPageDesign from "./components/pages/design-and-development/design-pricing/LandingPageDesign";
import UserExperienceTesting from "./components/pages/design-and-development/design-pricing/UserExperienceTesting";
import ContentManagementSystem from "./components/pages/design-and-development/development-pricing/ContentManagementSystem";
import EcommerceDevelopment from "./components/pages/design-and-development/development-pricing/EcommerceDevelopment";
import MobileApplication from "./components/pages/design-and-development/development-pricing/MobileApplication";
import PortfolioSite from "./components/pages/design-and-development/development-pricing/PortfolioSite";
import ShopifyAdsManagement from "./components/pages/seo-and-sales/marketplace-pricing/ShopifyAdsManagement";
import WebsiteDevelopment from "./components/pages/design-and-development/development-pricing/WebsiteDevelopment";
import WebsiteMaintenance from "./components/pages/design-and-development/development-pricing/WebsiteMaintenance";
import CRMROI from "./components/pages/research-and-optimization/analysis/CRMROI";
import CustomSEOReporting from "./components/pages/research-and-optimization/analysis/CustomSEOReporting";
import LeadManagementSolution from "./components/pages/research-and-optimization/analysis/LeadManagementSolution";
import ConversionRateOptimization from "./components/pages/research-and-optimization/optimization/ConversionRateOptimization";
import PageSpeedOptimization from "./components/pages/research-and-optimization/optimization/PageSpeedOptimization";
import AmazonAdsManagement from "./components/pages/seo-and-sales/marketplace-pricing/AmazonAdsManagement";
import AmazonSEOAndProductOptimizaton from "./components/pages/seo-and-sales/marketplace-pricing/AmazonSEOAndProductOptimizaton";
import FacebookMarketPlaceForBusiness from "./components/pages/seo-and-sales/marketplace-pricing/FacebookMarketPlaceForBusiness";
import ShopifyOptimizationService from "./components/pages/seo-and-sales/marketplace-pricing/ShopifyOptimizationService";
import AppStoreOptimizaton from "./components/pages/seo-and-sales/organic-serach-pricing/AppStoreOptimization";
import EcommerceSEO from "./components/pages/seo-and-sales/organic-serach-pricing/EcommerceSEO";
import LinkBuildingService from "./components/pages/seo-and-sales/organic-serach-pricing/LinkBuildingService";
import LocalSEO from "./components/pages/seo-and-sales/organic-serach-pricing/LocalSEO";
import SEOAudit from "./components/pages/seo-and-sales/organic-serach-pricing/SEOAudit";
import SEOService from "./components/pages/seo-and-sales/organic-serach-pricing/SEOService";
import DisplayAdsManagement from "./components/pages/seo-and-sales/paid-marketing-pricing/DisplayAdsManagement";
import EcommercePPCService from "./components/pages/seo-and-sales/paid-marketing-pricing/EcommercePPCService";
import LocalSearchAdsManagement from "./components/pages/seo-and-sales/paid-marketing-pricing/LocalSearchAdsManagement";
import PPCManagement from "./components/pages/seo-and-sales/paid-marketing-pricing/PPCManagement";
import EcommerceSocialAdsService from "./components/pages/social-and-creative-content/social-media-pricing/EcommerceSocialAdsServices";
import SocialMediaAdvertising from "./components/pages/social-and-creative-content/social-media-pricing/SocialMediaAdvertising";
import SocialMediaDesign from "./components/pages/social-and-creative-content/social-media-pricing/SocialMediaDesign";
import YoutubeAdsService from "./components/pages/social-and-creative-content/social-media-pricing/YoutubeAdsService";
import SocialMediaManagementService from "./components/pages/social-and-creative-content/social-media-pricing/SocialMediaManagementService";
import AnimationVideo from "./components/pages/social-and-creative-content/video-and-animation/AnimationVideo";
import CharacterAnimation from "./components/pages/social-and-creative-content/video-and-animation/CharacterAnimation";
import InfoAndMotionGraphics from "./components/pages/social-and-creative-content/video-and-animation/InfoAndMotionGraphics";
import PromoVideoProduction from "./components/pages/social-and-creative-content/video-and-animation/PromoVideoProduction";
import ThreeDProductVisualization from "./components/pages/social-and-creative-content/video-and-animation/ThreeDProductVisualization";
import ContentMarketingService from "./components/pages/social-and-creative-content/web-content-pricing/ContentMarketingService";
import PersonalizedWebContent from "./components/pages/social-and-creative-content/web-content-pricing/PersonalizedWebContent";
import ProductPhotographyService from "./components/pages/social-and-creative-content/web-content-pricing/ProductPhotgraphyService";
import WebsiteSEOCopywriting from "./components/pages/social-and-creative-content/web-content-pricing/WebsiteSEOCopywriting";
import WebVideoProductionService from "./components/pages/social-and-creative-content/web-content-pricing/WebVideoProductionService";
import pageNotFound from "./components/assets/images/pageNotFound.jpeg";
import Portfolio from "./components/pages/portfolio";
import WebDesignPortfolio from "./components/pages/portfolio/webDesign";
import AppDevPortfolio from "./components/pages/portfolio/mobileAppDev";
import UiUxPortfolio from "./components/pages/portfolio/uiUxDesign";
import SeoPortfolio from "./components/pages/portfolio/seoPortfolio";
import SocialMediaPortfolio from "./components/pages/portfolio/socialMediaPortfolio";
import CreativeDesignPortfolio from "./components/pages/portfolio/creativeDesignPortfolio";
import VideoProPortfolio from "./components/pages/portfolio/videoProductionPortfolio";
import AnimationPortfolio from "./components/pages/portfolio/animationPortfolio";
import PpcPortfolio from "./components/pages/portfolio/payPerClickPortfolio";
import AboutUs from "./components/pages/aboutUs";
import ContactUs from "./components/pages/contactus";
import Career from "./components/pages/career";
import JobDetails from "./components/pages/career/jobDetails";
import JobApply from "./components/pages/career/jobDetails/jobApply";
import GetAQuote from "./components/pages/getAQuote";
import Blog from "./components/pages/Blog";
import { ToastContainer } from "react-toastify";
import PortfolioDetails from "./components/pages/portfolio/portfolioDetails";
import BlogDetails from "./components/pages/Blog/BlogDetails";
import InternshipProgram from "./components/pages/internship-program";
import useGetActiveRoute from "./hooks/useGetActiveRoute";
import { GlobalStateContext } from "./store/store";

function App() {
  const NotFound = () => (
    <div>
      <img
        style={{ height: "100%", width: "100%" }}
        className="m-2"
        src={pageNotFound}
        alt=""
      />
    </div>
  );

  const activeRoute = useGetActiveRoute();

  const history = createBrowserHistory();

  return (
    <div>
      <Router history={history}>
        <ToastContainer />
        <ScrollToTop />
        {activeRoute !== "/internship-program" && <Header/>}
        {activeRoute !== "/internship-program" &&<MegaMenu />}

        {/* <Example /> */}

        <MessengerCustomerChat
          pageId="100608275271581"
          appId="814583795823534"
        />

        <Switch>
          <Route path="/" component={Home} exact />
          {/* Branding and Business Solution
      Brand Awareness */}
          <Route
            path="/influence-marketing"
            component={InfluencerMarketing}
            exact
          ></Route>
          <Route
            path="/newspaper-advertisement"
            component={NewsPaperAds}
            exact
          ></Route>

          <Route path="/ovc-marketing" component={OVCMarketing} exact></Route>
          <Route path="/tvc-marketing" component={TVCMarketing} exact></Route>

          {/* Brand Strategy */}
          <Route path="/brand-identity" component={BrandIdentity} exact></Route>
          <Route
            path="/business-development-strategy"
            component={BusinessDevelopmentStrategy}
            exact
          ></Route>
          <Route
            path="/community-management"
            component={CommunityManagement}
            exact
          ></Route>
          <Route
            path="/competitor-analysis"
            component={CompetitorAnalysis}
            exact
          ></Route>
          <Route
            path="/market-research"
            component={MarketResearch}
            exact
          ></Route>

          {/* Legal documentation */}
          <Route
            path="/business-registration"
            component={BusinessRegistration}
            exact
          ></Route>
          <Route
            path="/licence-and-certification"
            component={LicenceAndCertification}
            exact
          ></Route>
          <Route
            path="/partnership-deed"
            component={PartnershipDeed}
            exact
          ></Route>
          <Route
            path="/trade-mark-application"
            component={TradeMarkApplication}
            exact
          ></Route>

          {/* Design and Development
      Design Pricing */}
          <Route
            path="/ux-website-design"
            component={UxWebsiteDesign}
            exact
          ></Route>
          <Route
            path="/landing-page-design"
            component={LandingPageDesign}
            exact
          ></Route>
          <Route
            path="/user-experience-testing"
            component={UserExperienceTesting}
            exact
          ></Route>

          {/* Development Pricing */}
          <Route
            path="/content-management-system"
            component={ContentManagementSystem}
            exact
          ></Route>
          <Route
            path="/eCommerce-development"
            component={EcommerceDevelopment}
            exact
          ></Route>
          <Route
            path="/mobile-application"
            component={MobileApplication}
            exact
          ></Route>
          <Route path="/portfolio-site" component={PortfolioSite} exact></Route>
          <Route
            path="/shopify-store-development"
            component={ShopifyAdsManagement}
            exact
          ></Route>
          <Route
            path="/website-development"
            component={WebsiteDevelopment}
            exact
          ></Route>
          <Route
            path="/website-maintenance"
            component={WebsiteMaintenance}
            exact
          ></Route>
          {/* Research and Optimization */}
          <Route path="/crm-roi" component={CRMROI} exact></Route>
          <Route
            path="/custom-seo-reporting"
            component={CustomSEOReporting}
            exact
          ></Route>
          <Route
            path="/lead-management-solution"
            component={LeadManagementSolution}
            exact
          ></Route>
          <Route
            path="/conversion-rate-optimization"
            component={ConversionRateOptimization}
            exact
          ></Route>
          <Route
            path="/page-speed-optimization"
            component={PageSpeedOptimization}
            exact
          ></Route>

          {/* SEO and sales
      Marketplace pricing */}
          <Route
            path="/amazon-ads-management"
            component={AmazonAdsManagement}
          ></Route>
          <Route
            path="/amazon-seo-and-production"
            component={AmazonSEOAndProductOptimizaton}
          ></Route>
          <Route
            path="/facebook-market-price-for-business"
            component={FacebookMarketPlaceForBusiness}
          ></Route>
          <Route
            path="/shopify-ads-management"
            component={ShopifyAdsManagement}
          ></Route>
          <Route
            path="/shopify-optimization-service"
            component={ShopifyOptimizationService}
          ></Route>

          {/* Organic Search Pricing */}
          <Route
            path="/app-store-optimization"
            component={AppStoreOptimizaton}
            exact
          ></Route>
          <Route path="/eCommerce-seo" component={EcommerceSEO} exact></Route>
          <Route
            path="/link-building-service"
            component={LinkBuildingService}
            exact
          ></Route>
          <Route path="/local-seo" component={LocalSEO} exact></Route>
          <Route path="/seo-audit" component={SEOAudit} exact></Route>
          <Route path="/seo-service" component={SEOService} exact></Route>

          {/* paid marketing pricing */}
          <Route
            path="/display-ads-management"
            component={DisplayAdsManagement}
          ></Route>
          <Route
            path="/eCommerce-ppc-service"
            component={EcommercePPCService}
          ></Route>
          <Route
            path="/local-search-ads-management"
            component={LocalSearchAdsManagement}
          ></Route>
          <Route path="/ppc-management" component={PPCManagement}></Route>

          {/* Social and Creative Conetent
      Social Media Pricing  */}
          <Route
            path="/eCommerce-social-ads-service"
            component={EcommerceSocialAdsService}
            exact
          ></Route>
          <Route
            path="/social-media-advertising"
            component={SocialMediaAdvertising}
            exact
          ></Route>
          <Route
            path="/social-media-design"
            component={SocialMediaDesign}
            exact
          ></Route>
          <Route
            path="/social-media-management-service"
            component={SocialMediaManagementService}
            exact
          ></Route>
          <Route
            path="/youtube-ads-service"
            component={YoutubeAdsService}
            exact
          ></Route>

          {/* Video and Animation */}
          <Route
            path="/animation-video"
            component={AnimationVideo}
            exact
          ></Route>
          <Route
            path="/character-animation"
            component={CharacterAnimation}
            exact
          ></Route>
          <Route
            path="/infographics-and-motiongraphics"
            component={InfoAndMotionGraphics}
            exact
          ></Route>
          <Route
            path="/promo-video-production"
            component={PromoVideoProduction}
            exact
          ></Route>
          <Route
            path="/3D-Product-Visualization"
            component={ThreeDProductVisualization}
            exact
          ></Route>
          <Route
            path="/content-marketing-service"
            component={ContentMarketingService}
            exact
          ></Route>
          <Route
            path="/personalized-web-content"
            component={PersonalizedWebContent}
            exact
          ></Route>
          <Route
            path="/product-photography-service"
            component={ProductPhotographyService}
            exact
          ></Route>
          <Route
            path="/website-seo-copyWrite"
            component={WebsiteSEOCopywriting}
            exact
          ></Route>
          <Route
            path="/web-video-production-service"
            component={WebVideoProductionService}
            exact
          ></Route>

          {/* portfolio */}
          <Route
            path="/portfolio"
            component={Portfolio}
            exact
          ></Route>

          <Route
            path="/portfolio/:customUrl"
            component={PortfolioDetails}
            exact
          ></Route>

          <Route
            path="/portfolio/webDesignPortfolio"
            component={WebDesignPortfolio}
            exact
          ></Route>

          <Route
            path="/portfolio/appDevPortfolio"
            component={AppDevPortfolio}
            exact></Route>

          <Route
            path="/portfolio/uiUxPortfolio"
            component={UiUxPortfolio}
            exact></Route>

          <Route
            path="/portfolio/seoPortfolio"
            component={SeoPortfolio}
            exact></Route>

          <Route
            path="/portfolio/socialMediaPortfolio"
            component={SocialMediaPortfolio}
            exact></Route>

          <Route
            path="/portfolio/creativeDesignPortfolio"
            component={CreativeDesignPortfolio}
            exact></Route>


          <Route
            path="/portfolio/VideoProPortfolio"
            component={VideoProPortfolio}
            exact></Route>


          <Route
            path="/portfolio/AnimationPortfolio"
            component={AnimationPortfolio}
            exact></Route>

          <Route
            path="/portfolio/PpcPortfolio"
            component={PpcPortfolio}
            exact></Route>

          <Route
            path="/get-a-quote"
            component={GetAQuote}
            exact></Route>

        <Route 
          path="/internship-program"
          component={InternshipProgram}
          exact></Route>

        {/* about us */}
        <Route 
          path="/about"
          component={AboutUs}
          exact></Route>

          {/* contact us */}
          <Route
            path="/contact"
            component={ContactUs}
            exact></Route>

          {/* career */}
          <Route
            path="/career"
            component={Career}
            exact></Route>

          <Route
            path="/jobDetails/:title"
            component={JobDetails}
            exact></Route>

          <Route
            path="/jobApply"
            component={JobApply}
            exact></Route>


          <Route
            path="/blog"
            component={Blog}
            exact></Route>

          <Route
            path="/blog/:title"
            component={BlogDetails}
            exact></Route>

          <Route path="*" component={NotFound} exact />
        </Switch>

        {activeRoute !== "/internship-program" &&<Footer />}
      </Router>
    </div>
  );
}

export default App;
