import { useContext } from "react";
import { GlobalStateContext } from "../store/store";

function useGetActiveRoute() {
  const context = useContext(GlobalStateContext);

  const { globalState } = context;
  const { activeRoute } = globalState;

  return activeRoute;
}

export default useGetActiveRoute;
