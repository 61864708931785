import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/leadManagementSolutions/1.2nd-Inner-Image-link-1-removebg-preview 1.png";
import image2 from "../../../assets/images/leadManagementSolutions/2.manager-leading-brainstorming-meeting 1 (1).png";
import image3 from "../../../assets/images/leadManagementSolutions/3.business-man-holding-paper-with-why-choose-us-question_23-2148932314 1 (1).png";
import image4 from "../../../assets/images/leadManagementSolutions/4.workers-pointing-increase-sales 2 (1).png";
import image5 from "../../../assets/images/leadManagementSolutions/5.thoughtful-young-woman-student-girl-think-at-work-2021-09-02-08-41-44-utc 1.png";
import image6 from "../../../assets/images/leadManagementSolutions/6.businesspeople-celebrating-success 2 (1).png";
import { Link } from "react-router-dom";

function LeadManagementSolution() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Lead management agency that drives your audiences | WeTech
          </title>
          <meta
            name="description"
            content="WeTech Digital is one of the best companies that will give you the best service to get the leads generation service of your target audience."
          ></meta>
          <meta
            name="keywords"
            content="
          
lead management agency
,software for lead management
,lead management company
,b2b lead generation agency
,b2b lead generation services
,b2b lead generation companies
,best b2b lead generation companies
,Lead generation companies for small businesses
,b2b lead generation websites
,top lead generation companies for real estate


          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <p className="services-header-title">
                    Lead Management Solutions
                  </p>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph" >
                    Did you know that leads that are reached within five minutes
                    have a 90% chance of becoming sales? It's difficult to
                    organize, prioritize, and follow up on leads without a good
                    lead management system. Fortunately, WeTech Digital — our
                    clients' proprietary lead management software — can assist
                    you in converting leads into increased sales and money for
                    your small or mid-sized firm. As the top lead management
                    company, you can put your faith in us.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Lead Management Solutions Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph" >
                  Lead management is a systematic procedure for qualifying,
                  analyzing and nurturing incoming leads in order to turn them
                  into new business possibilities. Leads from various sources
                  enter your lead management system and sales-ready leads are
                  transformed into transactions in a conventional sales process.
                  If you want to stand out from the crowd, you'll need to handle
                  and track your leads effectively. A good lead management
                  system can help you streamline your conversion funnel so you
                  can get the most out of your marketing dollars. We've put
                  together a team of experts to assist lead-based businesses in
                  managing, tracking, following up with, and growing valuable
                  leads – and eventually income. Real estate agents may use lead
                  generating tools to discover clients and seal deals. Agents
                  can use lead generating firms to identify buyers and sellers
                  that reside in specific locations, have certain interests, or
                  are seeking particular sorts of homes. We introduce you to top
                  lead generation companies for real estate for your better
                  good. WeTech Digital is a lead management agency that can help
                  you out.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Lead Management Solutions Service
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  We keep track of your marketing ROI in real-time (ROI), follow
                  your leads throughout their entire lifespan. From their first
                  encounter through their purchase, Manage your sales funnel
                  efficiently and assist you in generating more leads, sales,
                  and revenue. We provide a full range of lead-generating
                  services to assist you in increasing lead conversions and,
                  eventually, revenues. Attract your audience's attention.
                  Increase the number of conversions. Boost your return on
                  investment. Consult with us to know about lead generation
                  companies for small businesses. We provide software for lead
                  management. B2B lead generation is the process of locating and
                  attracting the right consumers for your product or service.
                  For B2B sales and marketing teams, it's a must-do activity
                  that our experts control. As there are many b2b lead
                  generation companies, you have to find the correct and
                  affordable one. However, some of them are the best b2b lead
                  generation companies. When clients request it, we create b2b
                  lead generation websites. WeTech Digital is a top b2b lead
                  generation agency. Our b2b lead generation services are well
                  known.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">
                    Define goals & Make a start baseline
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Analysis needs</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Implementation</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Development</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Want well-managed Lead Management Solutions!
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us.</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We have qualified lead management software.
                  </li>
                  <li className="page-six-li">
                    Prepare a plan of short & long-term budget.
                  </li>
                  <li className="page-six-li">
                    Very user-friendly & customizable lead management system.
                  </li>
                  <li className="page-six-li">
                    Our product is well integrated.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Fulfill local industry-specific requirements.
                  </li>
                  <li className="page-six-li">
                    Provide distinct types of software based on needs.
                  </li>
                  <li className="page-six-li">
                    Our lead management software is available for a wide range
                    of business sizes
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Lead Management Solutions Services At The Right
                Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default LeadManagementSolution;
