const data = [
  {
    name: "Number of keyphrases optimized ",
    silver: "Up to 150",
    gold: "Up to 200",
    platinum: "Up to 250",
  },
  {
    name: "Technical Website Analysis & Opportunity Report ",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Keyword research & selection ",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Keyword mapping to target pages ",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Title Tags & Meta descriptions",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Robots.txt optimization",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Quarterly Earned Media Content Assets - Content, Linkbuilding, Consulting, or CRO Assets",
    silver: "3-4 Content, Outreach, UX",
    gold: "7 Content, Outreach, UX",
    platinum: "10 Content, Outreach, UX",
  },
  {
    name: "Information architecture audit",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Google Analytics setup with conversion tracking  ",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Google Analytics traffic analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Google Data Studio Reporting Dashboard Setup",
    silver: "1 dashboards",
    gold: "2 dashboards",
    platinum: "4 dashboards",
  },
  {
    name: "Canonicalization analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Setup of website sitemap",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Google Webmaster Tools and Bing Webmaster Tools setup",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Google/Bing Webmaster Tools analysis (initial and ongoing)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Alternate text added to images",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Product image optimization",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial link analysis and disavow",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Link redirect audit",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Breadcrumb analysis/implementation",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Broken link correction (initial)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Domain unification",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Don-page crawl error correction",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Custom 404 error page setup",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Product Markup Schema",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ongoing quarterly copywriting for product descriptions",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Quarterly web page freshness updates",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ongoing quarterly keyword ranking reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ongoing monthly traffic reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ongoing monthly transaction/revenue reporting",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Internal linking restructuring",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Proper URL structure analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "URL Parameter normalization/exclusions",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Header Tags Optimized (ex. H1s)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Duplicate content analysis & correction",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Keyword rank checker access (updates weekly)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Shopping cart funnel analysis and recommendations",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Website usability analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Rich snippets optimization",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Outreach to online influencers",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Initial visitor profile report",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "A/B Testing of SERP title & description to improve clickthrough",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monitor competitors' search rankings on a monthly basis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monitor competitors' website changes on a monthly basis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monitor competitors' content and link additions on a monthly basis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Customer review (user generated content) strategy",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Dedicated Internet Marketer",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Online Project Management Platform",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "150+ SMEs behind campaign driving results",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Website conversion analysis implementation",
    silver: "Custom",
    gold: "Custom",
    platinum: "Custom",
  },
  {
    name: "Initial campaign investment:(Two month duration)",
    silver: "$3000",
    gold: "$4000",
    platinum: "$5000",
  },
  {
    name: "Progressive monthly optimization:(1 year commitment - Subsequent 10 months)",
    silver: "$800",
    gold: "$1,300",
    platinum: "$18,00",
  },
];
export default data;
