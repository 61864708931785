import React, { useRef } from "react";
import { Helmet } from "react-helmet";
// photos
import smile from "../../../assets/images/smileMan.png";
import seoService1 from "../../../assets/images/rsz-home6.png";
import seoService2 from "../../../assets/images/rsz-seoservice2.png";
import seoService3 from "../../../assets/images/rsz-home2.png";
import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import Testimonial from "../../blocks/Testimonial";
import { Link } from "react-router-dom";

function UserExperienceTesting() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Usability testing companies : UX testing agency pricing | WeTech
          </title>
          <meta
            name="description"
            content="Test your website UX to find out usability issues & give your audiences a better user experience. Get affordable packages from best usability testing company."
          ></meta>
          <meta
            name="keywords"
            content="
          
          usability testing companies
,user testing websites
,ux testing agency
,best user testing websites
,top user testing websites
,usability testing agency


          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box-custom">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    User Experience Testing
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Are you looking for strategies to boost your profits? A
                    positive online user experience (UX) results in more visits,
                    customers, and money. Our user experience testing method
                    made that possible for you.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img
                    className="service-smile-image-landing"
                    src={smile}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <hr
                    style={{
                      width: "4rem",
                      backgroundColor: "white",
                      margin: "12px 10px",
                      height: "1px",
                    }}
                  />
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top User Experience Testing Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  The method by which design teams create products that provides
                  value to customers. Usability testing, often known as User
                  Experience (UX) testing, determines how user-friendly and
                  straightforward a product is. We follow- 1) Responding to your
                  consumers' feedback and enhancing their experience as a
                  result; 2) By reducing the amount of tricky navigation, slow
                  checkout procedures, and ambiguous directions, you can
                  increase brand loyalty and trust. For more sales, more lead
                  form submissions, or a better conversion rate’s you can seek
                  help from Wetech Digital to test your website’s user
                  experience and learn about what to change to be improved.
                  Usability testing services track time spent on a job, job
                  completion, and might discover roadblocks you didn't expect.
                  Good Firms has combed across a few companies and compiled a
                  list of the best usability testing services to meet your
                  needs. We arrange usability testing companies for you. As
                  usability testing agency, you can rely on WeTech Digital.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService1} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService2} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to User Experience Testing</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  Beautiful websites are necessary to establish a positive first
                  impression on the audience. WeTech Digital agency helps turn
                  your ideas into reality as web visuals. We became the most
                  significant UX Tester Company in the world by our working
                  method. We have the best user experience tools. Our UX Testing
                  procedures create a test strategy; people should be recruited
                  (target users) and locate an appropriate site. After that, the
                  test should be moderated and keep a record of the test
                  results. There are many UX testing agency like us who uses
                  best user testing websites. You sometimes need user testing
                  websites. Wetech Digital collaborates with top user testing
                  websites in need.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Design Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Research user experience</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Plan test strategy</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">UX Testing</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Screening delivery</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Launch</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={seoService3} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you want to test your product's user experience?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">Let's work with us.</p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-six-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We improve the navigational flow of your website.
                  </li>
                  <li className="page-six-li">
                    Our talented UX designers will work with you to create the
                    right design.
                  </li>
                  <li className="page-six-li">
                    We always take a good look at client’s reviews.
                  </li>
                  <li className="page-six-li">
                    We examine the concept from a variety of perspectives.
                  </li>

                  <li className="page-six-li">
                    Encourage people to come back.
                  </li>
                  <li className="page-six-li">Remove any technical flaws.</li>
                  <li className="page-six-li">
                    Reduce the cost of customer assistance by lowering the cost
                    of maintenance.
                  </li>
                  <li className="page-six-li">
                    Increase your conversion rates.
                  </li>
                  <li className="page-six-li">Boost your revenue</li>
                  <li className="page-six-li">
                    We are eager to keep in contact with you while working and
                    also optimistic about your opinion.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={seoService2} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={seoService4} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Reduce the time it takes for pages to load.
                  </li>
                  <li className="page-six-li">
                    Reduce the amount of time it takes to complete specific
                    tasks.
                  </li>
                  <li className="page-six-li">
                    Maintain a user-friendly interface.
                  </li>
                  <li className="page-six-li">
                    Optimize your user Experience following two methods
                    accordingly.
                  </li>
                  <li className="page-six-li">
                    We convert our best UX testing for your better good as
                    profitable solutions.
                  </li>
                  <li className="page-six-li">
                    Maximize your competitive edge
                  </li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span>
                    <span
                      style={{
                        borderTop: "1px solid black",
                        width: "60px",
                        marginLeft: "5px",
                        marginTop: "12px",
                      }}
                    ></span>
                  </span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Local & Global User Experience Testing Service At
                The Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default UserExperienceTesting;
