import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";

//import MainContent from "../../../assets/images/seo-service.png";
import SeoServicePricing from "../organic-serach-pricing/pricing/seo-service";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/seoService/1.PinClipart 3.png";
import image2 from "../../../assets/images/seoService/2.png";
import image3 from "../../../assets/images/seoService/3.business-man-holding-paper-with-why-choose-us-question_23-2148932314 1 (3).png";
import image4 from "../../../assets/images/seoService/4.workers-pointing-increase-sales 2 (3).png";
import image5 from "../../../assets/images/seoService/5.thinking-about-2021-09-01-06-25-35-utc 1.png";
import image6 from "../../../assets/images/seoService/6.businesspeople-celebrating-success 2 (3).png";
import { Link } from "react-router-dom";

function SEOService() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            SEO Services: affordable packages for small business | WeTech
            Digital
          </title>
          <meta
            name="description"
            content=" Get the best Search Engine Optimization Services to rank higher and grow your sales. Check WeTech Digital affordable SEO packages for small businesses. "
          ></meta>
          <meta
            name="keywords"
            content="
          seo services
          ,search engine optimization
          ,seo services company
          ,professional seo services
          ,affordable seo services
          ,affordable seo services for small business
          ,seo services providers
          ,seo service agency
          ,website seo service
          ,affordable seo packages
          ,best seo services providers
          ,best website seo service
          
          
          
          "
          ></meta>
        </Helmet>
      </div>

      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">SEO Service</h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    We ensure High-ranked Search Engine Optimization with
                    top-quality service. Let’s Dig into WeTech Digital insights
                    and learn how to get the most out of your search engine
                    marketing efforts.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span>
                    <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top SEO Service Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital listens to your strategic goals, learns about
                  your marketing strategy, marketplace, and competitive
                  environment, and then uses that information to build a
                  customized plan that takes you where you need to go and
                  beyond. One of the most important considerations when
                  selecting an SEO organization is how they assess campaign
                  success. While some SEO firms use the same standardized
                  reports all over every account, WeTech Digital creates
                  personalized reports based on the KPIs that are most relevant
                  to your company.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to SEO Service</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  We specify the keywords with both the highest search volume
                  and verify that your website ranks for all those aspects. We
                  also analyze search queries to identify specific information
                  people have about the products and services you provide. We
                  will drive more traffic to websites by optimizing these search
                  queries and ensuring your content contains the information
                  your audience is looking for.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Keyword research</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Competitor Analysis</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">On-page-optimization</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Off-page-optimization</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Detailed Analytics reporting</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Want to achieve Proven
                    <br />
                    and affordable SEO Services{" "}
                    <span
                      style={{ color: "#FFD872", fontFamily: "lora, serif" }}
                    >
                      to grow Your Business?
                    </span>
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us work together to make it happen
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <Link to="/contact">
                      <button className="services-five-button">
                        Contact Us
                      </button>
                    </Link>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We have global SEO specialists (Included international SEO
                    capabilities)
                  </li>
                  <li className="page-six-li">
                    We seem more than the best SEO services provider; also we
                    can able to handle all types of client’s needs
                  </li>
                  <li className="page-six-li">
                    We seem more than an SEO company, can able to handle We
                    combine SEO with social media, CRO, SEM, content marketing,
                    and public relations
                  </li>
                  <li className="page-six-li">
                    We use robust techniques to grow any business with an online
                    presence
                  </li>
                  <li className="page-six-li">
                    Clear and Customized reporting
                  </li>
                  <li className="page-six-li">
                    We provide professional SEO service for small to large
                    businesses.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <p className="header">
                  Why WeTech
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">Result-oriented outcomes</li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements
                  </li>
                  <li className="page-six-li">
                    We plan, execute, and refine strategy through our experts.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right">
                {/* <div className="controls-top">
                  <a
                    className="btn-floating"
                    href="#multi-item-example"
                    data-slide="prev"
                  >
                    <img src={leftArrow} alt="left" />
                  </a>
                  <a
                    className="btn-floating"
                    href="#multi-item-example"
                    data-slide="next"
                  >
                    <img src={rightArrow} alt="right" />
                  </a>
                </div> */}
              </div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          {/* pricing section */}

          <div className="page-ten-header">
            <p className="page-ten-title">
              Find High-end SEO Service At The Right Price
            </p>
          </div>
          <SeoServicePricing />

          {/* end of pricing section */}
          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default SEOService;
