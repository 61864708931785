import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "../../assets/css/career.css";
import ReactHtmlParser from 'react-html-parser';
import { toast } from "react-toastify";
import about from "../../assets/images/internship/about.png";
import { GlobalStateContext } from "../../../store/store";

function Career() {
  const myRef = useRef(null);
  const [allCareerList, setCareerList] = useState([]);
  const [noOpening, setNoOpening] = useState(false);
  const [applyInfo, setApplyInfo] = useState({});

  const context = useContext(GlobalStateContext);

  const { setGlobalState } = context;

  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
      top: 700,
    });

  const getAllCareerList = async () => {
    try {
      const dataFetched = await import("../../utils/services/career.service").then(
        async (service) => await service.getCareerList({})
      );
      if (dataFetched) {
        if (dataFetched.data.length === 0) {
          setCareerList(dataFetched.data);
          setNoOpening(true);
        } else if (dataFetched.data.length > 0) {
          setCareerList(dataFetched.data);
          setNoOpening(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllCareerList();
  }, [])
  const handleCareerBtn = (data) => {
    localStorage.setItem("CareerIdweT", JSON.stringify(data._id));
  }

  // const handleChange = async (e) => {
  //   const { name, value } = e.target;

  //   setApplyInfo((pre) => ({
  //     ...pre,
  //     [name]: value
  //   })
  //   )
  // }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const form = new FormData()
  //     const a = Object.entries(applyInfo)
  //     a.map(b => form.append(b[0], b[1]))
  //     const dataInserted = await import("../../utils/services/career.service").then(
  //       async (service) => await service.postCareerApply({ data: form })
  //     );
  //     if (dataInserted?.status === 201 || dataInserted?.status === 200) {
  //       setApplyInfo({})
  //       toast.success('Successfully submit.', {
  //         position: "top-center",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title> Career | WeTech</title>
          <meta name="description" content="Career | WeTech"></meta>
          <meta name="keywords" content="Career"></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one" style={{ marginBottom: "4rem" }}>
            <div className="page-one-box">
              <div className="box-left px-4">
                <div className="box-one-title">
                  <h1 className="services-header-title">Career</h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    WeTech Digital is an all-in-one digital marketing services agency that is dedicated to developing your business's outstanding success with online advertising and marketing facilities.
                  </p>
                </div>
              </div>
              <div className="first-box-right px-4">
                <div className="right-image-section">
                  <img
                    className="image-file"
                    src="https://i.ibb.co/Q8D0VwS/268628588-310552550943818-4547320090692996864-n.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}
          <div className="row">
            <div className="col-md-12">
              <div className="text-muted mb-5 text-2xl">Internship Program</div>
            </div>
          </div>
          <div className="w-full mb-8 flex flex-col-reverse lg:flex-row space-y-5 lg:space-x-5">
            <div className="w-full lg:w-[50%] justify-center flex flex-col text-justify items-center lg:items-start mt-5 lg:mt-0">
              At Wetech Digital, we believe in nurturing the talent of tomorrow. Whether you're a coding genius, a creative designer, a marketing enthusiast, or a business strategist, we have the perfect opportunity waiting for you to explore.
              <div className="mt-5">
                <Link to="/internship-program">
                  <button onClick={() => setGlobalState((prevState) => ({
                    ...prevState,
                    activeRoute: '/internship-program'
                  }))} className="button-sign-in !w-[10rem]">
                    Internship Program
                  </button>
                </Link>
              </div>
            </div>
            <div className="w-full lg:w-[50%]">
              <img src={about} alt="internship" className="w-full h-full" />
            </div>
          </div>
          {/* ---------------PAGE THREE START ------------------*/}

          <div className="row">
            <div className="col-md-12">
              <div className="text-muted mb-5 text-2xl">Our Ongoing Jobs </div>
            </div>
          </div>

          <div className="row mb-5" style={{ width: "100%" }}>
            {
              (allCareerList.length > 0) && (noOpening === false) ? (
                allCareerList.map(data =>
                  <div className="col-md-4 mb-4">
                    <div className="careerCard" style={{ height: "550px", borderRadius: "5px" }}>
                      {/* careerImg style={{ height: "250px" }} style={{ height: "600px" }}*/}
                      <div className="text-center" >
                        <img src={data?.file} style={{ height: "250px", width: "100% " }}
                          className="mx-auto img-fluid" alt="" />
                      </div>
                      <div className="careerCrdBody">
                        <h5>{data?.title}</h5>
                        <div className="careerDesc">
                          <small>
                            {ReactHtmlParser(data?.description)}
                          </small>
                        </div>
                        <Link to={`/jobDetails/${data?.customUrl}`} className="text-light">
                          {/* onClick={executeScroll} 
                          to={`/jobDetails/${data?.customUrl ? data?.customUrl : data?.title}`}
                          */}
                          <button
                            onClick={() => handleCareerBtn(data)}
                            className="career-button" style={{ marginTop: "20px" }}>
                            <span>Job Details</span><span className="ms-1">{">"}</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              ) : (allCareerList.length === 0) && (noOpening === true) ? (
                <div className="text-center primary-bg text-light py-5 mb-5">
                  <h1>No job opening at this time. </h1>
                </div>
              ) : null
            }
          </div>

          <div className="services-page-eleven">
            <div className="page-eleven-left">
              <p className="eleven-left-title1">
                Job apply
                <span
                  style={{
                    borderTop: "1px solid black",
                    width: "60px",
                    marginLeft: "5px",
                    marginTop: "12px",
                  }}
                ></span>
              </p>
              <p className="eleven-left-title2">Send Your CV</p>
              <p className="eleven-left-title3">
                Let's join our team.
              </p>
              <button style={{
                // margin: "10px 15px",
                backgroundColor: "#1E7FF5",
                padding: "8px 12px",
                border: "none",
                outline: "none",
                color: "#fff",
                cursor: "pointer"

              }} onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSctw7Z9UN55D7jTxPHSpHNSGwIwSPoGfLAALbIYLQvTjjMVSA/viewform")}>Apply Now</button>
            </div>
            {/* <div className="page-eleven-right">
              <div className="services-contact-form-section">
                <form action="" onSubmit={(e) => handleSubmit(e)} className="py-3 px-4">
                  <p className="services-contact-title text-start">Apply Now</p>
                  <div className="">
                    <label htmlFor="">Full Name</label>
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder="Full Name"
                      name="name"
                      id="name"
                      value={applyInfo?.name || ""}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="">Email Address</label>
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder="Email Address"
                      id="email"
                      name="email"
                      value={applyInfo?.email || ""}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="">Phone Number</label>
                    <input
                      className="form-control mb-3"
                      type="number"
                      placeholder="Phone Number"
                      id="phone"
                      name="phone"
                      value={applyInfo?.phone || ""}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="">Select Position</label>
                    <select
                      className="form-control mb-3"
                      type="select"
                      id="position"
                      name="position"
                      value={applyInfo?.position || ""}
                      onChange={(e) => handleChange(e)}
                    >
                      <option>Select</option>
                      {
                        allCareerList.map(data =>
                          <option value={data?.title}>{data?.title}</option>
                        )
                      }

                    </select>
                    <label htmlFor="">Upload Your CV</label>
                    <input
                      className="form-control mb-3"
                      type="file"
                      name="cv"
                      onChange={(e) => {
                        e.persist();
                        setApplyInfo((pre) => ({
                          ...pre,
                          cv: e.target.files[0]
                        }))
                      }}
                    />
                    <label htmlFor="" >Upload Your cover letter</label>
                    <input
                      className="form-control mb-3"
                      type="file"
                      placeholder="Your Message"
                      id="message"
                      name="message"
                      // value={message}
                      onChange={handleChange}
                    />

                    <button className="career-button" type="submit">
                      <span>Apply</span>
                    </button>
                  </div>
                </form>
              </div>
            </div> */}
          </div>

        </div>
      </div>
    </div>
  );
}

export default Career;
