import React, { useRef } from "react";
import ContactWetech from "../../blocks/ContactWetech";
import { Helmet } from "react-helmet";
// photos
import smile from "../../../assets/images/smileMan.png";
import project from "../../../assets/images/project.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/competitorAnalysis/1.PinClipart 2 (7).png";
import image2 from "../../../assets/images/competitorAnalysis/2.success-business-with-legal-movement-conceptual-3d-image-with-arrow-obstacles 1.png";
import image3 from "../../../assets/images/competitorAnalysis/3.serious-asia-male-doctor-white-medical-uniform-is-delivering-great-news-talk-discuss-results 1.png";
import image4 from "../../../assets/images/competitorAnalysis/4.businessman-playing-with-chess-game-finding-strategies-defeating-competitors-business-strategy-analysis-concept 1.png";
import image5 from "../../../assets/images/competitorAnalysis/5.asian-thinking-lady-standing-isolated 1.png";
import image6 from "../../../assets/images/competitorAnalysis/6.young-pretty-blond-woman-feeling-happy-surprised-cheerful-smiling-with-positive-attitude-realizing-solution-idea 1.png";
import { Link } from "react-router-dom";

function CompetitorAnalysis() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Competitor analysis services: Find online competitors | WeTech
          </title>
          <meta
            name="description"
            content=" Getting to know your competitors is very important to get good
            results on the internet & website SEO. Check out our competitor
            analysis services packages!"
          ></meta>
          <meta
            name="keywords"
            content="
          
competitor analysis services
,seo competitor analysis services
,competitor analysis companies



          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box-custom">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">Competitor Analysis</h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want well to build up Competitor Analysis services?
                    Then we are here to please you providing that
                    professionally.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img
                    // className="service-smile-image-landing"
                    src={image1}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Competitor Analysis Service
                  Company?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top Competitor Analysis services company
                  in the market. Every day, competitors are analyzed to help
                  develop goods, guide marketing campaigns, identify target
                  consumers, and build strategy. Competitive analysis reports,
                  when applied correctly, may give various advantages. It's a
                  game of chance in business. There are many competitor analysis
                  companies in the market. But WeTech Digital is one of the best
                  companies out there. An SEO competitor analysis includes
                  looking at the content and other aspects of the content, such
                  as the links and keywords utilized by a rival. Simply said,
                  it's an SEO comparison of two rival websites in the same
                  sector or business. Similar to other SEO competitor analysis
                  services, we offer the most quality full work for you.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to Competitor Analysis Service
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital is dedicated to providing clients with
                  interesting, relevant, and effective content. If you'd like to
                  surpass your competitors, you must understand their tactics,
                  campaigns, and methods for attracting leads to their company.
                  Competitor analysis in digital marketing is critical to your
                  marketing success. Frequent competitor analysis is beneficial
                  to all brands. We help you to recognize new inconsistencies,
                  create new goods and services. We level your competitors in
                  three as direct, indirect, and replacement competitors. Direct
                  rivals are the most hazardous since, in many respects, they
                  are carbon copies of your company. Your indirect rivals may
                  offer similar products and services to you, but they are
                  likely to operate under a different business model or have
                  distinct business objectives. Replacement rivals provide items
                  or services that may be used to replace the ones you provide.
                  As a result, if your products or services fail and your
                  consumers want to try something new to achieve their goal,
                  they can turn to your substitute competitors. Like other
                  competitor analysis companies, we solve all these for your
                  better good.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Development Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Learn about competitors</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Develop strategy</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Analysis of competitors pros and cons
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Gaining market trends</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Technology requirements</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Promote contents</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require Competitor Analysis services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <Link to="/contact">
                      <button className="services-five-button">
                        Contact Us
                      </button>
                    </Link>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Assist you in comprehending the target industry.{" "}
                  </li>
                  <li className="page-six-li">
                    We offer more efficient production and sales.
                  </li>
                  <li className="page-six-li">
                    We provide affordable services cost.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platforms.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We examine the sales strategies and outcomes of your rivals.
                  </li>
                  <li className="page-six-li">
                    Make sure your delivery prices are competitive.
                  </li>
                  <li className="page-six-li">
                    Check to see if your delivery costs are reasonable.
                  </li>
                  <li className="page-six-li">
                    Designing custom content with high technology.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Web Competitor Analysis Services At The Right
                Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default CompetitorAnalysis;
