const data = [
  {
    name: "Monthly Ad Spend",
    silver: "Up to $1,500",
    gold: "$1,501 to $5,000",
    platinum: "$5,000+",
  },
  {
    name: "Keyword Research (Up to 30 keywords per product)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Dedicated Amazon Marketing Specialist",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Online Project Management System",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Product Advertising Suggestions",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Campaign Strategy Development",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Campaign Setup",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Negative Keyword Research",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Ad Creation",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Bid Adjustments",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Sponsored Product Ads, Sponsored Brands (Brand Registered clients only), and Product Display Ads (Vendors only)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Promotion, Coupon, Lightning Deal Management",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Subscribe and Save Recommendations",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Management of both Automatic and Manual Campaigns",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Quarterly Competitive Analysis",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Monthly Reporting (2 hours included)",
    silver: "",
    gold: "",
    platinum: "",
  },
  {
    name: "Progressive Monthly Management Cost",
    silver: "300",
    gold: "14% of ad spend",
    platinum: "12% of ad spend",
  },
];
export default data;
