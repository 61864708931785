import React, { useRef } from "react";
import { Helmet } from "react-helmet";

// photos
import smile from "../../../assets/images/smileMan.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/licenseAndCertificate/1.tin-229x300 1.png";
import image2 from "../../../assets/images/licenseAndCertificate/2.manager-leading-brainstorming-meeting 1 (3).png";
import image3 from "../../../assets/images/licenseAndCertificate/3.business-man-holding-paper-with-why-choose-us-question_23-2148932314 1 (3).png";
import image4 from "../../../assets/images/licenseAndCertificate/4.workers-pointing-increase-sales 2 (3).png";
import image5 from "../../../assets/images/licenseAndCertificate/5famous-grandmaster-2021-09-24-03-54-08-utc 1.png";
import image6 from "../../../assets/images/licenseAndCertificate/6businesspeople-celebrating-success 2 (3).png";
import { Link } from "react-router-dom";

function LicenceAndCertification() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Business license compliance: Legal documentation service in BD
          </title>
          <meta
            name="description"
            content="Get business license compliance services with expert consultant. WeTech Digital is best business license and certification consultant company in Bangladesh.
"
          ></meta>
          <meta
            name="keywords"
            content="
          
business license compliance services
,renew business license
,business license service provider
,business license help
,small business certificate online
,legal documentation services
,legal documents for business
,small business certificate online


          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    License and Certification
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want to get License and Certification Services in
                    Bangladesh? If that's the case, we'll gladly assist you.
                    WeTech Digital is here for you with its business license
                    compliance services.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span> &gt; </span>
                  </button>
                </div>
              </div>
              <div className="first-box-right">
                <div className="right-image-section">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top License and Certification Services
                  Agency in Bangladesh?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top License and Application Services
                  Agency in Bangladesh. Licenses and certificates demonstrate
                  that an individual has the requisite qualifications or
                  competence to do a task. These certifications are usually
                  earned after you've accomplished your schooling. Achieving
                  requirements, which typically include passing an exam, is
                  required to obtain a license or certification. Licenses and
                  certificates are typically only effective for a certain length
                  of time and must be reissued on a regular basis. Either
                  credential may be required by an employment. As likely,
                  business licenses not just to hold corporations accountable
                  for their activities and conduct, but they also protect the
                  public's health and safety. Professional licenses indicate the
                  degree of competence and knowledge possessed by a certain
                  professional. So, business license help your business to
                  protect it legally. Our business license compliance services
                  are very helpful. WeTech Digital is very well known business
                  license service provider in Bangladeshi business market. Small
                  business certificate online are also provided by our company.
                  Because small business is also need to be certified and need
                  to get their license. We are here to help you with legal
                  documentation services. If you are a businessman, you must
                  need legal documents for business to secure it.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">
                  Our Approach to License and Certification Services in
                  Bangladesh{" "}
                </p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital is dedicated to providing clients with
                  interesting, relevant, and effective license & certification.
                  The vast number of federal, state, and municipal authorities
                  creates a tangle of licensing, registration and permit
                  requirements, which vary depending on location, industry and
                  kind of company's operations. Rules are likewise in continual
                  flux. Up to 65% of license registration criteria change each
                  year, and enforcement actions for business licensing breaches
                  are on the rise. New needs may be triggered by changes to your
                  company's activities, locations or services. If your firm does
                  not comply, it may suffer penalties, fines, business
                  interruption and negative publicity. So, we are here to give
                  business license compliance services. Our business license
                  compliance services are best in Bangladesh. We also renew
                  business license as it is very common to expire the previous
                  one. All of our services are cost effective.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process </p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Locate business location</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Business origin analysis </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Register business name</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Collect all required ID</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Do all needed legal procedure</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Make the document</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery </p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title-large">
                    Do you require License and Certification Services in
                    Bangladesh?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best works!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <Link to="/contact">
                      <button className="services-five-button">
                        Contact Us
                      </button>
                    </Link>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We are always harmonizing the needs of our customers.
                  </li>
                  <li className="page-six-li">Our job is more efficient.</li>
                  <li className="page-six-li">
                    We provide a variety of services for many platforms.
                  </li>
                  <li className="page-six-li">
                    Our service fees are reasonable. 5. We make our clients'
                    lives easier.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We renew business license any time on clients' request.
                  </li>
                  <li className="page-six-li">
                    We too provide help to online businessmen.
                  </li>
                  <li className="page-six-li">
                    Guarantees your brand image as a legal business.
                  </li>
                  <li className="page-six-li">
                    Cost effective high level services.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Local & Global License and Certification Service
                At The Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default LicenceAndCertification;
