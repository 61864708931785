import React, { useRef } from "react";
import { Helmet } from "react-helmet";
// photos
import smile from "../../../assets/images/smileMan.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/uxWebsiteDesign/1.uiuxdesign-graphic-03 1.png";
import image2 from "../../../assets/images/uxWebsiteDesign/2.manager-leading-brainstorming-meeting 1 (6).png";
import image3 from "../../../assets/images/uxWebsiteDesign/3.pexels-mikhail-nilov-8297616 1.png";
import image4 from "../../../assets/images/uxWebsiteDesign/4.workers-pointing-increase-sales 1 (8).png";
import image5 from "../../../assets/images/uxWebsiteDesign/5.unaware-hesitant-asian-woman-shrugs-shoulders-in-c-2021-08-31-09-12-31-utc 1.png";
import image6 from "../../../assets/images/uxWebsiteDesign/6.businesspeople-celebrating-success 1 (7).png";
import { Link } from "react-router-dom";

function UxWebsiteDesign() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            UI UX Design Services: Creative UX Design Agency | WeTech
          </title>
          <meta
            name="description"
            content="Build a creative website with Creative UI UX design services. WeTech Digital creative web design team will provide you best UI UX design services.
"
          ></meta>
          <meta
            name="description"
            content="
          ui ux design agency
          ,ux design agency
          ,ui ux services
          ,ux design services
          ,ui ux designing services
          ,ux design services
          ,ux ui design services
          
          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box-custom">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    UX Design for Website
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    We design user experience design as your thinking and
                    consider the client’s review. As a result, top-rated
                    companies choose our Unique set of creating solutions for
                    their products.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="img-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top UX Website Design Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  The process through which design teams generate products that
                  give customers meaningful and relevant experiences is known as
                  user experience design (UX). Using the design thinking
                  approach, our clients are widely researched. We strive to
                  learn as much as possible about our customers to create a
                  consistent User Experience and visual identity perfectly
                  matched with corporate goals and complete factorial design. As
                  a result, we’ve about contact added a wide range of
                  businesses, from start-ups to huge enterprises. Our agency can
                  successfully produce branding and UI/UX design, responsive and
                  bespoke web design, and user experience testing, thanks to our
                  industry-based expertise. For getting best UX UI design
                  services you can rely on us. There are many UI UX design
                  agency and they provide UI UX services. You only have to find
                  the most reliable UX design agency for getting high level UX
                  design services.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to UX Website Design</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  The design of your UX website is the first impression your
                  audience has of your business. Beautiful websites are
                  necessary to establish a positive first impression on the
                  audience. Essential value-adding components, user-centric
                  architecture, colors that match your brand, appropriate
                  photos, and testing the proper font responsive about contacted
                  should all be included on your website. So WeTech Digital
                  agency helps turn your ideas into reality as web visuals. We
                  became the most excellent website design company globally by
                  creating bespoke and best UX web design and understanding how
                  our great design would aid your business. In one word, our UI
                  UX designing services are first-rated.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Development Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Research user view</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Plan</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Build web visual</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">UX Testing</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Delivery</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Launch</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you already have a plan in mind? Let us collaborate to
                    make it a reality.
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our UX solutions!{" "}
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <button className="services-five-button">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-six-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Our talented UX designers will work with you to create the
                    right design.
                  </li>
                  <li className="page-six-li">
                    We take care of everything and more.
                  </li>
                  <li className="page-six-li">
                    We always take a good look at client’s reviews.
                  </li>
                  <li className="page-six-li">
                    We review the idea in many aspects.
                  </li>

                  <li className="page-six-li">
                    We are eager to keep in about contact with you while working
                    and also positive about optimistic your opinion.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">Optimize your user Experience</li>
                  <li className="page-six-li">
                    We convert our best UX website design into profitable
                    solutions
                  </li>
                  <li className="page-six-li">
                    Maximize your competitive edge
                  </li>
                  <li className="page-six-li">
                    Increse the effeictiveness of your digital marketing plan.
                  </li>
                  <li className="page-six-li">
                    Fulfill industry-specific requirements
                  </li>
                  <li className="page-six-li">
                    Help to grow local & international business
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Local & Global UX Design for Website Service At
                The Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default UxWebsiteDesign;
