import React, { useRef } from "react";
import { Helmet } from "react-helmet";
// photos
import smile from "../../../assets/images/smileMan.png";
import project from "../../../assets/images/project.png";
import ContactWetech from "../../blocks/ContactWetech";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/marketResearchService/1.clipart2033808 1.png";
import image2 from "../../../assets/images/marketResearchService/2.manager-leading-brainstorming-meeting 1.png";
import image3 from "../../../assets/images/marketResearchService/3.handshake-business-men-concept 1.png";
import image4 from "../../../assets/images/marketResearchService/4.workers-pointing-increase-sales 1 (1).png";
import image5 from "../../../assets/images/marketResearchService/5.why-2021-08-31-09-48-54-utc.png";
import image6 from "../../../assets/images/marketResearchService/6.businesspeople-celebrating-success 2.png";
import { Link } from "react-router-dom";

function MarketResearch() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            Market research service in Bangladesh: Research your consumer
          </title>
          <meta
            name="description"
            content="Get advanced market research and analysis services to make decisions. Define your current competitor and reach your potential consumer on the market. "
          ></meta>
          <meta
            name="keywords"
            content="
          
market research service
,market research service providers
,market research service price
,market research service firms


          
          "
          ></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box-custom">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    Market Research Service
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you want to get Market research for your business? Then
                    we're here to help you out competently.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="img-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Market Research Service Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top Market Research Service Agency. The
                  most successful internet marketers are those that are always
                  educating and testing the bounds. With this in mind, we've
                  developed a section called WeTech Digital Market Research to
                  share and republish information that encourages and educates
                  us. Intellectual and data-driven investigations are examples
                  of this. Here are a few samples of the kinds of things you
                  could discover in our research section: Articles from academe
                  that have been peer-reviewed, essays based on sources,
                  demonstrations. WeTech Digital is one of the best market
                  research service firms. We have cost-effective market research
                  service price.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to Market Research Service</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital’s market research service is very much
                  effective. Market research is when a firm does studies and
                  assesses the viability of a new product or service through the
                  use of surveys, product tests, and focus groups. There are
                  many market research service providers in the market. As
                  market research service provider surveys, interviews, focus
                  groups, and consumer observation are four typical forms of
                  market research methodologies done by us. Like other market
                  research service firms, we took all hassles to complete market
                  research for you. But our market research service price is
                  very affordable.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Growth Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Customer understanding</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Market research & identify</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Market area</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Information records</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Initial perceptions</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Claim of worthiness & confirmation
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Finish & delivery</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require Market Research Services?
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <Link to="/contact">
                      <button className="services-five-button">
                        Contact Us
                      </button>
                    </Link>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <hr
                    style={{
                      width: "4rem",
                      backgroundColor: "white",
                      margin: "12px 10px",
                      height: "1px",
                    }}
                  />
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    We provide more efficient work.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platforms.
                  </li>
                  <li className="page-six-li">
                    We have affordable service costs.
                  </li>
                  <li className="page-six-li">
                    We always balancing customer requirements.
                  </li>
                  <li className="page-six-li">Dedicated and patient team.</li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">Give importance to customers.</li>
                  <li className="page-six-li">
                    A full-proofa research strategy.
                  </li>
                  <li className="page-six-li">
                    Arrange legal information and documents
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}
                  ></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Web Market Research Services At The Right Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default MarketResearch;
