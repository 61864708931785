import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import ContactWetech from "../../blocks/ContactWetech";
// photos
import smile from "../../../assets/images/smileMan.png";
// import seoService1 from "../../../assets/images/rsz-home6.png";
// import seoService2 from "../../../assets/images/rsz-seoservice2.png";
// import seoService3 from "../../../assets/images/rsz-home2.png";
// import seoService4 from "../../../assets/images/rsz-seoservice4.png";
import project from "../../../assets/images/project.png";
import Testimonial from "../../blocks/Testimonial";

import image1 from "../../../assets/images/newspaper/1.pngegg (4) 1.png";
import image2 from "../../../assets/images/newspaper/2.documents-paperwork-business-strategy-concept 1.png";
import image3 from "../../../assets/images/newspaper/3.negotiating-business.png";
import image4 from "../../../assets/images/newspaper/4.man-looking-stock-market-news-computer 1.png";
import image5 from "../../../assets/images/newspaper/5.continue.png";
import image6 from "../../../assets/images/newspaper/6.that-way.png";
import { Link } from "react-router-dom";

function NewsPaperAds() {
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <div>
        <Helmet htmlAttributes>
          <title>
            News Portal - Newspaper Ads service provider in Bangladesh | WeTech
            Digital
          </title>
          <meta
            name="description"
            content="Reach local audiences with  Newspaper and News portal ads services at the lowest cost. Check our News portal and Newspaper ads cost for Bangladesh."></meta>
          <meta
            name="keywords"
            content="
          
          newspaper advertising agency
          ,newspaper ads cost
          ,local newspaper ads cost
          ,website banner advertising rates


          
          "></meta>
        </Helmet>
      </div>
      <div className="services-main-container">
        <div className="services-sub">
          <div className="services-page-one">
            <div className="page-one-box-custom">
              <div className="box-left">
                <div className="box-one-title">
                  <h1 className="services-header-title">
                    Newspaper/Portal Ads
                  </h1>
                </div>
                <div className="box-one-paragraph">
                  <p className="services-paragraph">
                    Do you need help with newspaper/portal ads? Then you are in
                    the right place to get it.
                  </p>
                </div>
                <div className="box-one-button">
                  <button className="services-button" onClick={executeScroll}>
                    <span>Learn More</span> <span>{">"}</span>
                  </button>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="image-file" src={image1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------PAGE ONE END ------------------*/}

          {/* ---------------PAGE TWO START ------------------*/}

          <div className="services-page-two" ref={myRef}>
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">
                  What Defines Us As The Top Newspaper/Portal Ads Agency?
                </p>
              </div>
              <div className="page-two-paragraph">
                <p className="paragraph">
                  WeTech Digital is the top Newspaper/Portal Ads Services
                  Company in the market. Publish advertising in regional or
                  national news sources, daily or weekly. Newspaper ads have
                  existed longer than any other sort of advertising, and are
                  still the initial type of advertising companies consider. In
                  recent eras, newspapers have appeared as portals online, where
                  advertising is also published. As a well-staged newspaper
                  advertising agency, WeTech Digital helps customers who want to
                  advertise. Our services are very good, which attracts
                  attention. Our newspaper ads cost and local newspaper ads cost
                  are very budget-friendly. For our good service, we are well
                  known in the market. A survey shows that our website banner
                  advertising rates are very good.
                </p>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image2} alt="" />
            </div>
          </div>

          <div className="services-page-three">
            <div className="services-page-three-left">
              <img className="image-1" src={image3} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">SEO Service</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Approach to Newspaper/Portal Ads</p>
              </div>
              <div className="page-three-paragraph">
                <p className="paragraph">
                  WeTech Digital is devoted and sensitive to its customers.
                  Those who require news/portal ads can look for aid from us as
                  we are a famous newspaper advertising agency. We provide
                  reasonable newspaper ads cost. Our local newspaper ads costs
                  are very low. We take all the hassles on behalf of our
                  customers; we prepare content, find a platform, do all the
                  negotiation, and publish ads. We also have a platform of our
                  own to publish newspaper/portal ads. Nowadays, website banner
                  advertising rates are growing. With these increasing rates, we
                  also provide website banner advertising services.
                </p>
              </div>
            </div>
          </div>

          <div className="services-page-four">
            <div className="page-four-header-section">
              <p className="page-four-title">Our Development Process</p>
            </div>
            <div className="page-four-content-section">
              <div className="page4-content-left">
                <div className="growth-process">
                  <p className="process-steps">Meet clients & take notes</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Analyze all possibilities</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Platform choosing</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">
                    Create strong & attractive content
                  </p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Publish</p>
                </div>
                <div className="growth-process">
                  <p className="process-steps">Share</p>
                </div>
              </div>
              <div className="page4-content-right">
                <img className="image-2" src={image4} alt="" />
              </div>
            </div>
          </div>

          <div className="services-page-five">
            <div className="page-one-box-contact">
              <div className="box-left">
                <div className="box-five-title">
                  <p className="services-five-title">
                    Do you require Newspaper/Portal Ads Services? Let us help
                    you with our best services!
                  </p>
                </div>
                <div className="box-five-paragraph">
                  <p className="services-five-paragraph">
                    Let us help you with our best solutions!
                  </p>
                </div>
                <div className="box-five-button">
                  <Link to="/contact">
                    <Link to="/contact">
                      <button className="services-five-button">
                        Contact Us
                      </button>
                    </Link>
                  </Link>
                </div>
              </div>
              <div className="box-right">
                <div className="service-overlay-image">
                  <img className="service-smile-image" src={smile} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="services-page-six">
            <div className="services-page-two-left">
              <div className="page-two-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-two-title">
                <p className="title">Why Us?</p>
              </div>
              <div className="page-two-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">
                    Help you in the market through advertising.
                  </li>
                  <li className="page-six-li">
                    We provide work that is more efficient.
                  </li>
                  <li className="page-six-li">
                    We provide affordable services cost.
                  </li>
                  <li className="page-six-li">
                    We arrange different services for different platforms.
                  </li>
                </ol>
              </div>
            </div>
            <div className="services-page-two-right">
              <img className="image-1" src={image5} alt="" />
            </div>
          </div>

          <div className="services-page-seven">
            <div className="services-page-three-left">
              <img className="image-1" src={image6} alt="" />
            </div>
            <div className="services-page-three-right">
              <div className="page-three-header">
                <span className="header">
                  <p className="mb-0">Why weTech</p>
                  <span
                    style={{
                      borderTop: "1px solid white",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </span>
              </div>
              <div className="page-three-title">
                <p className="title">Our Unique Propositions</p>
              </div>
              <div className="page-three-paragraph">
                <ol className="page-six-ol">
                  <li className="page-six-li">We examine all possibilities.</li>
                  <li className="page-six-li">
                    We tried to give unique services.
                  </li>
                  <li className="page-six-li">
                    We provide profitable service.
                  </li>
                  <li className="page-six-li">
                    Designing custom content with high technology.
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="services-page-eight">
            <div className="page-eight-header">
              <p className="first-section-title">Our Recent Projects</p>
            </div>
            <div className="page-eight-content">
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Parcel mobile app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Food delivery app</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
              <div className="project-eight-wrapper">
                <div className="project-section">
                  <div className="image-section">
                    <img className="image-1" src={project} alt="" />
                  </div>
                  <div className="image-title-section">
                    <p className="image-title">Ecommerce Website</p>
                  </div>
                </div>
                <div className="project-paragraph-section">
                  <p className="mt-2 project-paragraph">
                    Our team has been working at frispes for almost 2 months
                    because our office still closed. During
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="services-page-nine">
            <div className="page-nine-header">
              <div className="nine-header-left">
                <p className="eleven-left-title1">
                  Reviews
                  <span
                    style={{
                      borderTop: "1px solid black",
                      width: "60px",
                      marginLeft: "5px",
                      marginTop: "12px",
                    }}></span>
                </p>
                <p className="page-nine-title">What our customers are saying</p>
              </div>
              <div className="nine-header-right"></div>
            </div>
            <div className="page-nine-content-section">
              <Testimonial />
            </div>
          </div>

          <div className="services-page-ten">
            <div className="page-ten-header">
              <p className="page-ten-title">
                Find High-end Web Newspaper/Portal Ads Services At The Right
                Price
              </p>
            </div>
            <div className="page-ten-content">
              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Free</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section-basic">
                <div className="pricing-title-section">
                  <p className="title-free">Basic</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button-basic">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>

              <div className="pricing-section">
                <div className="pricing-title-section">
                  <p className="title-free">Pro</p>
                </div>
                <div className="pricing-costing-highlight">
                  <p className="pricing-highlight">$120/Month</p>
                </div>
                <div className="pricing-costing">
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                  <p className="pricing-list">$120/Month</p>
                </div>
                <div className="pricing-button-section">
                  <button className="pricing-button">
                    <span>ᐯ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ContactWetech />
        </div>
      </div>
    </div>
  );
}

export default NewsPaperAds;
